import { createSlice } from '@reduxjs/toolkit';
import { getKubeApplications } from '../api';

const kubeApplicationsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const kubeApplications = createSlice({
  name: 'kubeApplications',
  initialState: kubeApplicationsInitialState,
  reducers: {
    getKubeApplicationStart(state) {
      state.isLoading = true;
    },
    getKubeApplicationSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data;
      } else {
        state.data = [];
      }
    },
    getKubeApplicationFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getKubeApplicationStart, getKubeApplicationSuccess, getKubeApplicationFailure } = kubeApplications.actions;

export default kubeApplications.reducer;

export const fetchKubeApplications = target => async (dispatch) => {
  try {
    dispatch(getKubeApplicationStart());
    const kubeApplications = await getKubeApplications(target);
    dispatch(getKubeApplicationSuccess(kubeApplications));
  } catch (err) {
    dispatch(getKubeApplicationFailure(err.toString()));
  }
};
