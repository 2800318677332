import React from 'react';
import { useHistory } from 'react-router';
import '../index.scss';
import NoClusterMessage from '../components/NoClusterMessage';

const OnboardingOverview = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/onboarding/cluster');
  }

  return <div className='onboarding-overview-container'>
    <NoClusterMessage hasSkip={true} onClaimCluster={handleClick} />
  </div>;
}

export default OnboardingOverview;