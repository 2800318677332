import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'antd';
import BaseTable from '../../../components/table';
import { fetchKubernetes } from '../../../reducers/kubernetesSlice';
import { fetchKubernetesVersions } from '../../../reducers/kubernetesVersionsSlice';
import { stringSorter } from '../../../utils';
import AddKubeModal from '../../../components/modals/addKubeModal';
import { addNestedCluser } from '../../../api';
import notification from '../../../utils/notification';
import { disableCreation } from '../../../utils';

import './kubeClustersTable.scss';
import { useHistory } from 'react-router';

const KubeClustersTable = ({ title, value }) => {
  const history = useHistory()
  const [visible, toggleVisible] = useState(false);
  const [isAdding, toggleIsAdding] = useState(false);
  const { data, isLoading, error } = useSelector((state) => state.kubernetes);
  const { data: kubeVersions } = useSelector((state) => state.kubernetesVersions);
  const { data: capabilities } = useSelector((state) => state.capability);
  const { target } = useSelector((state) => state.clusters);
  const dispatch = useDispatch();

  const goViewClusters = () => {
    history.push('/kubernetes');
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'displayName',
      key: 'name',
      sorter: stringSorter('displayName')
    },
    {
      title: 'PODS',
      dataIndex: 'podCount',
      key: 'podCount'
    },
    {
      title: 'LOADBALANCERS',
      dataIndex: 'loadbalancers',
      key: 'loadbalancers',
      render: loadbalancers => (
        <>
        {
          (loadbalancers || []).map((lb) => lb.name).join(', ') || 0
        }
        </>
      )
    }
  ]

  useEffect(() => {
    function getKubeServices() {
      dispatch(fetchKubernetes(target));
    }
    getKubeServices();
    dispatch(fetchKubernetesVersions(target));
    const interval = setInterval(() => getKubeServices(), 10 * 1000);
    return () => {
      clearInterval(interval);
    }
  }, [target, dispatch]);

  const closeModal = () => {
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const handleCreate = (form) => {
    toggleIsAdding(true);
    return addNestedCluser({
      target,
      ...form.getFieldsValue(),
    }).then((res) => {
      toggleIsAdding(false);
      if (res.status === 200) {
        closeModal();
        dispatch(fetchKubernetes);
        notification.success({
          message: 'New Nested Cluster has been added',
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    }).catch(e => {
      if (e.response?.data) {
        notification.error({
          message: e.response?.data,
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
      toggleIsAdding(false);
    });
  };

  return (
    <div className='table-container'>
      <h4 className='heading'>Kube Clusters</h4>
      <div className='kube-container'>
        <BaseTable
          columns={columns}
          data={data}
          loading={isLoading}
          error={error}
          rowKey={(record) => record.dataValues?.id}
          id={'id'}
          emptyRenderText={'No existing clusters'}
        />
        <Row justify='space-between' className='kube-footer'>
          <Col>
            <Button 
              className='action-btn' 
              onClick={goViewClusters}
            >
              Manage Clusters
            </Button>
            <Button
              className='action-btn'
              onClick={openModal}
              disabled={disableCreation(capabilities)}
            >
              Add Cluster
            </Button>
          </Col>
        </Row>
      </div>
      <AddKubeModal
        target={target}
        visible={visible}
        loading={isAdding}
        handleCreate={handleCreate}
        onCancel={closeModal}
        versions={kubeVersions}
      />
    </div>
  );
};

export default KubeClustersTable;
