import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import notification from '../../utils/notification';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import ClusterFileUploadModal from '../../components/modals/clusterFileUploadModal';
import { fetchDataVolumes } from '../../reducers/vmsSlice';
import { fetchKubernetes } from '../../reducers/kubernetesSlice';
import { postDataVolume, uploadFile } from '../../api';
import { stringSorter } from '../../utils'
import { disableCreation } from '../../utils';

import './index.scss';

const DataVolumes = () => {
  const [visible, toggleVisible] = useState(false);
  const [isUploading, toggleIsUploading] = useState(false);
  const dispatch = useDispatch();
  const { dataVolumes, isLoading, error } = useSelector((state) => state.vms);
  const { data: kubernetes } = useSelector((state) => state.kubernetes);
  const { data: capabilities } = useSelector((state) => state.capability);
 
  const { target } = useSelector((state) => state.clusters);
  
  const dataVolumeColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: 150,
      sorter: stringSorter('name')
    },
    {
      title: 'NAMESPACE',
      dataIndex: 'namespaceDisplay',
      width: 150,
      sorter: stringSorter('namespaceDisplay')
    },
    {
      title: 'SOURCE',
      dataIndex: 'source',
      sorter: stringSorter('source')
    },
    {
      title: 'PROGRESS (%)',
      dataIndex: 'progress',
      sorter: stringSorter('progress')
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      sorter: stringSorter('status')
    },
  ];

  useEffect(() => {
    function getDataVolumes() {
      dispatch(fetchDataVolumes(target));
      dispatch(fetchKubernetes(target));
    }
    getDataVolumes();
    const interval = setInterval(() => getDataVolumes(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);


  useEffect(() => {
    dispatch(fetchKubernetes(target));
    dispatch(fetchDataVolumes(target));
  }, [dispatch, target])

  const closeModal = () => {
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const handleHttpUpload = (form) => {
    toggleIsUploading(true);
    const { kubernetes, name, url, size } = form.getFieldsValue();
    return postDataVolume({
      target,
      clusterId: kubernetes,
      form: { name: name, url: url, size: size },
    }).then((res) => {
      toggleIsUploading(false);
      if (res.status === 200) {
        closeModal();
        form.resetFields();
        dispatch(fetchDataVolumes(target));
        notification.success({
          message: 'New DataVolume has been added',
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    }).catch(e => {
      if (e.response?.data) {
        notification.error({
          message: e.response?.data,
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
      toggleIsUploading(false);
    });
  };

  const handleIsoUpload = async (form, clusterId) => {
    toggleIsUploading(true);
    try {
      await uploadFile(target, clusterId, form);
      dispatch(fetchDataVolumes(target));
      notification.success({
        message: 'New DataVolume has been added',
        placement: 'bottomRight'
      });
      form.resetFields();
      closeModal();
    } catch (error) {
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    }
    toggleIsUploading(false);
  }

  return (
    <div>
      <Header
        type='button'
        buttonName='Upload Volume'
        buttonDisabled={disableCreation(capabilities)}
        items={dataVolumes}
        title={{
          singular: 'DataVolume',
          plural: 'DataVolumes'
        }}
        loading={true}
        openModal={openModal}
        description='See your dataVolumes running FLUID in your data center or at your edge'
      />
      <BaseTable
        columns={dataVolumeColumns}
        data={dataVolumes}
        loading={isLoading}
        error={error}
        rowKey={(record) => record.key}
        id={'id'}
        emptyRenderText={'No existing dataVolumes'}
      />
      <ClusterFileUploadModal
        target={target}
        visible={visible}
        loading={isUploading}
        kubernetes={kubernetes}
        handleIsoUpload={handleIsoUpload}
        handleHttpUpload={handleHttpUpload}
        onCancel={closeModal}
      />
    </div>
  );
};

export default DataVolumes;
