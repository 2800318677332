import React, { useEffect } from 'react';
import { Checkbox } from 'antd';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button'; 
import Select from 'antd/lib/select';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Typography from 'antd/lib/typography';
import nameValidator from '../validators/nameValidator';
import ipValidator from '../validators/ipValidator';
import './modal.scss';

const { Paragraph } = Typography;
const { Option } = Select;

function useForceUpdate(){
  const [, setValue] = React.useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const AddKubeModal = ({ target, visible, loading, handleCreate, onCancel, versions }) => {
  const [form] = Form.useForm();
  const forceUpdate = useForceUpdate();
  let initialValues = {
    k3sVersion: versions[0],
  };

  useEffect(() => {
    if (versions && versions.length > 0) {
      form.setFieldsValue('k3sVersion', versions[0]);
    }
  }, [form, versions]);

  return (
    <Modal
      visible={visible}
      title='Create a Kubernetes Service'
      onOk={handleCreate}
      onCancel={onCancel}
      width={640}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                handleCreate(form);
              })
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          Create
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' initialValues={initialValues} requiredMark={'optional'} onChange={forceUpdate} >
        <Paragraph>Fluid manages your underlying infrastructure automatically so there's no need to specify a cluster size, simply specify a Kubernetes API version and a cluster name and  your Fluid managed Kubernetes cluster will be ready for your to access to seconds!</Paragraph>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              validateTrigger='onBlur'
              hasFeedback
              rules={[{ required: true, message: 'Name is required' }, nameValidator(target)]}
            >
              <Input className='modal-item-controller' placeholder='Name' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='isVMOnlyCluster'
              className='modal-item-label'
              valuePropName="checked" 
              label=''
            >
              <Checkbox>Is this a VM-only Cluster?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {!form.getFieldValue('isVMOnlyCluster') ? (
          <>
            <Row gutter={24}>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='k3sVersion'
                  className='modal-item-label'
                  label='Version'
                  rules={[{ required: true, message: 'Kubernetes node is required' }]}
                >
                  <Select size="large">
                    {versions.length > 0 && <>
                      {versions.map(el => (
                        <Option key={el} value={el}>Kubernetes: {el}-fluid</Option>
                      ))}
                    </>}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} className={'modal-col'}>
                <br/>
                <Form.Item
                  name='isSecured'
                  className='modal-item-label'
                  valuePropName="checked" 
                  label=''
                >
                  <Checkbox>Is this a secured cluster?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} className={'modal-col'}>
                <Form.Item
                  name='serviceCidr'
                  className='modal-item-label'
                  label='Service CIDR'
                  rules={[{ required: true, message: 'Service CIDR is required' }, ipValidator]}
                >
                  <Input className='modal-item-controller' placeholder='0.0.0.0' />
                </Form.Item>
              </Col>
            </Row>
          </>
        ): (<></>)}
      </Form>
    </Modal>
  );
};

export default AddKubeModal;
