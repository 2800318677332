import { createSlice } from '@reduxjs/toolkit';
import { getNestedClusters } from '../api';

const kubernetesInitialState = {
  data: [],
  namespace: undefined,
  isLoading: false,
  error: null
};

const kubernetes = createSlice({
  name: 'kubernetes',
  initialState: kubernetesInitialState,
  reducers: {
    getKubernetesStart(state) {
      state.isLoading = true;
    },
    getKubernetesSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((kube, index) => {
          return {
            key: index,
            ...kube
          };
        });
        state.namespace = state.data[0].displayName || state.namespace;
      } else {
        state.data = [];
        state.namespace = undefined;
      }
    },
    getKubernetesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
      state.namespace = undefined;
    },
    updatekubeNamespace(state, { payload }) {
      state.namespace = payload;
      return state;
    }
  }
});

export const { getKubernetesStart, getKubernetesSuccess, getKubernetesFailure, updateKubeTarget } = kubernetes.actions;

export default kubernetes.reducer;

export const fetchKubernetes = (target) => async (dispatch) => {
  try {
    dispatch(getKubernetesStart());
    const kubernetes = await getNestedClusters(target);
    dispatch(getKubernetesSuccess(kubernetes));
  } catch (err) {
    dispatch(getKubernetesFailure(err.toString()));
  }
};