import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import notification from '../../utils/notification';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import ShowStatus from '../../components/showstatus';
import BaseNatModal from '../../components/modals/baseNatModal';
import { fetchNats } from '../../reducers/natsSlice';
import { addNat, deleteNat, updateNat } from '../../api';
import TableMoreItems from '../../components/modals/tableMoreItems';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import { ipSorter, stringSorter } from '../../utils';

const Nat = () => {
  const natColumns = useMemo(() => [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: 200,
      sorter: stringSorter('name')
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: 60,
      sorter: stringSorter('status'),
      render: (status) => <ShowStatus status={status} />
    },
    {
      title: 'PERMISSION',
      dataIndex: 'action',
      width: 40,
      sorter: stringSorter('action'),
      render: (text) => {
        if (text === 'ACCEPT_SNAT') return 'ACCEPT';
        return text;
      }
    },
    {
      title: 'PROTOCOL',
      dataIndex: 'protocol',
      width: 40,
      sorter: stringSorter('protocol'),
      render: (text) => String(text).toUpperCase()
    },
    {
      title: 'SOURCE',
      dataIndex: 'source',
      width: 150,
      sorter: ipSorter('source')
    },
    {
      title: 'SRC PORT',
      width: 110,
      dataIndex: 'src_port_from',
      render: (text, record) => {
        if (record.protocol === 'all') {
          return 'all';
        }
        if (record.src_port_to) {
          return `${text}-${record.src_port_to}`;
        }
        return text;
      }
    },
    {
      title: 'DESTINATION',
      dataIndex: 'destination',
      width: 150,
      sorter: ipSorter('destination')
    },
    {
      title: 'DST PORT',
      width: 110,
      dataIndex: 'dst_port_from',
      render: (text, record) => {
        if (record.protocol === 'all') {
          return 'all';
        }
        if (record.dst_port_to) {
          return `${text}-${record.dst_port_to}`;
        }
        return text;
      }
    },
    {
      title: '',
      dataIndex: 'actions',
      className: 'action-column',
      width: 20,
      render: (_, record) => (
        <TableMoreItems onDelete={openDeleteModal(record)} onUpdate={openUpdateModal(record)} />
      )
    }
  ], []);

  const [visible, toggleVisible] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [visibleDeleteModal, toggleVisibleDeleteModal] = useState(false);
  const [visibleUpdateModal, toggleVisibleUpdateModal] = useState(false);
  const [current, setCurrent] = useState({});

  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.nats);
  const target = useSelector((state) => state.clusters?.target);

  useEffect(() => {
    function getNats() {
      target && dispatch(fetchNats(target));
    }
    getNats();
    const interval = setInterval(() => getNats(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);

  const closeModal = () => {
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const handleUpdate = () => {
    target && dispatch(fetchNats(target));
  };

  const openUpdateModal = (record) => () => {
    setCurrent(record);
    toggleVisibleUpdateModal(true);
  };

  const openDeleteModal = (record) => () => {
    setCurrent(record);
    toggleVisibleDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setCurrent(null);
    toggleVisibleDeleteModal(false);
  };

  const closeUpdateModal = () => {
    setCurrent(null);
    toggleVisibleUpdateModal(false);
  };

  const handleCreate = (data) => {
    toggleLoading(true);
    return addNat(target, data).then((res) => {
      toggleLoading(false);
      if (res.data === 'OK') {
        closeModal();
        closeUpdateModal();
        dispatch(fetchNats(target));
        notification.success({
          message: 'New Rule has been added',
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    }).catch(() => {
      toggleLoading(false);
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    });
  };

  const handleUpdateNat = (data) => {
    toggleLoading(true);
    return updateNat(target, data).then((res) => {
      toggleLoading(false);
      if (res.data === 'OK') {
        closeModal();
        closeUpdateModal();
        dispatch(fetchNats(target));
        notification.success({
          message: 'New Rule has been added',
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    }).catch(() => {
      toggleLoading(false);
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    });
  };

  const handleDelete = () => {
    toggleLoading(true);
    return deleteNat(target, current.id).then(() => {
      closeDeleteModal();
      dispatch(fetchNats(target));
      notification.success({
        message: 'New Rule has been added',
        placement: 'bottomRight'
      });
    }).catch(() => {
      toggleLoading(false);
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    })
  };

  return (
    <div>
      <Header
        type='button'
        buttonName='Add Rule'
        items={data}
        title={{
          plural: 'NAT Rules',
          singular: 'NAT Rule'
        }}
        loading={isLoading}
        openModal={openModal}
        onUpdate={handleUpdate}
        description='View network address translation (NAT) configurations'
        onRefresh={handleUpdate}
      />
      <BaseTable
        columns={natColumns}
        data={data}
        loading={isLoading}
        error={error}
      />
      <BaseNatModal
        visible={visible}
        loading={loading}
        type={'Add'}
        handleOk={handleCreate}
        onCancel={closeModal}
      />
      <BaseNatModal
        visible={visibleUpdateModal}
        loading={loading}
        type={'Update'}
        initialValues={current}
        handleOk={handleUpdateNat}
        onCancel={closeUpdateModal}
      />
      <DeleteItemModal
        visible={visibleDeleteModal}
        title='NAT'
        loading={loading}
        onDelete={handleDelete}
        onCancel={closeDeleteModal}
      />
    </div>
  );
};

export default Nat;
