import { createSlice } from '@reduxjs/toolkit';
import { getNetworks } from '../api';

const networksInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const networks = createSlice({
  name: 'networks',
  initialState: networksInitialState,
  reducers: {
    getNetworksStart(state) {
      state.isLoading = true;
    },
    getNetworksSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((network) => {
          network['key'] = network.id;
          network['type'] = network.type.charAt(0).toUpperCase() + network.type.slice(1);
          return network;
        });
      } else {
        state.data = [];
      }
    },
    getNetworksFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getNetworksStart, getNetworksSuccess, getNetworksFailure } = networks.actions;

export default networks.reducer;

export const fetchNetworks = target => async (dispatch) => {
  try {
    dispatch(getNetworksStart());
    const networks = await getNetworks(target);
    dispatch(getNetworksSuccess(networks));
  } catch (err) {
    dispatch(getNetworksFailure(err.toString()));
  }
};
