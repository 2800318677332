import React from 'react';
import { ReactComponent as AzureLogo } from '../../assets/logo_azure_icon.svg';
import { ReactComponent as GoogleLogo } from '../../assets/logo_google_icon.svg';
import { ReactComponent as AWSLogo } from '../../assets/logo_aws.svg';
import { ReactComponent as EquinixLogo } from '../../assets/logo_equinix.svg';
import { ReactComponent as MegaportLogo } from '../../assets/logo_megaport.svg';
import { ReactComponent as PCCWLogo } from '../../assets/logo_pccw.svg';

const WrappedLogo = ({ children }) => <div className='table-logo-wrapper'>
  {children}
</div>

const AzureTableLogo = () => <WrappedLogo><AzureLogo /></WrappedLogo>;
const GoogleTableLogo = () => <WrappedLogo><GoogleLogo /></WrappedLogo>
const AWSTableLogo = () => <WrappedLogo><AWSLogo style={{
  position: 'relative',
  top: 1,
}} /></WrappedLogo>;
const EquinixTableLogo = () => <WrappedLogo><EquinixLogo/></WrappedLogo>;
const MegaportTableLogo = () => <WrappedLogo><MegaportLogo /></WrappedLogo>
const PCCWTableLogo = () => <WrappedLogo><PCCWLogo style={{
  position: 'relative',
  left: 2,
}}/></WrappedLogo>

export {
  AzureLogo,
  AzureTableLogo,
  AWSLogo,
  AWSTableLogo,
  GoogleLogo,
  GoogleTableLogo,
  EquinixLogo,
  EquinixTableLogo,
  MegaportLogo,
  MegaportTableLogo,
  PCCWLogo,
  PCCWTableLogo
}
