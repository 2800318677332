import React, { useEffect, useState, useMemo } from 'react';
import Button from 'antd/lib/button/button';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import AddBgpPeerModal from '../../components/modals/addBgpPeerModal';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import { useDispatch, useSelector } from 'react-redux';
import { createBgpPeer, deleteBgpPeer, updateBgpPeer } from '../../api';
import notification from '../../utils/notification';
import { fetchBgpPeers } from '../../reducers/bgpPeersSlice';
import { stringSorter } from '../../utils';

import './index.scss';

const BgpPeers = () => {
  const [visible, toggleVisible] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [visibleDeleteModal, toggleVisibleDeleteModal] = useState(false);
  const [current, setCurrent] = useState(undefined);

  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(state => state.bgpPeers);
  const target = useSelector(state => state.clusters?.target);

  useEffect(() => {
    target && dispatch(fetchBgpPeers(target));
  }, [dispatch, target]);

  const bgpPeersColumns = useMemo(
    () => [
      {
        title: 'NAME',
        dataIndex: 'name',
        width: 250,
        sorter: stringSorter('name')
      },
      {
        title: 'Peer Address',
        dataIndex: 'peerAddress',
        width: 250,
        sorter: stringSorter('peerAddress'),
        render: (text, record) => (
          <>
            {
              record?.spec?.peerAddress
            }
          </>
        )
      },
      {
        title: 'Peer ASN',
        dataIndex: 'peerASN',
        width: 250,
        sorter: stringSorter('peerASN'),
        render: (text, record) => (
          <>
            {
              record?.spec?.peerASN
            }
          </>
        )
      },
      {
        title: 'My ASN',
        dataIndex: 'myASN',
        width: 250,
        sorter: stringSorter('myASN'),
        render: (text, record) => (
          <>
            {
              record?.spec?.myASN
            }
          </>
        )
      },
      {
        title: '',
        dataIndex: '',
        key: 'action',
        width: 30,
        className: 'action-column',
        render: (_, record) => {
          return <Button onClick={openUpdateModal(record)}>Update</Button>;
        }
      },
      {
        title: '',
        dataIndex: '',
        key: 'action',
        width: 30,
        className: 'action-column',
        render: (_, record) => {
          return <Button onClick={openDeleteModal(record)}>Delete</Button>;
        }
      }
    ],
    []
  );

  const closeModal = () => {
    setCurrent(undefined);
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const openUpdateModal = (record) => () => {
    setCurrent(record);
    toggleVisible(true);
  };

  const handleCreateOrUpdate = (form) => {
    toggleLoading(true);
    const data = form.getFieldsValue()
    const body = {
      myASN: Number(data.myASN),
      peerASN: Number(data.peerASN),
      peerAddress: data.peerAddress,
      peerPort: Number(data.peerPort)
    }

    if (current) {
      return updateBgpPeer(target, data.name, body).then(() => {
        toggleLoading(false);
        closeModal();
        handleUpdate();
        notification.success({
          message: 'BGP Peer updated.'
        })
      }).catch(e => {
        if (e?.response?.data?.message) {
          notification.error({
            message: e?.response?.data?.message
          });
        } else {
          notification.error({
            message: 'Sorry something went wrong. Please try again later.'
          });
        }
        toggleLoading(false);
      });
    } else {
      return createBgpPeer(target, data.name, body).then(() => {
        toggleLoading(false);
        closeModal();
        handleUpdate();
        notification.success({
          message: 'New BGP Peer added.'
        })
      }).catch(e => {
        if (e?.response?.data?.message) {
          notification.error({
            message: e?.response?.data?.message
          });
        } else {
          notification.error({
            message: 'Sorry something went wrong. Please try again later.'
          });
        }
        toggleLoading(false);
      });
    }
  };

  const handleUpdate = () => {
    target && dispatch(fetchBgpPeers(target));
  };

  const openDeleteModal = (record) => () => {
    setCurrent(record);
    toggleVisibleDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setCurrent(undefined);
    toggleVisibleDeleteModal(false);
  };

  const handleDelete = () => {
    const name = current?.name

    return current && deleteBgpPeer(target, name).then(() => {
      closeDeleteModal();
      handleUpdate();
      notification.success({
        message: 'Deleted BGP Peer.'
      });
    }).catch(() => {
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    })
  };

  return (
    <div>
      <Header
        type='button'
        buttonName='Add BGP Peer'
        items={data}
        title={{
          singular: 'BGP Peer',
          plural: 'BGP Peers'
        }}
        loading={isLoading}
        openModal={openModal}
        onRefresh={handleUpdate}
      />
      <BaseTable
        className={'bgpPeers-table'}
        columns={bgpPeersColumns}
        data={data}
        loading={isLoading}
      />
      <AddBgpPeerModal
        visible={visible}
        loading={loading}
        current={current}
        handleCreate={handleCreateOrUpdate}
        onCancel={closeModal}
        rowKey={'id'}
      />
      <DeleteItemModal
        visible={visibleDeleteModal}
        title='BGP Peer'
        onDelete={handleDelete}
        onCancel={closeDeleteModal}
      />
    </div>
  );
};

export default BgpPeers;
