import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import notification from '../../utils/notification';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import EditClusterModal from '../../components/modals/editClustersModal';
import EditCloudInitConfigModal from '../../components/modals/editCloudInitConfigModal';
import { deleteMetalCluster, editMetalCluster, updateCloudInitConfig } from '../../api';
import { fetchClusters } from '../../reducers/clusterSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const ClusterActions = ({ record }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCloudInitModalVisible, setIsCloudInitModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [cloudInitLoading, setCloudInitLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const onDelete = async () => {
    setDeleteLoading(true);
    try {
      await deleteMetalCluster(record.clusterUuid);
      setDeleteLoading(false);
      setIsDeleteModalVisible(false);
      dispatch(fetchClusters);
      history.push('/clusters');
    } catch (err) {
      notification.error({
        message: 'Something went wrong. Please try again later'
      })
    }
    setDeleteLoading(false);
  }

  const onEdit = (values) => {
    setEditLoading(true);
    try {
      editMetalCluster(record.clusterUuid, values);
      setEditLoading(false);
      setIsEditModalVisible(false);
      dispatch(fetchClusters);
    } catch (err) {
      notification.error({
        message: 'Something went wrong. Please try again later'
      })
    }
    setEditLoading(false);
  }

  const onCloudInit = (values) => {
    const body = {
      clusterUuid: record.clusterUuid,
      startScript: values.startScript
    }
    setCloudInitLoading(true);
    try {
      updateCloudInitConfig(record.clusterUuid, body);
      setCloudInitLoading(false);
      setIsCloudInitModalVisible(false);
      dispatch(fetchClusters);
    } catch (err) {
      notification.error({
        message: 'Something went wrong. Please try again later'
      })
    }
    setCloudInitLoading(false);
  }

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  }

  const showEditModal = () => {
    setIsEditModalVisible(true);
  }

  const showCloudInitModal = () => {
    setIsCloudInitModalVisible(true);
  }

  const onCancelDelete = () => {
    setIsDeleteModalVisible(false);
  }

  const onCancelEdit = () => {
    setIsEditModalVisible(false);
  }

  const onCancelCloudInit = () => {
    setIsCloudInitModalVisible(false);
  }

  return (
    <Row justify='space-between' className='extended-footer'>
      <Col>
        <Button className='action-btn' onClick={showEditModal}>Edit</Button>
      </Col>
      <Col>
        <Button className='action-btn' onClick={showCloudInitModal}>Edit CloudInit Config</Button>
      </Col>
      <Col>
        <Button className='destroy-btn' type='danger' onClick={showDeleteModal}>
          Destroy
        </Button>
      </Col>
      <DeleteItemModal
        title={'Cluster'}
        visible={isDeleteModalVisible}
        loading={deleteLoading}
        onCancel={onCancelDelete}
        onDelete={onDelete}
        />
        <EditClusterModal
          visible={isEditModalVisible}
          onCancel={onCancelEdit}
          onEdit={onEdit}
          loading={editLoading}
          existingData={record}
        />
        <EditCloudInitConfigModal
          visible={isCloudInitModalVisible}
          onCancel={onCancelCloudInit}
          onEdit={onCloudInit}
          loading={cloudInitLoading}
          existingData={record}
        />
    </Row>
  );
};

export default ClusterActions;
