import React, { useEffect, useState, useMemo } from 'react';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import BaseSubnetsModal from '../../components/modals/baseSubnetsModal';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import TableMoreItems from '../../components/modals/tableMoreItems';
import { useDispatch, useSelector } from 'react-redux';
import { createSubnet, deleteSubnet, updateSubnet } from '../../api';
import notification from '../../utils/notification';
import { fetchSubnets } from '../../reducers/subnetSlice';
import { stringSorter, ipSorter } from '../../utils';

import './index.scss';

const Subnets = () => {
  const [visible, toggleVisible] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [visibleDeleteModal, toggleVisibleDeleteModal] = useState(false);
  const [current, setCurrent] = useState(undefined);

  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(state => state.subnets);
  const target = useSelector(state => state.clusters?.target);

  useEffect(() => {
    target && dispatch(fetchSubnets(target));
  }, [dispatch, target]);

  const subnetColumns = useMemo(
    () => [
      {
        title: 'NAME',
        dataIndex: 'description',
        width: 150,
        sorter: stringSorter('name')
      },
      {
        title: 'IP',
        dataIndex: 'prefix',
        width: 150,
        sorter: ipSorter('prefix'),
        render: (_, record) => `${record.prefix}/${record.length || 0}`
      },
      {
        title: '',
        dataIndex: 'actions',
        className: 'action-column',
        width: 20,
        render: (_, record) => (
          <TableMoreItems onDelete={openDeleteModal(record)} onUpdate={openUpdateModal(record)} />
        )
      }
    ],
    []
  );

  const closeModal = () => {
    setCurrent(undefined);
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const handleCreateOrUpdate = (form) => {
    toggleLoading(true);
    const formData = form.getFieldsValue();

    const transformedData = {
      ...formData,
      tenant: 1, // FIXME - are we going to expose this later?
      site: 1, // FIXME - are we going to expose this later?
      id: current?.id
    }

    return (current ? updateSubnet(target, transformedData) : createSubnet(target, transformedData)).then(() => {
      toggleLoading(false);
      closeModal();
      handleUpdate();
      notification.success({
        message: current ? 'Updated Subnet.' : 'New Subnet added.'
      })
    }).catch(e => {
      if (e?.response?.data?.message) {
        notification.error({
          message: e?.response?.data?.message
        });
      } else {
        notification.error({
          message: 'Sorry something went wrong. Please try again later.'
        });
      }
      toggleLoading(false);
    });
  };

  const handleUpdate = () => {
    target && dispatch(fetchSubnets(target));
  };

  const openUpdateModal = (record) => () => {
    setCurrent(record);
    toggleVisible(true);
  };

  const openDeleteModal = (record) => () => {
    setCurrent(record);
    toggleVisibleDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setCurrent(undefined);
    toggleVisibleDeleteModal(false);
  };

  const handleDelete = () => {
    const transformedData = {
      ids: [current?.id, ...(current?.assignments || []).map?.(({ id }) => id)]
    }

    return current?.id && deleteSubnet(target, transformedData).then(() => {
      closeDeleteModal();
      handleUpdate();
      notification.success({
        message: 'Deleted Subnet.'
      });
    }).catch(() => {
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    })
  };

  return (
    <div>
      <Header
        type='button'
        buttonName='Add Subnet'
        items={data}
        title={{
          singular: 'Subnet',
          plural: 'Subnets'
        }}
        loading={isLoading}
        openModal={openModal}
        onRefresh={handleUpdate}
      />
      <BaseTable
        className={'subnet-table'}
        columns={subnetColumns}
        data={data}
        loading={isLoading}
      />
      <BaseSubnetsModal
        visible={visible}
        loading={loading}
        current={current}
        handleCreate={handleCreateOrUpdate}
        onCancel={closeModal}
        rowKey={'id'}
      />
      <DeleteItemModal
        visible={visibleDeleteModal}
        title='Subnets'
        onDelete={handleDelete}
        onCancel={closeDeleteModal}
      />
    </div>
  );
};

export default Subnets;
