import { createSlice } from '@reduxjs/toolkit';
import { getVlans } from '../api';

const vlansInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const vlans = createSlice({
  name: 'vlans',
  initialState: vlansInitialState,
  reducers: {
    getVlansStart(state) {
      state.isLoading = true;
    },
    getVlansSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((vlan) => {
          vlan['key'] = vlan.id;
          vlan.assignments = vlan.children;
          delete vlan.children;
          return vlan;
        });
      } else {
        state.data = [];
      }
    },
    getVlansFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getVlansStart, getVlansSuccess, getVlansFailure } = vlans.actions;

export default vlans.reducer;

export const fetchVlans = target => async (dispatch) => {
  try {
    dispatch(getVlansStart());
    const vlans = await getVlans(target);
    dispatch(getVlansSuccess(vlans));
  } catch (err) {
    dispatch(getVlansFailure(err.toString()));
  }
};
