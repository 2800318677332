const MIN_PORT = 1;
const MAX_PORT = 65535;

const portValidator = () => ({
  validator(_, value) {
    try {
      const numPort = Number.parseInt(value);
      if (
        Number.isInteger(numPort) && numPort >= MIN_PORT && numPort <= MAX_PORT
      ) {
        return Promise.resolve();
      }
      return Promise.reject('Port is a number between 1 and 65535');
    } catch {
      return Promise.reject('Port is a number between 1 and 65535');
    }
  },
})

export default portValidator;