import { createSlice } from '@reduxjs/toolkit';
import { getUser } from '../api';

const userInitialState = {
  data: {},
  isLoading: false,
  error: null
};

const user = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    getUserStart(state) {
      state.isLoading = true;
    },
    getUserSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data;
    },
    getUserFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getUserStart, getUserSuccess, getUserFailure } = user.actions;

export default user.reducer;

export const fetchUser = async (dispatch) => {
  try {
    dispatch(getUserStart());
    const user = await getUser();
    dispatch(getUserSuccess(user));
  } catch (err) {
    dispatch(getUserFailure(err.toString()));
  }
};
