import { createSlice } from '@reduxjs/toolkit';
import { getMetalClusters } from '../api';
import { fetchCapabilities } from './clusterCapabilitySlice';

const clustersInitialState = {
  data: [],
  target: undefined,
  isLoading: false,
  error: null
};

const clusters = createSlice({
  name: 'clusters',
  initialState: clustersInitialState,
  reducers: {
    getClustersStart(state) {
      state.isLoading = true;
    },
    getClustersSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((cluster) => {
          return {
            key: cluster?.uuid,
            connected: true,
            clusterName: cluster?.name,
            clusterUuid: cluster?.uuid,
            location: 'Metal',
            ...cluster
          };
        });
        state.target = state.target || state.data[0].clusterUuid;
      } else {
        state.data = [];
        state.target = undefined;
      }
    },
    getClustersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
      state.target = undefined;
    },
    updateClusterTarget(state, { payload }) {
      state.target = payload;
      return state;
    }
  }
});

export const { getClustersStart, getClustersSuccess, getClustersFailure, updateClusterTarget } = clusters.actions;

export default clusters.reducer;

export const fetchClusters = async (dispatch) => {
  try {
    dispatch(getClustersStart());
    const clusters = await getMetalClusters();
    dispatch(getClustersSuccess(clusters));
    dispatch(fetchCapabilities(clusters.target));
  } catch (err) {
    console.error(err.toString());
    dispatch(getClustersFailure(err.toString()));
  }
};