import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import notification from '../../utils/notification';
import Button from 'antd/lib/button';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import AddKubeModal from '../../components/modals/addKubeModal';
import { fetchKubernetes } from '../../reducers/kubernetesSlice';
import { fetchKubernetesVersions } from '../../reducers/kubernetesVersionsSlice';
import { addNestedCluser } from '../../api';
import ShowStatus from '../../components/showstatus';
import { disableCreation } from '../../utils';

import './index.scss';

import { stringSorter } from '../../utils';

const Kubernetes = () => {
  const [visible, toggleVisible] = useState(false);
  const [isAdding, toggleIsAdding] = useState(false);
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.kubernetes);
  const { data: kubeVersions } = useSelector((state) => state.kubernetesVersions);
  const { data: capabilities } = useSelector((state) => state.capability);
  const { target } = useSelector((state) => state.clusters);

  const history = useHistory();

  useEffect(() => {
    dispatch(fetchKubernetesVersions(target));
    dispatch(fetchKubernetes(target));
  }, [dispatch, target])

  const handleKubeInfo = (name) => (evt) => {
    evt.preventDefault();
    history.push(`/kubernetes/${name}`);
  }
  
  const kubeColumns = [
    {
      title: 'NAME',
      dataIndex: 'displayName',
      width: 150,
      sorter: stringSorter('name')
    },
    {
      title: 'PODS',
      dataIndex: 'podCount',
      width: 200,
    },
    {
      title: 'LOAD BALANCERS',
      dataIndex: 'loadbalancers',
      width: 200,
      render: (loadbalancers) => <span>{loadbalancers ? loadbalancers.length : 0}</span>
    },
    {
      title: 'UTILISATION',
      dataIndex: 'utilisation',
      render: utilisation => {
        return (
          <div>
            {utilisation ? (utilisation.cpu / 1000).toFixed(1) : 'Unknown'} CPU
            <br/>
            {utilisation ? utilisation.mem.toFixed(1) + 'Mi' : 'Unknown'} RAM
          </div>
        );
      }
    },
    {
      title: 'HEALTH',
      dataIndex: 'health',
      width: 200,
      sorter: (a, b) => a.health > b.health,
      render: (health) => <ShowStatus status={health} />
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 160,
      className: 'action-column',
      render: (_, record, index) => {
        return (
          <Button onClick={handleKubeInfo(record.clusterId)}>Manage</Button>
        );
      }
    }
  ];

  useEffect(() => {
    function getKubeServices() {
      dispatch(fetchKubernetes(target));
      dispatch(fetchKubernetesVersions(target));
    }
    getKubeServices();
    const interval = setInterval(() => getKubeServices(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);

  const closeModal = () => {
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const handleCreate = (form) => {
    toggleIsAdding(true);
    return addNestedCluser({
      target,
      ...form.getFieldsValue(),
    }).then((res) => {
      toggleIsAdding(false);
      if (res.status === 200) {
        closeModal();
        form.resetFields();
        dispatch(fetchKubernetes(target));
        notification.success({
          message: 'New Nested Cluster has been added',
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    }).catch(e => {
      if (e.response?.data) {
        notification.error({
          message: e.response?.data,
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
      toggleIsAdding(false);
    });
  };

  return (
    <div>
      <Header
        type='button'
        buttonName='Create'
        buttonDisabled={disableCreation(capabilities)}
        items={data}
        title={{
          singular: 'Kubernetes Service',
          plural: 'Kubernetes Services'
        }}
        loading={true}
        openModal={openModal}
        description='See your kubernetes services running FLUID in your data center or at your edge'
      />
      <BaseTable
        columns={kubeColumns}
        data={data.map((el, index)=> ({ ...el, key: `kubernetes-${index}` }))}
        loading={isLoading}
        error={error}
        rowKey={(record) => record.key}
        id={'id'}
        emptyRenderText={'No existing services'}
      />
      <AddKubeModal
        target={target}
        visible={visible}
        loading={isAdding}
        handleCreate={handleCreate}
        onCancel={closeModal}
        versions={kubeVersions}
      />
    </div>
  );
};

export default Kubernetes;
