import { createSlice } from '@reduxjs/toolkit';
import { getSvcs } from '../api';

const svcsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const svcs = createSlice({
  name: 'svcs',
  initialState: svcsInitialState,
  reducers: {
    getSvcsStart(state) {
      state.isLoading = true;
    },
    getSvcsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data.map(data => ({
        ...data,
        ip: data.spec?.clusterIP
      }));
    },
    getSvcsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getSvcsStart, getSvcsSuccess, getSvcsFailure } = svcs.actions;

export default svcs.reducer;

export const fetchSvcs = (target) => async (dispatch) => {
  try {
    dispatch(getSvcsStart());
    const svcs = await getSvcs(target);
    dispatch(getSvcsSuccess(svcs));
  } catch (err) {
    dispatch(getSvcsFailure(err.toString()));
  }
};
