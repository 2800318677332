import React from 'react';
import '../index.scss';

const ExpandedClouds = (record, index, indent, expanded) => {
  return (
    expanded && (
      <>
        <div className='cloud-job-container'>
          <h5 className='cloud-job-label'>CLOUD STATE</h5>
          <h5 className='cloud-job-value'>{record.state}</h5>
        </div>
        <div className='cloud-job-container'>
          <h5 className='cloud-job-label'>STATUS</h5>
          <h5 className='cloud-job-value'>{record.status}</h5>
        </div>
      </>
    )
  );
};

export default ExpandedClouds;
