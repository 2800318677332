const ipValidator = () => ({
  validator(_, value) {
    // split the value
    const [, netmask] = String(value).split('/');
    const numNetmask = Number.parseInt(netmask);
    try {
      if (netmask > 32) {
        return Promise.reject('Netmask needs to be less than 32');
      }
      if (
        (String(value).match(/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\/[0-9]{1,2}/) && numNetmask <= 32) ||
        String(value).match(/[a-z0-9]{1,4}:[a-z0-9]{0,4}:[a-z0-9]{0,4}:[a-z0-9]{0,4}:[a-z0-9]{0,4}:[a-z0-9]{0,4}:[a-z0-9]{0,4}:[a-z0-9]{0,4}/)
      ) {
        return Promise.resolve();
      }
      return Promise.reject('Address does not match IPv4 or IPv6 format');
    } catch {
      return Promise.reject('Address does not match IPv4 or IPv6 format');
    }
  },
})

export default ipValidator;