import { createSlice } from '@reduxjs/toolkit';
import { getSwitchPorts } from '../api';

const switchPortsInitialState = {
  ips: {},
};

const switchPorts = createSlice({
  name: 'switchPorts',
  initialState: switchPortsInitialState,
  reducers: {
    getSwitchPortsStart(state, { payload }) {
      state.ips[payload.ip] = {};
      state.ips[payload.ip].isLoading = true;
    },
    getSwitchPortsSuccess(state, { payload }) {
      state.ips[payload.ip].isLoading = false;
      state.ips[payload.ip].error = null;
      state.ips[payload.ip].data = payload.data
        .filter(datum => datum.port != null && datum.port.length > 0)
        .sort((a, b) => (a.port < b.port ? -1 : 1))
        .sort((a, b) => (a.port.length < b.port.length ? -1 : 1));
    },
    getSwitchPortsFailure(state, { payload }) {
      state.ips[payload.ip].isLoading = false;
      state.ips[payload.ip].error = payload.error;
    }
  }
});

export const {
  getSwitchPortsStart,
  getSwitchPortsSuccess,
  getSwitchPortsFailure
} = switchPorts.actions;

export default switchPorts.reducer;

export const fetchSwitchPorts = (target, ip) => async (dispatch) => {
  try {
    dispatch(getSwitchPortsStart({ ip }));
    const switchPorts = await getSwitchPorts(target, ip);
    dispatch(getSwitchPortsSuccess({ data: switchPorts.data, ip } ));
  } catch (err) {
    dispatch(getSwitchPortsFailure({ ip, error: err.toString() }));
  }
};
