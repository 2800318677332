import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import notification from '../../utils/notification';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import TableMoreItems from '../../components/modals/tableMoreItems';
import { fetchAcls } from '../../reducers/aclsSlice';
import { deleteAcl } from '../../api';
import { useHistory } from 'react-router';
import { ipSorter, stringSorter } from '../../utils';

const Security = () => {
  const history = useHistory();
  
  const securityColumns = [
      {
        title: 'NAME',
        dataIndex: 'name',
        width: 250,
        sorter: stringSorter('name')
      },
      {
        title: 'PERMISSION',
        dataIndex: 'action',
        width: 40,
        sorter: stringSorter('action')
      },
      {
        title: 'PROTOCOL',
        dataIndex: 'protocol',
        width: 40,
        sorter: stringSorter('protocol')
      },
      {
        title: 'SOURCE',
        dataIndex: 'source',
        width: 150,
        sorter: ipSorter('source')
      },
      {
        title: 'SRC PORT',
        width: 110,
        dataIndex: 'src_port_from',
        render: (text, record) => {
          if (record.src_port_to) {
            return `${text}-${record.src_port_to}`;
          }
          return text;
        }
      },
      {
        title: 'DESTINATION',
        dataIndex: 'destination',
        width: 150,
        sorter: ipSorter('destination')
      },
      {
        title: 'DST PORT',
        width: 110,
        dataIndex: 'dst_port_from',
        render: (text, record) => {
          if (record.dst_port_to) {
            return `${text}-${record.dst_port_to}`;
          }
          return text;
        }
      },
      {
        title: '',
        dataIndex: 'actions',
        className: 'action-column',
        width: 20,
        render: (_, record) => (
          <TableMoreItems onDelete={openDeleteModal(record)} onUpdate={openUpdateWizard(record)} />
        )
      }
    ];

  const [loading, toggleLoading] = useState(false);
  const [visibleDeleteModal, toggleVisibleDeleteModal] = useState(false);
  const [current, setCurrent] = useState({});

  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.acls);
  const target = useSelector((state) => state.clusters?.target);

  useEffect(() => {
    function getAcls() {
      target && dispatch(fetchAcls(target));
    }
    getAcls();
    const interval = setInterval(() => getAcls(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);

  const handleUpdate = () => {
    target && dispatch(fetchAcls(target));
  };

  const openDeleteModal = (record) => () => {
    setCurrent(record);
    toggleVisibleDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setCurrent(null);
    toggleVisibleDeleteModal(false);
  };

  const handleDelete = async () => {
    toggleLoading(true);
    const body = {
      id: [current.id]
    };
    try {
      target && await deleteAcl(target, body).then((res) => {
        toggleLoading(false);
        if (res.data.error === 0) {
          toggleVisibleDeleteModal(false);
          target && dispatch(fetchAcls(target));
          notification.success({
            message: 'ACL has been deleted',
            placement: 'bottomRight'
          });
        } else {
          notification.error({
            message: 'Something went wrong, please try again later',
            placement: 'bottomRight'
          });
        }
      });
    } catch (e) {
      toggleLoading(false);
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    }
  };

  const openWizard = () => {
    history.push('/network/security/wizard/')
  };

  const openUpdateWizard = record => () => {
    history.push(`/network/security/wizard/${record.id}`);
  }

  return (
    <div>
      <Header
        type='button'
        buttonName='Add ACL'
        items={data}
        title={{
          plural: 'Security ACLs',
          singular: 'Security ACL'
        }}
        loading={isLoading}
        openModal={openWizard}
        onUpdate={handleUpdate}
        description='Manage ACL below'
        onRefresh={handleUpdate}
      />
      <BaseTable
        columns={securityColumns}
        data={data}
        loading={isLoading}
        error={error}
      />
      <DeleteItemModal
        visible={visibleDeleteModal}
        title='ACL'
        loading={loading}
        onDelete={handleDelete}
        onCancel={closeDeleteModal}
      />
    </div>
  );
};

export default Security;
