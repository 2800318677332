import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Col, Row, Spin } from 'antd';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { useHistory, useParams } from 'react-router';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import RenameItemModal from '../../components/modals/renameItemModal';

import Header from '../../components/header';
import notification from '../../utils/notification';
import { updateBreadcrumbs } from '../../reducers/breadcrumbSlice';
import { fetchKubernetesAssets } from '../../reducers/kubernetesAssetsSlice';
import { fetchNestedClusterDetails } from '../../reducers/nestedClusterDetails';
import { getKubeCtl, deleteNestedCluster, renameNestedCluser } from '../../api';

import NameSpaces from './namespaces';
import LoadBalancers from './loadBalancers';
import ActionButtons from './actionButtons';

import './index.scss';

const KubernetesDetails = () => {
  const { target } = useSelector((state) => state.clusters);
  const { data, isLoading } = useSelector((state) => state.nestedClusterDetails);
  const params = useParams();
  const history = useHistory();
  const { name: clusterId } = params;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const [isKubeConfigDownloading, setIsKubeConfigDownloading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (target) {
        try {
          dispatch(fetchNestedClusterDetails(clusterId, target))
          dispatch(fetchKubernetesAssets(clusterId, target))
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [clusterId, target, dispatch]);

  useEffect(() => {
    dispatch(updateBreadcrumbs([
      {
        title: 'Kubernetes',
        link: '/kubernetes'
      }, {
        title: 'Kubernetes Service Details'
      }
    ]))
    return () => {
      dispatch(updateBreadcrumbs([]));
    }
  }, [dispatch]);

  const goViewKubernetes = () => {
    history.push('/kubernetes');
  };

  const onDelete = (assets) => {
    setDeleteLoading(true);
    deleteNestedCluster(clusterId, target, assets)
      .then(() => {
        setDeleteLoading(false);
        setIsDeleteModalVisible(false);
        history.push('/kubernetes');
      }).catch(() => {
      notification.error({
        message: 'Something went wrong. Please try again later'
      })
      setDeleteLoading(false);
    })
  }

  const onRename = (newName) => {
    setRenameLoading(true);
    renameNestedCluser(target, clusterId, newName)
    .then(() => {
      setRenameLoading(false);
      setIsRenameModalVisible(false);
      dispatch(fetchNestedClusterDetails(clusterId, target))
    }).catch(() => {
      notification.error({
      message: 'Something went wrong. Please try again later'
      })
    })
    setRenameLoading(false);
  }

  const onCancelDelete = () => {
    setIsDeleteModalVisible(false);
  }

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  }

  const onCancelRename = () => {
    setIsRenameModalVisible(false);
  }

  const showRenameModal = () => {
    setIsRenameModalVisible(true);
  }

  const downloadKubeCtl = () => {
    setIsKubeConfigDownloading(true);
    return getKubeCtl(target, clusterId).then((res) => {
      const { data: file } = res;
      if (file) {
        const dataStr = window.URL.createObjectURL(new Blob([file]));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", `${data?.displayName}-kube-ctl.yml`);
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }
      setIsKubeConfigDownloading(false);
    }).catch(e => {
      setIsKubeConfigDownloading(false);
      if (e.response?.data) {
        notification.error({
          message: e.response?.data,
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    });
  }

  return (
    <div className="cluster-container">
      <Header
        items={undefined}
        title={undefined}
        description=' '
      />
      {data ? <div>
          <Spin spinning={isLoading}>
            <h2 style={{ color: '#006dc9' }}>{data.displayName}</h2>
          </Spin>
          <Row className='kube-stats-info'>
            <Spin spinning={isLoading} className='spinner'>
              <Row className='stats-container' justify='space-between'>
                <Col span={8} className='stats-info-block'>
                  <div className='stats-info-description'>
                    <div className='stats-value'>{data.podCount || 0}</div>
                    <div className='stats-sub-title'>Pods</div>
                  </div>
                </Col>
                <Col span={8} className='stats-info-block'>
                  <div className='stats-info-description'>
                    <div className='stats-value'>{data.utilisation?.cpu ?
                      (data.utilisation.cpu / 1000).toFixed(1) : 0}</div>
                    <div className='stats-sub-title'>CPU Utilisation</div>
                  </div>
                </Col>
                <Col span={8} className='stats-info-block'>
                  <div className='stats-info-description'>
                    <div className='stats-value'>{data.utilisation?.mem ? data.utilisation.mem.toFixed(1) : 0}Mi</div>
                    <div className='stats-sub-title'>Memory Utilisation</div>
                  </div>
                </Col>
              </Row>
            </Spin>
            <Row className='kube-actions-container' justify='space-between'>
              <Col span={12}>
                <Button className='action-btn' loading={isKubeConfigDownloading} onClick={() => downloadKubeCtl()}>Download Kube Config</Button>
                <Button className='action-btn' onClick={showRenameModal}>Rename</Button>
              </Col>
              <Col className='destroy-container' span={12}>
                <Button className='destroy-btn' type='danger' onClick={showDeleteModal}>Destroy</Button>
              </Col>
            </Row>
          </Row>
          <Row justify='space-between' gutter={40} className='cluster-detail-row'>
            <Col span={12}>
              <NameSpaces data={data.namespaces || []} isLoading={isLoading} />
            </Col>
            <Col span={12}>
              <LoadBalancers data={data.loadbalancers || []} isLoading={isLoading} />
            </Col>
          </Row>
          <ActionButtons/>
        </div> :
        <div className='nested-cluster-not-available'>
          <div className='message-container'>
            <ExclamationCircleOutlined />
            The cluster you are trying to access is not available. Please go back and try again.
          </div>
          <Button className='view-nested-cluster-btn' type='primary' onClick={goViewKubernetes}>View Kubernetes</Button>
        </div>
      }
      <DeleteItemModal
        title={'Cluster'}
        visible={isDeleteModalVisible}
        loading={deleteLoading}
        onCancel={onCancelDelete}
        onDelete={onDelete}
        deleteNestedCluster={true}
      />
      <RenameItemModal
        title={`Nested Cluster(${data.displayName})`}
        visible={isRenameModalVisible}
        loading={renameLoading}
        onCancel={onCancelRename}
        onRename={onRename}
      />
    </div>
  );
};

export default KubernetesDetails;
