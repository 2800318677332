import React from 'react';
import { Button, Col, Row } from 'antd';

import spotLogo from '../../assets/spot.png';
import { ReactComponent as AstraLogo } from '../../assets/logo-cc-astra.svg';

const ActionButtons = () => {

  return (
    <div>
      <h4 className='heading'>
        Easy Buttons
      </h4>
      <div className='action-button-container'>
        <Row justify='start'>
          <Col span={5} className={'action-col'}>
            <AstraLogo className='action-icon'/>
            <br/>
            <Button>Deploy Astra Data Store</Button>
          </Col>
          <Col span={1}/>
          <Col span={5} className={'action-col'}>
            <img src={spotLogo} className='spot-icon' alt="Spot Icon"/>
            <br/>
            <Button>Connect to Spot</Button>
          </Col>
        </Row>
      </div>
    </div>
  );

};

export default ActionButtons;
