import React, { useState } from 'react';
import Table from 'antd/lib/table/Table';
import ConfigProvider from 'antd/lib/config-provider';
import Empty from 'antd/lib/empty';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import DoubleLeftOutlined from '@ant-design/icons/DoubleLeftOutlined';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';

import './table.scss';

import { ReactComponent as ExpandedArrow } from '../../assets/expand_arrow_up.svg';
import { ReactComponent as EmptyImage } from '../../assets/ic_document.svg';

const PAGE_SIZE = 10;

const BaseTable = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const {
    columns,
    data,
    error,
    className,
    showCheckbox,
    noPagination,
    sortDirections = ['ascend', 'descend'],
    scroll,
    rowKey,
    pageSize,
    expandedRowRender,
    expandSingle,
    rowExpandable,
    emptyRenderText,
    loading
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = evt => {
    let pageNumber = Number(evt.target.value);
    pageNumber = Number.isInteger(pageNumber) ?
      pageNumber < data.length / (pageSize || PAGE_SIZE) ? pageNumber : Math.ceil(data.length / (pageSize || PAGE_SIZE))
      : undefined;
    pageNumber && evt.target.value && setCurrentPage(pageNumber);
  }

  const handleDoublePrevious = () => {
    setCurrentPage(1);
  }

  const handleDoubleNext = () => {
    setCurrentPage(Math.ceil(data.length / (pageSize || PAGE_SIZE)));
  }

  const handlePrevious = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  }

  const handleNext = () => {
    const max = Math.ceil(data.length / (pageSize || PAGE_SIZE));
    setCurrentPage( currentPage < max ? currentPage + 1 : max);
  }

  const paginationProps = {
    total: data.length,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    hideOnSinglePage: noPagination ? true : false,
    showQuickJumper: false,
    pageSize: pageSize || PAGE_SIZE,
    size: 'small',
    current: currentPage || 1,
    itemRender: (page, type) => {
      if (type === 'prev') {
        return <div className={'pagination-button-container'}>
          <Button onClick={handleDoublePrevious}><DoubleLeftOutlined /></Button>
          <Button onClick={handlePrevious}><LeftOutlined /></Button>
        </div>
      }
      if (type === 'next') {
        return <div className={'pagination-button-container'}>
          <Button onClick={handleNext}><RightOutlined /></Button>
          <Button onClick={handleDoubleNext}><DoubleRightOutlined /></Button>
        </div>
      }
      if (page === 1) {
        return <Input key={`input-${page}`} onChange={handlePageChange} defaultValue={1} value={currentPage} />
      }
      return undefined;
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  const onRow = ({ key }) => {
    return expandedRowKeys.includes(key) && { className: 'custom-table-expanded-row' };
  };

  const rowClass = (record, index) => {
    return 'custom-table-row';
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  const onExpand = (expanded, record) => {
    const key = record.key;
    const expandedKeys = expanded
      ? [key]
      : expandedRowKeys.filter((k) => k !== key);
    setExpandedRowKeys(expandedKeys);
  };

  return (
    <ConfigProvider renderEmpty={() => <Empty description={emptyRenderText || "No Data"} image={<EmptyImage />} />}>
      <Table
        columns={columns}
        dataSource={error && !data.length ? [] : data}
        onChange={onChange}
        pagination={!noPagination && (data.length > (pageSize || PAGE_SIZE) ? paginationProps : false)}
        className={className}
        rowClassName={rowClass}
        sortDirections={sortDirections}
        rowSelection={showCheckbox && rowSelection}
        scroll={scroll}
        rowKey={rowKey}
        onRow={onRow}
        loading={loading}
        expandable={{
          onExpand: onExpand,
          expandedRowKeys: expandSingle ? expandedRowKeys : undefined,
          expandRowByClick: !!expandedRowRender,
          expandedRowRender: expandedRowRender ? expandedRowRender : null,
          expandIconColumnIndex: showCheckbox ? columns.length + 2 : columns.length + 1,
          rowExpandable: rowExpandable,
          expandIcon: ({ expanded, onExpand, record, expandable }) => {
            return expandable ? (expanded ? (
              <ExpandedArrow style={{ color: '#c1cadc' }} onClick={() => onExpand(record)} />
            ) : (
              <ExpandedArrow style={{ color: '#c1cadc', transform: 'scaleY(-1)' }} onClick={() => onExpand(record)} />
            )) : <></>
          }
        }}
      />
    </ConfigProvider>
  );
}

export default BaseTable;
