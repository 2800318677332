import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import EasyButtons from './easyButtons';
import KubeClustersTable from './kubeClustersTable';
import VmTable from './vmTable';
import EnvironmentStats from './environmentStats';
import { fetchUser } from '../../reducers/userSlice';

const Overview = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch(fetchUser);
  }, [dispatch, token]);

  return (
    <div>
      <h2 style={{ color: '#006dc9' }}>Fluid Dashboard</h2>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <EnvironmentStats />
        </Col>
        <Col span={12}>
          <KubeClustersTable />
        </Col>
        <Col span={12}>
          <VmTable />
        </Col>
        <Col span={24}>
          <EasyButtons />
        </Col>
      </Row>
    </div>
  );
};

export default Overview;