import React from "react";
import { Button } from 'antd';
import './index.scss';

import { useHistory } from 'react-router';

const Complete = () => {
  const history = useHistory();

  const goCreateEnvironment = () => {
    history.push(`/onboarding/environment`);
  };

  const goDashboard = () => {
    history.push(`/overview`);
  };

  return (
    <div className='complete-container'>
      <h1>Onboarding Successful!</h1>
      <p>Create your first Environment:</p>
      <Button onClick={goCreateEnvironment}>Create Environment</Button>
      <br/>
      <p>Proceed to the Dashboard:</p>
      <Button onClick={goDashboard}>Dashboard</Button>
    </div>
  );
};

export default Complete;
