import { createSlice } from '@reduxjs/toolkit';
import { getCloudStatus } from '../api';

const cloudStatusInitialState = {
  data: [],
  chartData: [],
  isLoading: false,
  error: null
};

const cloudStatus = createSlice({
  name: 'cloudStatus',
  initialState: cloudStatusInitialState,
  reducers: {
    getCloudStatusStart(state) {
      state.isLoading = true;
    },
    getCloudStatusSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data;
      state.chartData = payload?.data?.map(({ stats_rx = [], stats_tx = [] }) => stats_rx.map((elem, index) => ({
        time: elem[1],
        rx: elem[0],
        tx: stats_tx[index][0]
      })));

      return state;
    },
    getCloudStatusFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const {
  getCloudStatusStart,
  getCloudStatusSuccess,
  getCloudStatusFailure
} = cloudStatus.actions;

export default cloudStatus.reducer;

export const fetchCloudStatus = (target, id) => async (dispatch) => {
  try {
    dispatch(getCloudStatusStart());
    const cloudStatus = await getCloudStatus(target, id);
    dispatch(getCloudStatusSuccess(cloudStatus));
  } catch (err) {
    dispatch(getCloudStatusFailure(err.toString()));
  }
};
