import React from 'react';
import { LineChart } from 'recharts/lib/chart/LineChart';
import { CartesianGrid } from 'recharts/lib/cartesian/CartesianGrid';
import { ResponsiveContainer } from 'recharts/lib/component/ResponsiveContainer';
import { Tooltip } from 'recharts/lib/component/Tooltip';
import { YAxis } from 'recharts/lib/cartesian/YAxis';
import { XAxis } from 'recharts/lib/cartesian/XAxis';
import { Line } from 'recharts/lib/cartesian/Line';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';

const CustomizedAxisTick = ({ x, y, payload }) => <g transform={`translate(${x},${y})`}>
  <text x={0} y={0} dy={16} textAnchor="end" fill="#666"
        transform="rotate(-35)">{format(fromUnixTime(payload.value), 'MMM dd HH:mm')}</text>
</g>

const labelFormatter = (value) => format(fromUnixTime(value), 'MMM dd HH:mm')

const Chart = ({ data }) => {
  return (
    data.length > 0 && (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey='time'
            allowDataOverflow={true}
            tick={<CustomizedAxisTick />}
            height={80}
          />
          <YAxis yAxisId="1"/>
          <YAxis yAxisId="2" orientation="right" />
          <Tooltip labelFormatter={labelFormatter} />
          <Line type='natural' dataKey='rx' name={'rx - octlets'} stroke='#8884d8' dot={false} yAxisId="1" />
          <Line type='natural' dataKey='tx' name={'tx - octlets'} stroke='#4ED3BA' dot={false} yAxisId="2" />
        </LineChart>
      </ResponsiveContainer>
    )
  );
};

export default Chart;
