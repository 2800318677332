export const icmpTypes = [
  {
    id: 0,
    name: 'Echo Reply'
  },
  {
    id: 1,
    name: 'Unassigned'
  },
  {
    id: 2,
    name: 'Unassigned'
  },
  {
    id: 3,
    name: 'Destination Unreachable'
  },
  {
    id: 4,
    name: 'Source Quench'
  },
  {
    id: 5,
    name: 'Redirect'
  },
  {
    id: 6,
    name: 'Alternate Host Address'
  },
  {
    id: 7,
    name: 'Unassigned'
  },
  {
    id: 8,
    name: 'Echo'
  },
  {
    id: 9,
    name: 'Router Advertisement'
  },
  {
    id: 10,
    name: 'Router Selection'
  },
  {
    id: 11,
    name: 'Time Exceeded'
  },
  {
    id: 12,
    name: 'Parameter Problem'
  },
  {
    id: 13,
    name: 'Timestamp'
  },
  {
    id: 14,
    name: 'Timestamp Reply'
  },
  {
    id: 15,
    name: 'Information Request'
  },
  {
    id: 16,
    name: 'Information Reply'
  },
  {
    id: 17,
    name: 'Address Mask Request'
  },
  {
    id: 18,
    name: 'Address Mask Reply'
  },
  {
    id: 30,
    name: 'Traceroute'
  },
  {
    id: 31,
    name: 'Datagram Conversion Error'
  },
  {
    id: 32,
    name: 'Mobile Host Redirect'
  },
  {
    id: 33,
    name: 'IPv6 Where-Are-You'
  },
  {
    id: 34,
    name: 'IPv6 I-Am-Here'
  },
  {
    id: 35,
    name: 'Mobile Registration Request'
  },
  {
    id: 36,
    name: 'Mobile Registration Reply'
  },
  {
    id: 37,
    name: 'Domain Name Request'
  },
  {
    id: 38,
    name: 'Domain Name Reply'
  },
  {
    id: 40,
    name: 'Photuris'
  }
];

export const googleRegions = [
  {
    name: 'OREGON',
    region: 'us-west1'
  },
  {
    name: 'LOS ANGELES',
    region: 'us-west2',
  },
  {
    name: 'SALT LAKE CITY',
    region: 'us-west3'
  },
  {
    name: 'LAS VEGAS',
    region: 'us-west4'
  },
  {
    name: 'IOWA',
    region: 'us-central1'
  },
  {
    name: 'SOUTH CAROLINA',
    region: 'us-east1'
  },
  {
    name: 'N. VIRGINIA',
    region: 'us-east4'
  },
  {
    name: 'MONTRÉAL',
    region: 'northamerica-northeast1'
  },
  {
    name: 'SÃO PAULO',
    region: 'southamerica-east1'
  },
  {
    name: 'LONDON',
    region: 'europe-west2'
  },
  {
    name: 'BELGIUM',
    region: 'europe-west1'
  },
  {
    name: 'FRANKFURT',
    region: 'europe-west3'
  },
  {
    name: 'NETHERLANDS',
    region: 'europe-west4'
  },
  {
    name: 'ZURICH',
    region: 'europe-west6'
  },
  {
    name: 'FINLAND',
    region: 'europe-north1'
  },
  {
    name: 'MUMBAI',
    region: 'asia-south1'
  },
  {
    name: 'SINGAPORE',
    region: 'asia-southeast1'
  },
  {
    name: 'JAKARTA',
    region: 'asia-southeast2'
  },
  {
    name: 'TAIWAN',
    region: 'asia-east1'
  },
  {
    name: 'HONG KONG',
    region: 'asia-east2'
  },
  {
    name: 'TOKYO',
    region: 'asia-northeast1'
  },
  {
    name: 'OSAKA',
    region: 'asia-northeast2'
  },
  {
    name: 'SEOUL',
    region: 'asia-northeast3'
  },
  {
    name: 'SYDNEY',
    region: 'australia-southeast1'
  },
];

export const awsRegions = [
  {
    name: 'US EAST (N. Virginia)',
    region: 'us-east-1',
  },
  {
    name: 'US EAST (Ohio)',
    region: 'us-east-2',
  },
  {
    name: 'US WEST (N. California)',
    region: 'us-west-1',
  },
  {
    name: 'US WEST (Oregon)',
    region: 'us-west-2',
  },
  {
    name: 'Africa (Cape Town)',
    region: 'af-south-1'
  },
  {
    name: 'Asia Pacific (Hong Kong)',
    region: 'ap-east-1'
  },
  {
    name: 'Asia Pacific (Mumbai)',
    region: 'ap-south-1'
  },
  {
    name: 'Asia Pacific (Seoul)',
    region: 'ap-northeast-2'
  },
  {
    name: 'Asia Pacific (Singapore)',
    region: 'ap-southeast-1'
  },
  {
    name: 'Asia Pacific (Sydney)',
    region: 'ap-southeast-2'
  },
  {
    name: 'Asia Pacific (Tokyo)',
    region: 'ap-northeast-1'
  },
  {
    name: 'Canada (Central)',
    region: 'ca-central-1'
  },
  {
    name: 'Europe (Frankfurt)',
    region: 'eu-central-1'
  },
  {
    name: 'Europe (Ireland)',
    region: 'eu-west-1'
  },
  {
    name: 'Europe (London)',
    region: 'eu-west-2'
  },
  {
    name: 'Europe (Milan)',
    region: 'eu-south-1'
  },
  {
    name: 'Europe (Paris)',
    region: 'eu-west-3'
  },
  {
    name: 'Europe (Stockholm)',
    region: 'eu-north-1'
  },
  {
    name: 'Middle East (Bahrain)',
    region: 'me-south-1'
  },
  {
    name: 'South America (São Paulo)',
    region: 'sa-east-1'
  }
];

export const PROVIDERS = {
  AWS: 'amazon',
  GOOGLE: 'google',
  AZURE: 'azure',
  PCCW: 'pccw',
  MEGAPORT: 'megaport',
  EQUINIX: 'equinix'
}