import React from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import portValidator from '../validators/portValidator';
import './modal.scss';

const AddBgpPeerModal = ({ visible, loading, handleCreate, onCancel, current }) => {
  const [form] = Form.useForm();

  const initialValues = {
    name: current ? current?.name : '',
    peerAddress: current ? current?.spec?.peerAddress : '',
    peerASN: current ? current?.spec?.peerASN.toString() : undefined,
    myASN: current ? current?.spec?.myASN.toString() : undefined,
    peerPort: current ? current?.spec?.peerPort.toString() : undefined
  }

  return (
    <Modal
      visible={visible}
      title={current ? 'Update BGP Peer' : 'Add BGP Peer'}
      onOk={handleCreate}
      onCancel={onCancel}
      width={600}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => handleCreate(form))
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          {current ? 'Update' : 'Create' }
        </Button>,
        <Button
          key='cancel'
          type='secondary'
          onClick={onCancel}
        >
          Cancel
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' requiredMark={'optional'} initialValues={initialValues} >
        <Row gutter={24}>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              rules={[{ required: true, message: 'Name is required'}]}
            >
              <Input disabled={current} className='modal-item-controller' placeholder='Name' maxLength={24} />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='peerAddress'
              className='modal-item-label'
              label='Peer Address'
              rules={[{ required: true, message: 'Peer Address is required'}]}
            >
              <Input className='modal-item-controller' placeholder='Peer Address' maxLength={24} />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='peerASN'
              className='modal-item-label'
              label='Peer ASN'
              rules={[{ required: true, message: 'Peer ASN is required', portValidator }]}
            >
              <InputNumber className='modal-item-controller' placeholder='Peer ASN'/>
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='myASN'
              className='modal-item-label'
              label='My ASN'
              rules={[{ required: true, message: 'My ASN is required', portValidator }]}
            >
              <InputNumber className='modal-item-controller' placeholder='My ASN'/>
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='peerPort'
              className='modal-item-label'
              label='Peer Port'
              rules={[{ required: true, message: 'Peer port is required', portValidator }]}
            >
              <InputNumber className='modal-item-controller' placeholder='Peer Port'/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddBgpPeerModal;
