import { combineReducers } from '@reduxjs/toolkit';
import clustersReducer from './clusterSlice';
import notificationReducer from './notificationSlice';
import cloudsReducer from './cloudSlice';
import cloudStatusReducer from './cloudStatusSlice';
import cloudPrefillReducer from './cloudPrefillSlice';
import cloudTemplateReducer from './cloudTemplateSlice';
import cloudCredentialReducer from './cloudCredentialSlice';
import NodesReducer from './nodesSlice';
import SvcsReducer from './svcsSlice';
import UserReducer from './userSlice';
import SpotReducer from './spotSlice';
import NetworksReducer from './networkSlice';
import SwitchPortsReducer from './switchPortsSlice';
import NatsReducer from './natsSlice';
import AclsReducer from './aclsSlice';
import ConfigReducer from './configSlice';
import BreadcrumbReducer from './breadcrumbSlice';
import WizardReducer from './wizardSlice';
import NatIpReducer from './natIpsSlice';
import PCCWReducer from './pccwSlice';
import SubnetsReducer from './subnetSlice';
import UnusedSubnetsReducer from './unusedSubnetsSlice'
import CircuitReducer from './circuitSlice';
import versionsReducer from './versionSlice';
import kubernetesReducer from './kubernetesSlice';
import vmsReducer from './vmsSlice';
import capacityReducer from './capacitySlice';
import kubernetesVersionsReducer from './kubernetesVersionsSlice';
import kubernetesAssetsReducer from './kubernetesAssetsSlice';
import nestedClusterDetailsReducer from './nestedClusterDetails';
import clusterCapabilityReducer from './clusterCapabilitySlice';
import vlansReducer from './vlansSlice';
import metalBootKeysReducer from './metalBootKeysSlice';
import bgpPeersReducer from './bgpPeersSlice';
import kubeApplicationsReducer from './kubeApplicationsSlice'
import sshKeysReducer from './sshKeysSlice'
import cloudInitConfigReducer from './cloudInitConfigSlice';
import clusterStateReducer from './clusterStateSlice';

const rootReducer = combineReducers({
  clusters: clustersReducer,
  notification: notificationReducer,
  clouds: cloudsReducer,
  cloudStatus: cloudStatusReducer,
  cloudPrefill: cloudPrefillReducer,
  cloudTemplate: cloudTemplateReducer,
  cloudCredential: cloudCredentialReducer,
  nodes: NodesReducer,
  svcs: SvcsReducer,
  capacity: capacityReducer,
  capability: clusterCapabilityReducer,
  user: UserReducer,
  spot: SpotReducer,
  networks: NetworksReducer,
  subnets: SubnetsReducer,
  unusedSubnets: UnusedSubnetsReducer,
  ports: SwitchPortsReducer,
  nats: NatsReducer,
  natIps: NatIpReducer,
  acls: AclsReducer,
  config: ConfigReducer,
  breadcrumbs: BreadcrumbReducer,
  wizard: WizardReducer,
  pccw: PCCWReducer,
  circuits: CircuitReducer,
  versions: versionsReducer,
  kubernetes: kubernetesReducer,
  kubernetesVersions: kubernetesVersionsReducer,
  kubernetesAssets: kubernetesAssetsReducer,
  vms: vmsReducer,
  nestedClusterDetails: nestedClusterDetailsReducer,
  vlans: vlansReducer,
  metalBootKeys: metalBootKeysReducer,
  bgpPeers: bgpPeersReducer,
  kubeApplications: kubeApplicationsReducer,
  sshKeys: sshKeysReducer,
  cloudInitConfig: cloudInitConfigReducer,
  clusterState: clusterStateReducer
});

export default rootReducer;
