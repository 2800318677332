import React, { useEffect, useState } from 'react';
import BaseTable from '../../components/table';
import { Button, Col, Row } from 'antd';
import AddSshKeyModal from '../../components/modals/addSshKeyModal';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import { deleteSshKey } from '../../api';
import notification from '../../utils/notification';
import { useSelector, useDispatch } from 'react-redux';
import { fetchClusters } from '../../reducers/clusterSlice';
import { fetchSshKeys } from '../../reducers/sshKeysSlice';

const SshKeys = ({ clusterDetails }) => {
  const { data, isLoading, error } = useSelector((state) => state.sshKeys);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedSshKey, setSelectedSshKey] = useState('');
  const [currentCluster, setCurrentCluster] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentCluster(clusterDetails?.uuid);
    dispatch(fetchSshKeys(clusterDetails?.uuid));
  }, [clusterDetails, dispatch])

  const columns = [
    {
      title: 'Key',
      dataIndex: 'keyString',
      width: 1200,
      render: (text) => (
        <>
          {
            text.substring(0, text.indexOf(' ')) + ' ... ' + text.substring(text.lastIndexOf(' '))
          }
        </>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 150,
      className: 'action-column',
      render: (_, record, index) => {
        return <Button onClick={(e) => showDeleteModal(record)}>Delete</Button>;
      }
    }
  ];

  const handleDeleteSshKey = async () => {
    try {
      await deleteSshKey(currentCluster, selectedSshKey.uuid);
      dispatch(fetchClusters);
      dispatch(fetchSshKeys(currentCluster));
      setIsDeleteModalVisible(false);
    } catch (err) {
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    }
  }

  const onCancelAdd = () => {
    setIsAddModalVisible(false);
  }

  const onCancelDelete = () => {
    setIsDeleteModalVisible(false);
  }

  const showDeleteModal = (record) => {
    setSelectedSshKey(record);
    setIsDeleteModalVisible(true);
  }

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  return (
    <div className='node-container'>
      <div className='header'>
        <div className='row'>
          <div className='node-header-description'>
            <span className='item-count'>{data?.length || 0}</span> SSH Keys
          </div>
        </div>
      </div>
      <div className='node-list'>
        <Row>
          <BaseTable
            columns={columns}
            data={data}
            className='node-table'
            pageSize={5}
            isLoading={isLoading}
            error={error}
          />
        </Row>
        <Row justify='space-between' className='vm-footer'>
          <Col>
            <Button
              className='action-btn'
              onClick={showAddModal}
            >
              Add SSH Key
            </Button>
          </Col>
        </Row>        
      </div>
      <AddSshKeyModal
        title={'Add SSH Key'}
        visible={isAddModalVisible}
        onCancel={onCancelAdd}
        metalClusterInfo={clusterDetails}
      />
      <DeleteItemModal
        title={'SSH Key'}
        visible={isDeleteModalVisible}
        onCancel={onCancelDelete}
        onDelete={handleDeleteSshKey}
      />
    </div>
  );
};

export default SshKeys;
