import React  from 'react';
import { Modal, Button } from 'antd';
import './modal.scss';
import styled from 'styled-components';

const PaddedBlock = styled.div`
    padding-bottom: 1em;
`;

const VersionsModal = ({ visible, onClose, cloudVersion, maestroVersion }) => {

  return (
    <Modal
      visible={visible}
      title='Versions'
      onClose={onClose}
      width={500}
      footer={[
        <Button
          key='Close'
          type='primary'
          onClick={onClose}
        >
          Close
        </Button>
      ]}
    >
      <div>
        <PaddedBlock>
          <h3>Fluid API Version</h3>
          <p>Version: { cloudVersion ? `${cloudVersion.version} (${cloudVersion.sha.substring(0,7)})` : 'Error obtaining version' }</p>
        </PaddedBlock>

        <PaddedBlock>
          <h3>Fluid Cluster Version</h3>
          <p>Version: { maestroVersion ?  `${maestroVersion.version} (${maestroVersion.sha.substring(0,7)})` : 'Error obtaining version' }</p>
        </PaddedBlock>

        <PaddedBlock>
          <h3>Fluid Web Version</h3>
          <p>Version: {`${process.env.REACT_APP_BUILD_VERSION} (${process.env.REACT_APP_BUILD_HASH})` }</p>
        </PaddedBlock>
      </div>
    </Modal>
  );
};

export default VersionsModal;
