import React from "react";

const Empty = () => {
  return (
    <div>
      <h1>404 not found</h1>
    </div>
  );
};

export default Empty;
