import React from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import { Typography } from 'antd';
import './modal.scss';

const { Text } = Typography

const InfoModal = ({ visible, title, loading, onClose, message }) => {

  const handleClose = () => {
    onClose();
  }

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onClose}
      width={500}
      footer={[
        <Button key='confirm' loading={loading} type='primary' onClick={handleClose}>
          Close
        </Button>
      ]}
    >
      <div>
        {message ? 
          <Text code>{message}</Text> : 
          ''
        }
      </div>
    </Modal>
  );
};

export default InfoModal;
