import React, { useEffect, useState } from 'react';
import { Checkbox, Select } from 'antd';
import BaseTable from '../../../../components/table';
import { stringSorter } from '../../../../utils';

import './nodeTable.scss';

const { Option } = Select;

const NodeTable = ({ data, versions, createMetalData, editMode }) => {
  const [nodeData, setNodeData] = useState([]);
  const [nodeVersions, setNodeVersions] = useState([]);
  const [genesisIndex, setGenesisIndex] = useState(0);
  const [genesisNode, setGenesisNode] = useState(data[0]?.pairingKey);

  useEffect(() => {
    setNodeData(data);
    setNodeVersions(versions);
  }, [data, versions]);

  useEffect(() => {
    if (editMode) {
      createMetalData({
        nodes: nodeData
      });
    } else {
      createMetalData({
        nodes: nodeData,
        genesisNode
      });
    }
    // eslint-disable-next-line
  }, [nodeData, genesisNode])

  /*
    Parse the entered pairingKeys into and array of pairingKeys
    Send pairingKey array to validation api
    Print out green/red tags depending on validity
    Create a node object for each valid pairingKey in setNodeData
    Use nodeData as the table data
    Update the nodeData when onclick checkbox or select occurs
    On next button, submit the nodeData (possibly map data to correct structure) to api 
  */

  const toggleGenesisFactory = (rowIndex, pairingKey) => event => {
    const newGenesisIndex = rowIndex;
    setGenesisIndex(newGenesisIndex);
    setGenesisNode(pairingKey);
  };

  const handleRoleChange = (value, pairingKey) => {
    const newNodesData = nodeData.map(data => {
      if (data.pairingKey === pairingKey) {
        data.role = value
      }
      return data;
    })
    setNodeData(newNodesData);
  }

  const handleKernelChange = (value, pairingKey) => {
    const newNodesData = nodeData.map(data => {
      if (data.pairingKey === pairingKey) {
        data.kernelVersion = value
      }
      return data;
    })
    setNodeData(newNodesData);
  }

  const handleOsChange = (value, pairingKey) => {
    const newNodesData = nodeData.map(data => {
      if (data.pairingKey === pairingKey) {
        data.osVersion = value
      }
      return data;
    })
    setNodeData(newNodesData);
  }

  const handleKubeChange = (value, pairingKey) => {
    const newNodesData = nodeData.map(data => {
      if (data.pairingKey === pairingKey) {
        data.kubeVersion = value
      }
      return data;
    })
    setNodeData(newNodesData);
  }

  const columns = [
    {
      title: 'Pairing Key',
      dataIndex: 'pairingKey',
      key: 'pairingKey',
      sorter: stringSorter('pairingKey')
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, record, index) => (
        <Select defaultValue={'MASTER'} onChange={(value) => handleRoleChange(value, record.pairingKey)}>
          <Option value='MASTER'>Master</Option>
          <Option value='AGENT'>Worker</Option>
        </Select>
      )
    },
    {
      title: 'Genesis',
      dataIndex: 'genesis',
      key: 'genesis',
      render: (value, record, index) => (
        <Checkbox checked={index === genesisIndex} onChange={toggleGenesisFactory(index, record.pairingKey)}/>
      )
    },
    {
      title: 'Kernel',
      dataIndex: 'kernelVersion',
      key: 'kernel',
      render: (value, record, index) => (
        <Select defaultValue={value} onChange={(value) => handleKernelChange(value, record.pairingKey)}>
          {nodeVersions?.kernel.length > 0 && <>
            {nodeVersions?.kernel.map(version => (
              <Option value={version.value}>{version.table}</Option>
            ))}
          </>}
        </Select>
      )
    },
    {
      title: 'OS Version',
      dataIndex: 'osVersion',
      key: 'os',
      render: (value, record, index) => (
        <Select defaultValue={value} onChange={(value) => handleOsChange(value, record.pairingKey)}>
          {nodeVersions?.os.length > 0 && <>
            {nodeVersions?.os.map(version => (
              <Option value={version.value}>{version.table}</Option>
            ))}
          </>}
        </Select>
      )
    },
    {
      title: 'Kube Version',
      dataIndex: 'kubeVersion',
      key: 'kube',
      render: (value, record, index) => (
        <Select defaultValue={value} onChange={(value) => handleKubeChange(value, record.pairingKey)}>
          {nodeVersions?.kube.length > 0 && <>
            {nodeVersions?.kube.map(version => (
              <Option value={version.value}>{version.table}</Option>
            ))}
          </>}
        </Select>
      )
    }
  ]

  return (
    <div className='table-container'>
      <h4 className='heading'>Nodes</h4>
      <div className='kube-container'>
        <BaseTable
          columns={editMode ? columns.filter((column) => column.key !== 'genesis') : columns}
          data={nodeData}
          error={null}
          rowKey={(record) => record.pairingKey}
          id={'id'}
          emptyRenderText={'No nodes'}
        />
      </div>
    </div>
  );
};

export default NodeTable;
