import { validateName } from '../../api';

const nameValidator = (target) => ({
  async validator(_, value) {
    const name = { name: String(value) };
    try {
      const { data: validName } = await validateName(target, name);
      if (validName.valid === true) {
        return Promise.resolve();
      }
      return Promise.reject('Name is invalid');
    } catch (err) {
      return Promise.reject('Unable to validate name');
    }
  },
})

export default nameValidator;