import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin, Alert } from 'antd';
import ClusterOutlined from '@ant-design/icons/ClusterOutlined';
//import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import CloudServerOutlined from '@ant-design/icons/CloudServerOutlined';
import CloudOutlined from '@ant-design/icons/CloudOutlined';
import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import { fetchClouds } from '../../../reducers/cloudSlice';

import './environmentStats.scss';

const EnvironmentStats = () => {
  const envs = useSelector((state) => state.clusters);
  const kube = useSelector((state) => state.kubernetes);
  const vms = useSelector((state) => state.vms);
  const clouds = useSelector((state) => state.clouds);
  const { target } = useSelector((state) => state.clusters);
  const { data: capabilities } = useSelector((state) => state.capability);
  const dispatch = useDispatch();

  const stats = {
    environments: `${envs.data.filter((env) => { return env.connected }).length}/${envs.data.length}`,
    clusters: kube.data.length,
    vms: vms.data.length,
    cloudConnections: clouds.data.length
  }

  useEffect(() => {
    dispatch(fetchClouds(target));
  }, [dispatch, target]);

  return (
    <div>
      { capabilities && capabilities?.setupComplete === false ?
        <div className='alert'>
          <Alert
            description="Environment setup has not yet completed. Creation of kubernetes clusters, VMs, and datavolumes is disabled until setup is completed. 
            If you have not yet plugged in more nodes, please plug them in and wait for bootstrapping."
            type="warning"
            showIcon
            >
          </Alert>
        </div>
        : <></>
      }
      { capabilities && capabilities?.setupComplete === true && capabilities?.hasCapacity === false ?
        <div className='alert'>
          <Alert
            description="Capacity is currently limited in this environment. Creation of kubernetes clusters, VMs, and datavolumes is disabled until more capacity is added."
            type="error"
            showIcon
            >
          </Alert>
        </div>
        : <></>
      }
      <h4 className='heading'>
        Environment Stats
      </h4>
      <Spin spinning={envs.isLoading || kube.isLoading || vms.isLoading}>
        <Row justify='space-between' className='env-stats-info'>
          <Row justify='space-between'>
            <Col span={5} className='stats-info-block'>
              <div className='icon-block'>
                <AppstoreOutlined />
              </div>
              <div className='stats-info-description'>
                <div className='stats-value'>{stats.environments}</div>
                <div className='stats-sub-title'>Environments</div>
              </div>
            </Col>
            <Col span={5} className='stats-info-block'>
              <div className='icon-block'>
                <ClusterOutlined />
              </div>
              <div className='stats-info-description'>
                <div className='stats-value'>{stats.clusters}</div>
                <div className='stats-sub-title'>Kube Clusters</div>
              </div>
            </Col>
            <Col span={5} className='stats-info-block'>
              <div className='icon-block'>
                <CloudServerOutlined />
              </div>
              <div className='stats-info-description'>
                <div className='stats-value'>{stats.vms}</div>
                <div className='stats-sub-title'>Virtual Machines</div>
              </div>
            </Col>
            <Col span={5} className='stats-info-block'>
              <div className='icon-block'>
              <CloudOutlined />
              </div>
              <div className='stats-info-description'>
                <div className='stats-value'>{stats.cloudConnections}</div>
                <div className='stats-sub-title'>Cloud Connections</div>
              </div>
            </Col>
          </Row>
        </Row>
      </Spin>
    </div>
  );
};

export default EnvironmentStats;
