import React, { useState } from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import './modal.scss';

const RenameItemModal = ({ visible, title, loading, onRename, onCancel }) => {
  const [renameValue, setRenameValue] = useState('');

  const onClickRename = () => {
    setRenameValue('');
    onRename(renameValue);
  }

  const handleCancel = () => {
    setRenameValue('');
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      title={`Rename ${title}`}
      onOk={onRename}
      onCancel={handleCancel}
      width={500}
      footer={[
        <Button key='submit' loading={loading} type='primary' onClick={onClickRename}>
          Rename
        </Button>,
        <Button key='cancel' type='secondary' onClick={handleCancel}>
          Cancel
        </Button>
      ]}
    >
      <div className='rename-body'>
        <p className='rename-network-modal-text'>Enter a new name for {title}:</p>
        <Input
          placeholder={'cluster name'}
          value={renameValue}
          onChange={(evt) => setRenameValue(evt.target.value)}
        />
      </div>
    </Modal>
  );
};

export default RenameItemModal;
