import { createSlice } from '@reduxjs/toolkit';
import { getMaestroNotification, getNotification } from '../api';

const NOTIFICATION_READ_TIMESTAMP = 'notification-read-timestamp';

const notificationInitialState = {
  notificationClusters: {}
};

const notification = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    getNotificationStart(state, { payload }) {
      state.notificationClusters[payload.target] = {};
      state.notificationClusters[payload.target].isLoading = true;
      state.notificationClusters[payload.target].lastReadTimestamp = window.localStorage.getItem(`${NOTIFICATION_READ_TIMESTAMP}-${payload.target}`);
      state.notificationClusters[payload.target].unreadCnt = 0;
    },
    getNotificationSuccess(state, { payload }) {
      state.notificationClusters[payload.target].isLoading = false;
      state.notificationClusters[payload.target].error = null;
      const mappedData = payload?.data.sort((a, b) => {
        const aTimestamp = a.timestamp ? new Date(a.timestamp).getTime() : 0;
        const bTimestamp = b.timestamp ? new Date(b.timestamp).getTime() : 0;
        return bTimestamp - aTimestamp;
      }).map(elem => ({
        ...elem,
        isRead: elem.timestamp ? new Date(elem.timestamp).getTime() < state.notificationClusters[payload.target].lastReadTimestamp : true,
      }))
      state.notificationClusters[payload.target].data = mappedData;
      state.notificationClusters[payload.target].unreadCnt = mappedData.filter(elem => !elem.isRead).length || 0;
    },
    getNotificationFailure(state, { payload }) {
      state.notificationClusters[payload.target].isLoading = false;
      state.notificationClusters[payload.target].error = payload.error;
    },
    setNotificationReadTimestamp(state, { payload }) {
      window.localStorage.setItem(`${NOTIFICATION_READ_TIMESTAMP}-${payload.target}`, payload.timestamp);
      state.notificationClusters[payload.target].lastReadTimestamp = payload.timestamp;
      const mappedData = state.notificationClusters[payload.target].data.map(elem => {
        return {
          ...elem,
          isRead: elem.timestamp ? new Date(elem.timestamp).getTime() < payload.timestamp : true,
        }
      });
      state.notificationClusters[payload.target].data = mappedData;
      state.notificationClusters[payload.target].unreadCnt = mappedData.filter(elem => !elem.isRead).length || 0;
    }
  }
});

export const {
  getNotificationStart,
  getNotificationSuccess,
  getNotificationFailure,
  setNotificationReadTimestamp
} = notification.actions;

export default notification.reducer;

export const fetchNotification = target => async (dispatch) => {
  try {
    dispatch(getNotificationStart({ target }));
    const notification = await getNotification();
    const maestroNotification = await getMaestroNotification(target);
    dispatch(getNotificationSuccess({
      target,
      data: (notification?.data || []).concat((maestroNotification?.data || []).map(elem => ({
        id: elem._id,
        ...elem
      })))
    }));
  } catch (err) {
    dispatch(getNotificationFailure({ target, error: err.toString() }));
  }
};