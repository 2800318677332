import React from 'react';
import { Row, Button } from 'antd';
import { splitSwitch, unsplitSwitch } from '../../../api'

import ShowStatus from '../../../components/showstatus';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSwitchPorts } from '../../../reducers/switchPortsSlice';

const Port = ({ data, ip }) => {
  const target = useSelector(state => state.clusters?.target);

  const dispatch = useDispatch();

  const handleSplit = (e) => {
    e.preventDefault();
    target && !data.split && splitSwitch(target, ip, data.port).then(() => {
      dispatch(fetchSwitchPorts(target, ip));
    })
  }

  const handleUnsplit = (e) => {
    e.preventDefault();
    target && data.split && unsplitSwitch(target, ip, data.port).then(() => {
      dispatch(fetchSwitchPorts(target, ip));
    })
  }

  return (
    <>
      <Row justify='space-between' align='bottom'>
        <Row className='port-title'>
            <h4 className='m-0 port-title'>{data.port}</h4>
            <ShowStatus status={data.status}/>
        </Row>
        <Row className='port-speed-details'>
          <span className='port-speed'>Speed: {data.speed}</span>
          <div className='port-button-container'>
          <Button className={`btn-portspeed ${data.split && ' active'}`} onClick={handleSplit}>10/25G</Button>
          <Button className={`btn-portspeed ${!data.split && ' active'}`} onClick={handleUnsplit}>40/100G</Button>
          </div>
        </Row>
      </Row>
      <hr />
    </>
  );
};

export default Port;
