import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import notification from '../../utils/notification';
import Button from 'antd/lib/button';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import AddVMModal from '../../components/modals/addVMModal';
import { fetchVMs } from '../../reducers/vmsSlice';
import { fetchKubernetes } from '../../reducers/kubernetesSlice';
import { fetchCapacity } from '../../reducers/capacitySlice';
import { addNewVM } from '../../api';
import { disableCreation } from '../../utils';

import './index.scss';

import { stringSorter, ipSorter } from '../../utils';

const VMs = () => {
  const { target } = useSelector((state) => state.clusters);
  const { data, isLoading, error } = useSelector((state) => state.vms);
  const { data: namespaces } = useSelector((state) => state.kubernetes);
  const { data: capacity } = useSelector((state) => state.capacity);
  const { data: capabilities } = useSelector((state) => state.capability);
  const [visible, toggleVisible] = useState(false);
  const [isAdding, toggleIsAdding] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    function getRelatedInformation() {
      dispatch(fetchVMs(target));
      dispatch(fetchKubernetes(target))
      dispatch(fetchCapacity(target));
    }
    getRelatedInformation();
    const interval = setInterval(() => getRelatedInformation(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);
  
  const vmColumes = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: 150,
      sorter: stringSorter('name')
    },
    {
      title: 'NAMESPACE',
      dataIndex: 'namespaceDisplay',
      width: 150,
      sorter: stringSorter('namespaceDisplay')
    },
    {
      title: 'RAM',
      dataIndex: 'ramUtilised',
      sorter: stringSorter('ramUtilised')
    },
    {
      title: 'CPU (%)',
      dataIndex: 'cpuUtilised',
      sorter: stringSorter('cpuUtilised')
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      sorter: stringSorter('status')
    },
    {
      title: 'IP ADDRESSES',
      dataIndex: 'ipAddresses',
      sorter: ipSorter('ipAddresses')
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 160,
      className: 'action-column',
      render: (_, record) => {
        return <Button onClick={handleVMInfo(record.name)}>VM Info</Button>
      }
    }
  ];

  const handleVMInfo = (name) => (evt) => {
    evt.preventDefault();
    history.push(`/vms/${name}`);
  }

  const closeModal = () => {
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const handleCreate = (form) => {
    toggleIsAdding(true);
    const formData = form.getFieldsValue()
    // convert Gi to Mi since API takes Mi
    formData.storage = formData.storage * 1024
    return addNewVM({
      target,
      ...formData
    }).then((res) => {
      toggleIsAdding(false);
      if (res.status === 200) {
        closeModal();
        dispatch(fetchVMs(target));
        notification.success({
          message: 'New Virtual Machine has been added',
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    }).catch(e => {
      if (e.response?.data) {
        notification.error({
          message: e.response?.data,
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
      toggleIsAdding(false);
    });
  };

  return (
    <div>
      <Header
        type='button'
        buttonName='Create'
        buttonDisabled={disableCreation(capabilities)}
        items={data}
        title={{
          singular: 'Virtual Machine',
          plural: 'Virtual Machines'
        }}
        loading={true}
        openModal={openModal}
        description='See your virtual machines running FLUID in your data center or at your edge'
      />
      <BaseTable
        columns={vmColumes}
        data={data.map((el, index)=> ({ ...el, key: `vm-${index}` }))}
        loading={isLoading}
        error={error}
        rowKey={(record) => record.key}
        id={'id'}
        emptyRenderText={'No existing virtual machines'}
      />
      <AddVMModal
        data={undefined}
        visible={visible}
        loading={isAdding}
        handleComplete={handleCreate}
        onCancel={closeModal}
        namespaces={namespaces}
        target={target}
        capacityLimits={{
          maxCores: capacity.maxCores,
          maxMem: capacity.maxMem
        }}
      />
    </div>
  );
};

export default VMs;
