import { createSlice } from '@reduxjs/toolkit';
import { getClusterCapabilities } from '../api';

const capabilityInitialState = {
  data: {},
  isLoading: false,
  error: null
};

const capability = createSlice({
  name: 'capability',
  initialState: capabilityInitialState,
  reducers: {
    getCapabilities(state) {
      state.isLoading = true;
    },
    getCapabilitySuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data || {};
    },
    getCapabilityFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = {};
    }
  }
});

export const { getCapabilities, getCapabilitySuccess, getCapabilityFailure } = capability.actions;

export default capability.reducer;

export const fetchCapabilities = (target) => async (dispatch) => {
  if (!target) {
    return;
  }
  
  try {
    dispatch(getCapabilities());
    const capability = await getClusterCapabilities(target);
    dispatch(getCapabilitySuccess(capability));
  } catch (err) {
    dispatch(getCapabilityFailure(err.toString()));
  }
};
