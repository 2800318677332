import { createSlice } from '@reduxjs/toolkit';
import { getCloudInitConfig } from '../api';

const cloudInitConfigInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const cloudInitConfig = createSlice({
  name: 'cloudInitConfig',
  initialState: cloudInitConfigInitialState,
  reducers: {
    getCloudInitConfigState(state) {
      state.isLoading = true;
    },
    getCloudInitConfigSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data || [];
    },
    getCloudInitConfigFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    }
  }
});

export const { getCloudInitConfigState, getCloudInitConfigSuccess, getCloudInitConfigFailure } = cloudInitConfig.actions;

export default cloudInitConfig.reducer;

export const fetchCloudInitConfig = (target) => async (dispatch) => {
  try {
    dispatch(getCloudInitConfigState());
    const cloudInitConfig = await getCloudInitConfig(target);
    dispatch(getCloudInitConfigSuccess(cloudInitConfig));
  } catch (err) {
    dispatch(getCloudInitConfigFailure(err.toString()));
  }
};
