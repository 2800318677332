import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'antd';
import BaseTable from '../../../components/table';
import { fetchAllVMs } from '../../../reducers/vmsSlice';
import { stringSorter } from '../../../utils';
import AddVMModal from '../../../components/modals/addVMModal';
import { fetchVMs } from '../../../reducers/vmsSlice';
import { fetchCapacity } from '../../../reducers/capacitySlice';
import { addNewVM } from '../../../api';
import notification from '../../../utils/notification';
import { disableCreation } from '../../../utils';

import './vmTable.scss';
import { useHistory } from 'react-router';

const VmTable = ({ title, value }) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [visible, toggleVisible] = useState(false);
  const [isAdding, toggleIsAdding] = useState(false);
  const { data, isLoading, error } = useSelector((state) => state.vms);
  const { target } = useSelector((state) => state.clusters);
  const { namespace } = useSelector((state) => state.kubernetes);
  const { data: namespaces } = useSelector((state) => state.kubernetes);
  const { data: capacity } = useSelector((state) => state.capacity);
  const { data: capabilities } = useSelector((state) => state.capability);

  useEffect(() => {
    function getVMs() {
      dispatch(fetchAllVMs(target));
      dispatch(fetchCapacity(target));
    }
    getVMs();
    const interval = setInterval(() => getVMs(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);

  const goViewVMs = () => {
    history.push('/vms');
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: stringSorter('name')
    },
    {
      title: 'NAMESPACE',
      dataIndex: 'namespaceDisplay',
      key: 'namespace',
      sorter: stringSorter('namespaceDisplay')
    },
    {
      title: 'CPU (%)',
      dataIndex: 'cpuUtilised',
      key: 'cpuUtilised'
    },
    {
      title: 'RAM',
      dataIndex: 'ramUtilised',
      key: 'ramUtilised'
    }
  ];

  const closeModal = () => {
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const handleCreate = (form) => {
    toggleIsAdding(true);
    return addNewVM({
      target,
      namespace,
      ...form.getFieldsValue(),
    }).then((res) => {
      toggleIsAdding(false);
      if (res.status === 200) {
        closeModal();
        dispatch(fetchVMs);
        notification.success({
          message: 'New Virtual Machine has been added',
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    }).catch(e => {
      if (e.response?.data) {
        notification.error({
          message: e.response?.data,
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
      toggleIsAdding(false);
    });
  };

  return (
    <div>
      <h4 className='heading'>Virtual Machines</h4>
      <div className='vm-container'>
        <BaseTable
          columns={columns}
          data={data}
          loading={isLoading}
          error={error}
          rowKey={(record) => record.dataValues?.id}
          id={'id'}
          emptyRenderText={'No existing Virtual Machines'}
        />
        <Row justify='space-between' className='vm-footer'>
          <Col>
            <Button 
              className='action-btn' 
              onClick={goViewVMs}
            >
              Manage VMs
            </Button>
            <Button
              className='action-btn'
              onClick={openModal}
              disabled={disableCreation(capabilities)}
            >
              Add VM
            </Button>
          </Col>
        </Row>
      </div>
      <AddVMModal
        data={undefined}
        visible={visible}
        loading={isAdding}
        handleComplete={handleCreate}
        onCancel={closeModal}
        namespaces={namespaces}
        target={target}
        capacityLimits={{
          maxCores: capacity.maxCores,
          maxMem: capacity.maxMem
        }}
      />
    </div>
  );
};

export default VmTable;
