import { createSlice } from '@reduxjs/toolkit';
import { getSubnets } from '../api';

const subnetsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const subnets = createSlice({
  name: 'subnets',
  initialState: subnetsInitialState,
  reducers: {
    getSubnetsStart(state) {
      state.isLoading = true;
    },
    getSubnetsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((subnet) => {
          subnet['key'] = subnet.id;
          subnet.assignments = subnet.children;
          delete subnet.children;
          return subnet;
        });
      } else {
        state.data = [];
      }
    },
    getSubnetsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getSubnetsStart, getSubnetsSuccess, getSubnetsFailure } = subnets.actions;

export default subnets.reducer;

export const fetchSubnets = target => async (dispatch) => {
  try {
    dispatch(getSubnetsStart());
    const subnets = await getSubnets(target);
    dispatch(getSubnetsSuccess(subnets));
  } catch (err) {
    dispatch(getSubnetsFailure(err.toString()));
  }
};
