import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Form from 'antd/lib/form';
import useForm from 'antd/lib/form/hooks/useForm';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import { useDispatch, useSelector } from 'react-redux';
import { addCircuit, updateCircuit, getCircuitFreePorts } from '../../../api';
import { useHistory, useParams, useLocation } from 'react-router';
import notification from '../../../utils/notification';
import BaseWizard from '../baseWizard';
import { mergeObjects } from '../../../utils';
import '../index.scss';
import './circuits.scss';

import WrappedSelect from '../../select';
import Checkbox from 'antd/lib/checkbox';
import { fetchCircuits } from '../../../reducers/circuitSlice';
import { fetchSubnets } from '../../../reducers/subnetSlice';
import { fetchUnusedSubnets } from '../../../reducers/unusedSubnetsSlice';

const { Option } = Select;

const gatewayValidator = ({ getFieldValue }) => ({
  validator() {
    const ipv4 = getFieldValue('members_ipv4');
    const ipv6 = getFieldValue('members_ipv6');
    if (ipv4.length || ipv6.length) {
      return Promise.resolve();
    } else {
      return Promise.reject('Require IPv4 or IPv6 Gateway');
    }
  }
})

const CircuitWizard = () => {
  const [currentData, setCurrentData] = useState({});
  const [loading, setLoading] = useState(false);
  //const [aware, setAware] = useState(false);
  const [freePorts, setFreePorts] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedPorts, setSelectedPorts] = useState([]);
  const [selectedCircuit, setSelectedCircuit] = useState({});

  const dispatch = useDispatch();
  const location = useLocation();

  const { target, subnets, unusedSubnets } = useSelector((state) => ({
    circuits: state.circuits,
    target: state.clusters?.target,
    subnets: state.subnets?.data,
    unusedSubnets: state.unusedSubnets?.data
  }));

  const parseCircuitData = (formData) => {
     const testGateways = selectedValues.map(selectedValue => {
      const subnet = ipv4Subnets.find(subnet => subnet.id === selectedValue);
      return {
        gateway: formData[`selected_gateway_${subnet?.name}`],
        gw_length: subnet?.length,
        version: subnet?.ip_version,
        nos: null
      };
    });

    const testMembers = formData.freeports.map(freePort => {
      return {
        port_id: freePort,
        vlan_id: formData[`selected_port_${freePort}`],
        tenant_id: 1,
        childPort: 0,
        parentPort: 0,
        member_state: 'active',
        lacp: !!formData[`selected_port_${freePort}_lag`] ? 'on' : 'off',
        port_name: freePorts?.find(elem => elem.id === freePort).name,
        portIsUntagged: !!formData[`selected_port_${freePort}_untagged`]
      }
    });

    const data = {
      id: location?.state ? selectedCircuit : null,
      name: formData.name,
      owner: 1,
      state: formData.state,
      provisioning: 1,
      tenants: [],
      sites: [1],
      gateways: testGateways,
      members: JSON.stringify(testMembers),
      va_mode: 0,
      va_native_vlan: null,
      va_vlans: ""
    };
    return data;
  }

  const params = useParams();
  const history = useHistory();

  //const { data = {} } = circuits;
  const [form] = useForm();

  useEffect(() => {
    target && dispatch(fetchCircuits(target));
    target && dispatch(fetchSubnets(target));
    target && dispatch(fetchUnusedSubnets(target));

    const getFreePorts = async () => {
      const result = await getCircuitFreePorts(target);
      const { data } = result || {};
      setFreePorts(data);
    }
    target && getFreePorts();
  }, [target, dispatch]);

  // const handleChangeAware = () => {
  //   setAware(form.getFieldValue('va_mode'));
  // };

  const initialValues = useCallback(() => {
    const gateways = location?.state?.gateways;
    const members_ipv4 = location.state?.members;

    const initVals = params?.wizardId && location.state ?
      {
        id: location.state?.id,
        name: location.state?.name,
        state: location.state?.state,
        members_ipv4: gateways.map(gateway => gateway?.subnet?.id), //get selected subnet ids
        freeports: members_ipv4.map(port => port.id)
      } :
      {
        state: 'active',
        aware: false
      }
    
    location.state && gateways.forEach(gateway => {
      initVals[`selected_gateway_${gateway?.subnet?.name}`] = gateway?.gateway;
    });

    location.state && members_ipv4.forEach(member => {
      initVals[`selected_port_${member.port_id}_lag`] = member?.lacp === 'on';
      initVals[`selected_port_${member.port_id}_enabled`] = member?.member_state === 'active';
      initVals[`selected_port_${member.port_id}`] = member?.vlan_id;
    });

    return initVals;
  }, [params, location])

  const wizardSteps = useMemo(() => [
    {
      title: 'Set VLAN details',
      name: 'VLAN',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
    {
      title: 'Define IP and Ports',
      name: 'IP and Ports',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
  ], [form]);

  const onCancelWizard = () => {
    history.push('/network/circuits');
  }

  const onComplete = async () => {
    // get form data and send
    setLoading(true);
    const formData = form.getFieldsValue();
    let combinedData = mergeObjects(currentData, formData);
    let transformedData = parseCircuitData(combinedData);

    params.wizardId ? updateCircuit(target, transformedData).then(() => {
      notification.success({
        message: 'Updated Circuit'
      });
      setLoading(false);
      setCurrentData({});
      history.push('/network/circuits');
    }).catch((e) => {
      setLoading(false);
      if (e?.response?.data?.msg?.message) {
        notification.error({
          message: e?.response?.data?.msg?.message
        });
      } else {
        notification.error({
          message: 'Sorry something went wrong. Please try again later.'
        });
      }
    }) : addCircuit(target, transformedData).then(() => {
      notification.success({
        message: 'New Circuit Added.'
      });
      setLoading(false);
      setCurrentData({});
      history.push('/network/circuits');
    }).catch((e) => {
      setLoading(false);
      if (e?.response?.data?.message) {
        notification.error({
          message: e?.response?.data?.message
        });
      } else {
        notification.error({
          message: 'Sorry something went wrong. Please try again later.'
        });
      }
    });
  }

  const onStepChange = () => {
    // We do this so we can expand on the field values since form resets the field value for each step
    setCurrentData(mergeObjects(currentData, form.getFieldsValue()));
  }

  const handleSelectIp = value => {
    setSelectedValues([...selectedValues, value]);
  }

  const handleDeselectIp = value => {
    const indexOfValue = selectedValues.indexOf(value);
    selectedValues.splice(indexOfValue, 1);
    setSelectedValues([...selectedValues]);
  }

  const handleSelectPort = value => {
    setSelectedPorts([...selectedPorts, value]);
  }

  const handleDeselectPort = value => {
    const indexOfValue = selectedPorts.indexOf(value);
    selectedPorts.splice(indexOfValue, 1);
    setSelectedPorts([...selectedPorts]);
  }

  useEffect(() => {
    const initVals = initialValues();
    if (params?.wizardId && location.state) {
      form.setFieldsValue(initVals);
      setSelectedPorts(initVals?.freeports);
      setSelectedValues(initVals?.members_ipv4);
      setSelectedCircuit(initVals?.id);
    }
  }, [form, initialValues, location, params]);

  const unusedIpv4 = () => {
    const unusedIpv4 = unusedSubnets?.filter?.(subnet => subnet.ip_version === 'ipv4');
    if (location?.state) {
      selectedValues.forEach(selectedValue => {
        const selected = subnets?.find(elem => elem.id === selectedValue);
        if (!unusedIpv4.find(subnet => subnet.id === selected.id)) {
          unusedIpv4.push(selected);
        }
      })
    }
    return unusedIpv4;
  }
  
  const ipv4Subnets = unusedIpv4();
  const ipv6Subnets = unusedSubnets?.filter?.(subnet => subnet.ip_version === 'ipv6');

  const selectedGatewayPrefix = (subnet) => (
    <div>{subnet?.name}</div>
  )

  const selectedPortPrefix = (port) => (
    <div>{port}</div>
  )

  return <>
    <BaseWizard
      completeText={params?.wizardId ? 'Update' : 'Create'}
      name={`${params?.wizardId ? 'Update' : 'Create'} Circuit`}
      onComplete={onComplete}
      onCancelWizard={onCancelWizard}
      steps={wizardSteps}
      loading={loading}
      onStepChange={onStepChange}
    >
      {(currentStep, setCurrentStep) =>
        <Form form={form} layout='vertical' initialValues={initialValues()} requiredMark={'optional'}>
          <Row gutter={24}>
            {currentStep === 0 &&
            <>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='name'
                  className='modal-item-label'
                  label='Name'
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input className='modal-item-controller' placeholder='Name' />
                </Form.Item>
              </Col>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='state'
                  className='modal-item-label'
                  label='State'
                  rules={[{ required: true, message: 'State is required' }]}
                >
                  <WrappedSelect className='modal-item-controller' defaultValue={'active'}>
                    <Option value='active'>Active</Option>
                    <Option value='disabled'>Disabled</Option>
                  </WrappedSelect>
                </Form.Item>
              </Col>
              {/* <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='va_mode'
                  className='modal-item-label'
                  label='VLAN Aware'
                  valuePropName={'checked'}
                >
                  <WrappedSelect className='modal-item-controller' defaultValue={false} onChange={handleChangeAware}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Disabled</Option>
                  </WrappedSelect>
                </Form.Item>
              </Col> */}
              {false && <>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='va_native_vlan'
                    className='modal-item-label'
                    label='Native VLAN ID'
                    rules={[{ required: true, message: 'Native VLAN ID is required' }]}
                  >
                    <Input className='modal-item-controller' placeholder='1' />
                  </Form.Item>
                </Col>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='va_vlans'
                    className='modal-item-label'
                    label='VLAN List'
                    rules={[{ required: true, message: 'VLAN List is required' }]}
                  >
                    <Input className='modal-item-controller' placeholder='1,2-10,20-30' />
                  </Form.Item>
                </Col>
              </>
              }
            </>
            }
            {currentStep === 1 && (
              <>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='members_ipv4'
                    className='modal-item-label gateway'
                    label='IPv4 Gateway'
                    //valuePropName={'checked'}
                    rules={[gatewayValidator]}
                  >
                    <WrappedSelect className='modal-item-controller' mode='multiple' onSelect={handleSelectIp}
                                   onDeselect={handleDeselectIp}>
                      {ipv4Subnets?.map(ip => <Option value={ip.id}><Checkbox
                        checked={selectedValues?.includes(ip.id)}>{ip.name}</Checkbox></Option>)}
                    </WrappedSelect>
                  </Form.Item>
                </Col>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='members_ipv6'
                    className='modal-item-label gateway'
                    label='IPv6 Gateway'
                    valuePropName={'checked'}
                    rules={[gatewayValidator]}
                  >
                    <WrappedSelect className='modal-item-controller' mode='multiple' onSelect={handleSelectIp}
                                   onDeselect={handleDeselectIp}>
                      {ipv6Subnets?.map(ip => <Option value={ip.id}><Checkbox
                        checked={selectedValues?.includes(ip.id)}>{ip.name}</Checkbox></Option>)}
                    </WrappedSelect>
                  </Form.Item>
                </Col>
                <Col span={24} className={'modal-col'}>
                  {selectedValues?.length ? <div className={'ant-form-item-label'}>Selected Gateways</div> : <></>}
                  {selectedValues?.map(value => {
                    const subnet = location.state ? subnets?.find(elem => elem.id === value) : unusedSubnets?.find(elem => elem.id === value);
                    return <Form.Item
                      name={`selected_gateway_${subnet?.name}`}
                      className='modal-item-label item-label-descriptor'
                      key={value}
                    >
                      {subnet?.ip_version === 'ipv4' ?
                        <Input addonBefore={selectedGatewayPrefix(subnet)} className='modal-item-controller' placeholder='x.x.x.x' /> :
                        <Input addonBefore={selectedGatewayPrefix(subnet)} className='modal-item-controller' placeholder='x:x:x:x:x:x:x' />
                      }
                    </Form.Item>
                  })}
                </Col>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='freeports'
                    className='modal-item-label'
                    label='Ports'
                    rules={[{ required: true, message: 'Ports is required' }]}
                  >
                    <WrappedSelect className='modal-item-controller' mode='multiple'
                                   onSelect={handleSelectPort}
                                   onDeselect={handleDeselectPort}>
                      {freePorts?.map(port => <Option value={port.port_id}><Checkbox
                        checked={selectedPorts?.includes(port.port_id)}>{port.port}</Checkbox></Option>)}
                    </WrappedSelect>
                  </Form.Item>
                </Col>
                <Col span={24} className={'modal-col'}>
                  {selectedPorts?.length ? <div className={'ant-form-item-label'}>Selected Ports</div> : <></>}
                  {selectedPorts?.map(value =>
                    <div className={'port-container'}>
                      <Form.Item
                        name={`selected_port_${value}`}
                        className='modal-item-label item-label-descriptor'
                      >
                        <Input 
                          addonBefore={selectedPortPrefix(freePorts?.find(elem => elem.id === value).port)} 
                          className='modal-item-controller' 
                          placeholder='2-4096' 
                        />
                      </Form.Item>
                      <div className={'port-checkbox-container'}>
                        <Form.Item
                          name={`selected_port_${value}_enabled`}
                          className='modal-item-label item-label-descriptor'
                          valuePropName={'checked'}
                        >
                          <Checkbox>Enabled</Checkbox>
                        </Form.Item>
                        <Form.Item
                          name={`selected_port_${value}_untagged`}
                          className='modal-item-label item-label-descriptor'
                          valuePropName={'checked'}
                        >
                          <Checkbox>Untagged</Checkbox>
                        </Form.Item>
                        <Form.Item
                          name={`selected_port_${value}_lag`}
                          className='modal-item-label item-label-descriptor'
                          valuePropName={'checked'}
                        >
                          <Checkbox>LAG Mode</Checkbox>
                        </Form.Item>
                      </div>
                    </div>)}
                </Col>
              </>
            )}
          </Row>
        </Form>
      }
    </BaseWizard>
  </>
}

export default CircuitWizard;
