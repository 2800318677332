import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import './modal.scss';
import { transformPortRange } from '../../utils';
import portRangeValidator from '../validators/portRangeValidator';
import portValidator from '../validators/portValidator';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNatIps } from '../../reducers/natIpsSlice';
import WrappedSelect from '../select';

const { Option } = Select;

const transformData = data => {
  const newData = { ...data };

  const [src_prefix, src_length] = newData?.source?.split?.('/') || [];
  const [dst_prefix, dst_length] = newData?.destination?.split?.('/') || [];

  const [src_port_to = 0, src_port_from = 0] = newData?.src_port_range?.split?.('-') || [];
  const [dst_port_to = 0, dst_port_from = 0] = newData?.dst_port_range?.split?.('-') || [];

  const anycast = newData.natip || '';

  if (newData.action === 'DNAT') {
    newData.dnat_dst_port_from = dst_port_from == null ? 0 : dst_port_from;
    newData.dnat_dst_port_to = dst_port_to == null ? 0 : dst_port_to;
  } else {
    newData.dnat_dst_port_from = 0;
    newData.dnat_dst_port_to = 0;
  }

  delete newData.destination;
  delete newData.natip;
  delete newData.source;
  delete newData.src_port_range;
  delete newData.dst_port_range;

  return {
    ...newData,
    src_port_to,
    src_port_from,
    src_prefix,
    src_length,
    dst_port_to,
    dst_port_from,
    dst_prefix,
    dst_length,
    anycast,
    subnet_lengh: 32,
    comment: ''
  }
}

const BaseNatModal = ({ visible, type, initialValues, loading, handleOk, onCancel }) => {
  const [action, setAction] = useState('ACCEPT_SNAT');
  const [protocol, setProtocol] = useState('accept');
  const [form] = Form.useForm();

  const { target, natIps } = useSelector(state => {
    return { target: state.clusters?.target, natIps: state.natIps }
  });

  const dispatch = useDispatch();

  const transformedInitialValues = transformPortRange(initialValues || {
    protocol: 'all',
    action: 'ACCEPT_SNAT',
    status: 'enabled',
    src_port_from: 0,
    src_port_to: 0,
    dst_port_from: 0,
    dst_port_to: 0
  });

  const handleSelectAction = value => {
    setAction(value);
  };

  const handleSelectProtocol = value => {
    if (value === 'tcp' || value === 'udp') {
      form.setFieldsValue({ src_port_range: '1-65535', dst_port_range: '1-65535' })
    } else {
      form.setFieldsValue({ src_port_range: 'all', dst_port_range: 'all' })
    }
    setProtocol(value);
  }

  useEffect(() => {
    setAction(initialValues?.action || 'ACCEPT_SNAT');
    setProtocol(initialValues?.protocol || 'all');
    form.resetFields();
  }, [form, initialValues])

  useEffect(() => {
    target && dispatch(fetchNatIps(target));
  }, [dispatch, target]);

  const { data, isLoading } = natIps;

  return (
    <Modal
      visible={visible}
      title={`${type} Rule`}
      onOk={handleOk}
      onCancel={onCancel}
      width={600}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => handleOk(transformData(form.getFieldsValue())))
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          {type === 'Add' ? 'Create' : 'Update'}
        </Button>,
        <Button
          key='cancel'
          type='secondary'
          onClick={onCancel}
        >
          Cancel
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' initialValues={transformedInitialValues} requiredMark={'optional'}>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item name='name' className='modal-item-label' label='Name' rules={[{ required: true, message: 'Name is required' }]}>
              <Input className='modal-item-controller' placeholder='Name' />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='protocol'
              className='modal-item-label'
              label='Protocol'
              rules={[{ required: true, message: 'Protocol is required' }]}
            >
              <WrappedSelect className='modal-item-controller' onChange={handleSelectProtocol}>
                <Option value='all'>ALL</Option>
                <Option value='tcp'>TCP</Option>
                <Option value='udp'>UDP</Option>
                <Option value='icmp'>ICMP</Option>
              </WrappedSelect>
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='action'
              className='modal-item-label'
              label='Action'
              rules={[{ required: true, message: 'Action is required' }]}
            >
              <WrappedSelect className='modal-item-controller' onChange={handleSelectAction}>
                <Option value='DNAT'>DNAT</Option>
                <Option value='SNAT'>SNAT</Option>
                <Option value='ACCEPT_SNAT'>ACCEPT</Option>
              </WrappedSelect>
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col span={12} style={{
            marginBottom: 10,
          }}>
            <span className='static-text'>SOURCE</span>
          </Col>
          <Col span={12} style={{
            marginBottom: 10,
          }}>
            <span className='static-text'>DESTINATION</span>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='source'
              className='modal-item-label'
              label='IP Address'
              rules={[{ required: true, message: 'Source IP is required' }]}
            >
              <Input className='modal-item-controller' placeholder='0.0.0.0/0' />
            </Form.Item>
          </Col>
          {action === 'DNAT' ?
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='destination'
                className='modal-item-label'
                label='IP Address'
                rules={[{ required: true, message: 'Destination IP is required' }]}
                disabled={isLoading}
              >
                <WrappedSelect className='modal-item-controller' loading={isLoading} disabled={isLoading}>
                  {data.map(natIp =>
                    <Option key={`${natIp.name}-${natIp.ip_address}`} value={`${natIp.ip_address}/${natIp.subnet_length}`}>
                      {`${natIp.ip_address}/${natIp.subnet_length} (${natIp.name})`}
                    </Option>
                  )}
                </WrappedSelect>
              </Form.Item>
            </Col> :
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='destination'
                className='modal-item-label'
                label='IP Address'
                rules={[{ required: true, message: 'Destination IP is required' }]}
              >
                <Input className='modal-item-controller' placeholder='0.0.0.0/0' />
              </Form.Item>
            </Col>
          }
          {protocol !== 'icmp' && protocol !== 'all' && <Col span={12} className={'modal-col'}>
            <Form.Item
              name='src_port_range'
              className='modal-item-label'
              label='Port Range'
              rules={[{ required: true, message: 'Source Port Range is required' }, portRangeValidator]}
            >
              <Input className='modal-item-controller' placeholder='1' />
            </Form.Item>
          </Col>
          }
          {protocol !== 'icmp' && protocol !== 'all' &&
          <>
            {action !== 'DNAT' ? (
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='dst_port_range'
                  className='modal-item-label'
                  label='Port Range'
                  rules={[{ required: true, message: 'Destination Port Range is required' }, portRangeValidator]}
                >
                  <Input className='modal-item-controller' placeholder='1' />
                </Form.Item>
              </Col>
            ) : (
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='dst_port'
                  className='modal-item-label'
                  label='Port'
                  rules={[{ required: true, message: 'Port is required', portValidator }]}
                >
                  <Input className='modal-item-controller' placeholder='Port' />
                </Form.Item>
              </Col>
            )}
          </>
          }
        </Row>
        <hr />
        <Row gutter={24}>
          {action === 'SNAT' && (
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='natip'
                className='modal-item-label'
                label='NAT IP'
                rules={[{ required: true, message: 'NAT IP is required' }]}
              >
                <WrappedSelect className='modal-item-controller' loading={isLoading} disabled={isLoading}>
                  {data.map(natIp =>
                    <Option key={`${natIp.name}-${natIp.ip_address}`} value={`${natIp.ip_address}/${natIp.subnet_length}`}>
                      {`${natIp.ip_address}/${natIp.subnet_length} (${natIp.name})`}
                    </Option>
                  )}
                </WrappedSelect>
              </Form.Item>
            </Col>
          )}
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='status'
              className='modal-item-label'
              label='Status'
              rules={[{ required: true, message: 'Status is required' }]}
            >
              <WrappedSelect className='modal-item-controller'>
                <Option value='enabled'>Enabled</Option>
                <Option value='disabled'>Disabled</Option>
              </WrappedSelect>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BaseNatModal;
