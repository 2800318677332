import React from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import './modal.scss';
import ipValidator from '../validators/ipValidator';

const transformToFormValues = ({
  name,
  prefix,
  length,
}) => ({
  name,
  cidr: `${prefix}/${length}`,
})

const BaseSubnetsModal = ({ visible, loading, handleCreate, onCancel, current }) => {
  const [form] = Form.useForm();

  const initialValues = current ? transformToFormValues(current) : {};

  return (
    <Modal
      visible={visible}
      title={current ? 'Update Subnet' : 'Add Subnet'}
      onOk={handleCreate}
      onCancel={onCancel}
      width={600}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => handleCreate(form))
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          {current ? 'Update' : 'Create' }
        </Button>,
        <Button
          key='cancel'
          type='secondary'
          onClick={onCancel}
        >
          Cancel
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' initialValues={initialValues} requiredMark={'optional'}>
        <Row gutter={24}>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input className='modal-item-controller' placeholder='Name' />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='cidr'
              className='modal-item-label'
              label='Prefix'
              rules={[{ required: true, message: 'Prefix is required' }, ipValidator]}
              disabled={current}
            >
              <Input className='modal-item-controller' placeholder='0.0.0.0' disabled={current}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BaseSubnetsModal;
