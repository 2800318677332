import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Button from 'antd/lib/button';
import { useForm } from 'antd/es/form/Form';
import Form from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';

import AddCredentialModal from '../../../components/modals/addCredentialModal';
import { PROVIDERS } from '../../../components/constants';
import { fetchClusters } from '../../../reducers/clusterSlice';
import notification from '../../../utils/notification';
import { transformToCredential } from '../../../utils';
import { addCredential } from '../../../api';

import '../index.scss';

const { AWS, AZURE, GOOGLE, MEGAPORT, EQUINIX, PCCW, } = PROVIDERS;

const OnboardingCredentials = () => {
  const [loading, setLoading] = useState(false);
  const [visibleCloud, setVisibleCloud] = useState(false);
  const [visibleProvider, setVisibleProvider] = useState(false);

  const [createdCloud, setCreatedCloud] = useState(undefined);
  const [createdProvider, setCreatedProvider] = useState(undefined);

  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = useForm();

  const target = useSelector(state => state.clusters?.target);

  useEffect(() => {
    dispatch(fetchClusters);
  }, [dispatch]);

  const onClose = () => {
    setVisibleCloud(false);
    setVisibleProvider(false);
  }

  const onCreateCloud = (form) => {
    const data = form.getFieldsValue();
    setLoading(true);
    const transformedData = transformToCredential(data);
    target && addCredential(target, transformedData.type, transformedData).then(() => {
      setLoading(false);
      setCreatedCloud(true);
      form.setFieldsValue({
        cloudCreated: true
      });
      onClose();
      notification.success({
        message: 'Added new provider credentials.'
      });
    }).catch(() => {
      setLoading(false);
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    });
  }

  const onCreateProvider = (form) => {
    const data = form.getFieldsValue();
    setLoading(true);
    const transformedData = transformToCredential(data);
    target && addCredential(target, transformedData.type, transformedData).then(() => {
      setLoading(false);
      setCreatedProvider(true);
      form.setFieldsValue({
        providerCreated: true
      });
      onClose();
      notification.success({
        message: 'Added new provider credentials.'
      });
    }).catch(() => {
      setLoading(false);
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    });
  }

  const handleClick = () => {
    form.setFieldsValue({
      cloudCreated: createdCloud,
      providerCreated: createdProvider
    });
    return form.validateFields().then(() => {
      history.replace('/overview');
    }).catch(() => undefined);
  }

  const initialValues = {
    cloudCreated: undefined,
    providerCreated: undefined
  };

  return <div className='onboarding-credentials-container'>
    <Form form={form} initialValues={initialValues} layout='vertical' requiredMark={'optional'}>
      <Form.Item
        name='cloudCreated'
        className='modal-item-label'
        label='Cloud Credential'
        rules={[{ required: true, message: 'Cloud Credential is required' }]}
      >
        <div className='info-area'>
          <div className={'info-box'} />
          <Button type='primary' onClick={() => {
            setVisibleCloud(true);
          }} disabled={createdCloud}>{'Add Cloud Credential'}</Button>
        </div>
      </Form.Item>
      <Form.Item
        name='providerCreated'
        className='modal-item-label'
        label='Provider Credential'
        rules={[{ required: true, message: 'Provider Credential is required' }]}
      >
        <div className='info-area'>
          <div className={'info-box'} />
          <Button type='primary' onClick={() => {
            setVisibleProvider(true);
          }} disabled={createdProvider}>{'Add Provider Credential'}</Button>
        </div>
      </Form.Item>
      <Button type='primary' onClick={handleClick} className='wizard-btn' disabled={!createdCloud || !createdProvider}>{'Done'}</Button>
    </Form>
    <AddCredentialModal
      loading={loading}
      visible={visibleCloud}
      filter={[AWS, GOOGLE, AZURE]}
      handleCreate={onCreateCloud}
      onCancel={onClose}
    />
    <AddCredentialModal
      loading={loading}
      visible={visibleProvider}
      filter={[PCCW, MEGAPORT, EQUINIX]}
      handleCreate={onCreateProvider}
      onCancel={onClose}
    />
  </div>;
}

export default OnboardingCredentials;