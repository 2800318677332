import React, { useState, useMemo, useEffect } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Form from 'antd/lib/form';
import useForm from 'antd/lib/form/hooks/useForm';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import Spin from 'antd/lib/spin';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import Button from 'antd/lib/button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCloudPrefill } from '../../../reducers/cloudPrefillSlice';
import { postCloudconnectTemplate, updateCloudconnectTemplate, addCredential } from '../../../api';
import AddCredentialModal from '../../modals/addCredentialModal';
import { useHistory, useParams } from 'react-router';
import notification from '../../../utils/notification';
import { fetchCloudCredentials } from '../../../reducers/cloudCredentialSlice';
import BaseWizard from '../baseWizard';
import { PROVIDERS } from '../../constants';
import { mergeObjects, transformToCredential } from '../../../utils';
import '../index.scss';
import './addCloudTemplate.scss';

import { AzureLogo, GoogleLogo, AWSLogo, PCCWLogo, MegaportLogo } from '../../logo';

import WrappedSelect from '../../select';

const { Option } = Select;
const { AWS, GOOGLE, AZURE, PCCW, MEGAPORT, EQUINIX } = PROVIDERS;

const transformTemplate = data => ({
  type: data.type,
  provider: data.provider,
  description: data.description,
  location: data.location,
  name: data.name,
  srcPortId: data.pccw?.srcPortId,
  destPortId: data.pccw?.destPortId,
  ebgpOffloader: data.netris?.terminate_on_switch === 'yes' ? 'none-offload-port-id' : data.netris?.nfv_port_id,
  switchPort: `${data.netris?.switch_port_id}:${data.netris?.term_switch_id}`,
  profile: data.profiles?.[data.type],
  providerProfile: data.profiles?.[data.provider],
  azureServiceProviderName: data.azure?.serviceProviderName,
  azurePeeringLocation: data.azure?.peeringLocation
});

const AddCloudTemplateWizard = () => {

  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedProviderPort, setSelectedProviderPort] = useState(undefined);
  const { cloudPrefill, cloudCredential, cloudTemplate, target } = useSelector((state) => ({
    cloudPrefill: state.cloudPrefill,
    cloudCredential: state.cloudCredential,
    cloudTemplate: state.cloudTemplate,
    target: state.clusters?.target
  }));

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [visibleConnectModal, setVisibleConnectModal] = useState(false);

  const { data = {}, isLoading: isPrefillLoading, error: prefillError } = cloudPrefill;
  const { data: cloudCredentialData } = cloudCredential;
  const { data: cloudTemplateData } = cloudTemplate;
  const [form] = useForm();

  const initialValues = params?.wizardId ?
    transformTemplate(cloudTemplateData?.find(template => String(template._id) === String(params.wizardId)) || {}) :
    {
      type: AWS,
      provider: PCCW
    };

  const [activeCloud, setActiveCloud] = useState(initialValues.type || AWS);
  const [activeConnect, setActiveConnect] = useState(initialValues.provider || PCCW);

  useEffect(() => {
    const template = cloudTemplateData?.find(template => String(template._id) === String(params?.wizardId));
    template && setActiveCloud(template.type);
  }, [cloudTemplateData, params?.wizardId]);

  const wizardSteps = useMemo(() => [
    {
      title: 'Select Cloud provider and profile',
      name: 'Cloud Profile',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
    {
      title: 'Select Connect provider and profile',
      name: 'Connect Profile',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
    {
      title: 'Fill in cloud details',
      name: 'Cloud Details',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
      error: !!prefillError,
      preAction: async () => {
        dispatch(fetchCloudPrefill(activeCloud, activeConnect, target, form.getFieldValue('profile'), form.getFieldValue('providerProfile')));
      }
    },
  ], [form, dispatch, activeCloud, target, prefillError, activeConnect]);

  useEffect(() => {
    target && form.getFieldValue('type') && form.getFieldValue('provider') && form.getFieldValue('profile') && form.getFieldValue('providerProfile') && dispatch(fetchCloudPrefill(form.getFieldValue('type'), form.getFieldValue('provider'), target, form.getFieldValue('profile'), form.getFieldValue('providerProfile')));
    dispatch(fetchCloudCredentials(target));
  }, [dispatch, form, target]);

  const onCancelWizard = () => {
    history.push('/settings/cloud-templates');
  }

  const handleClickCloud = (val) => () => {
    form.resetFields();
    form.setFieldsValue({ type: val, profile: undefined });
    setActiveCloud(val);
  };

  const handleClickConnect = (val) => () => {
    form.resetFields();
    form.setFieldsValue({ provider: val, type: activeCloud, providerProfile: undefined });
    setActiveConnect(val);
  };

  const handleNoProfileClick = (evt) => {
    evt.preventDefault();
    setVisible(true);
    setVisibleConnectModal(false);
  }

  const handleNoConnectProfileClick = (evt) => {
    evt.preventDefault();
    setVisible(false);
    setVisibleConnectModal(true);
  }

  const onCloseProfile = () => {
    setVisible(false);
    setVisibleConnectModal(false);
  }

  const onCreateProfile = async (form) => {
    const data = form.getFieldsValue();
    setIsProfileLoading(true);
    const transformedData = transformToCredential(data);
    return addCredential(target, String(data.type).toLowerCase(), transformedData).then(() => {
      notification.success({
        message: 'New Profile added.'
      });
      setIsProfileLoading(false);
      setVisible(false);
      setVisibleConnectModal(false);
      dispatch(fetchCloudCredentials(target));
    }).catch(() => {
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
      setIsProfileLoading(false);
      return Promise.reject();
    });
  };

  const hasProfiles = Object.keys(cloudCredentialData || {}).length > 0;

  const onComplete = async () => {
    // get form data and send
    setLoading(true);
    const formData = form.getFieldsValue();
    let combinedData = mergeObjects(currentData, formData);
    const [switchPortId, termSwitchPortId] = String(combinedData.switchPort).split(':').map(elem => Number.parseInt(elem)).filter((elem) => !Number.isNaN(elem));

    let transformedData = {
      name: combinedData.name,
      description: combinedData.description,
      type: combinedData.type,
      provider: combinedData.provider,
      location: combinedData.location,
      pccw: {
        srcPortId: combinedData.srcPortId,
        destPortId: combinedData.destPortId
      },
      netris: {
        ...(combinedData.ebgpOffloader !== 'none-offload-port-id' && combinedData.ebgpOffloader != null ? {
          nfv_port_id: combinedData.ebgpOffloader,
          terminate_on_switch: undefined
        } : {
          terminate_on_switch: 'yes',
        }),
        switch_port_id: switchPortId,
        term_switch_id: termSwitchPortId
      },
      profiles: {
        [combinedData.type]: combinedData.profile,
        [combinedData.provider]: combinedData.providerProfile
      }
    }

    if (combinedData.type === AZURE) {
      transformedData = {
        ...transformedData,
        azure: {
          serviceProviderName: combinedData.azureServiceProviderName,
          peeringLocation: combinedData.azurePeeringLocation
        }
      }
    }

    params.wizardId ? updateCloudconnectTemplate(target, params.wizardId, transformedData).then(() => {
      notification.success({
        message: 'Updated Cloud Template.'
      });
      setLoading(false);
      setCurrentData({});
      history.push('/settings/cloud-templates');
    }).catch(() => {
      setLoading(false);
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    }) : postCloudconnectTemplate(target, transformedData).then(() => {
      notification.success({
        message: 'New Cloud Template added.'
      });
      setLoading(false);
      setCurrentData({});
      history.push('/settings/cloud-templates');
    }).catch(() => {
      setLoading(false);
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    });
  }

  const onStepChange = () => {
    // We do this so we can expand on the field values since form resets the field value for each step
    setCurrentData(mergeObjects(currentData, form.getFieldsValue()));
  }

  const handleSelectProviderPort = (value) => {
    setSelectedProviderPort(value);
  }

  return <>
    <BaseWizard
      completeText={params?.wizardId ? 'Update' : 'Create'}
      name={`${params?.wizardId ? 'Update' : 'Create'} Cloud Template`}
      stepName={'Cloud Template'}
      onComplete={onComplete}
      onCancelWizard={onCancelWizard}
      steps={wizardSteps}
      loading={loading}
      onStepChange={onStepChange}
    >
      {(currentStep, setCurrentStep) =>
        <Form form={form} layout='vertical' initialValues={initialValues} requiredMark={'optional'}>
          <Row gutter={24}>
            {currentStep === 0 &&
            <>
              <Col span={24} className={'wizard-col'}>
                <Form.Item
                  name='type'
                  className='modal-item-label'
                  label='Choose Cloud'
                  rules={[{ required: true, message: 'Cloud is required' }]}
                >
                  <Row justify='start' align='middle'>
                    < Col
                      className={`cloud-area ${activeCloud === AWS && 'active'}`}
                      onClick={handleClickCloud(AWS)}
                    >
                      <div className={'provider-logo-container'}><AWSLogo />Amazon Web Services</div>
                      {activeCloud === AWS &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                    <Col
                      className={`cloud-area ${activeCloud === GOOGLE && 'active'}`}
                      onClick={handleClickCloud(GOOGLE)}
                    >
                      <div className={'provider-logo-container'}><GoogleLogo />Google Cloud</div>
                      {activeCloud === GOOGLE &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                    <Col
                      className={`cloud-area ${activeCloud === AZURE && 'active'}`}
                      onClick={handleClickCloud(AZURE)}
                    >
                      <div className={'provider-logo-container'}><AzureLogo />Microsoft Azure</div>
                      {activeCloud === AZURE &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={24} className={'wizard-col'}>
                <Form.Item
                  name='profile'
                  className='modal-item-label profile-item'
                  label={
                    <>
                      <span className='profile-span'>Profile</span>
                      <PlusOutlined onClick={handleNoProfileClick} />
                    </>}
                  rules={[{ required: true, message: 'Profile is required' }]}
                  disabled={!hasProfiles}
                >
                  <WrappedSelect className='modal-item-controller' disabled={!hasProfiles}>
                    {cloudCredentialData[activeCloud] &&
                    cloudCredentialData[activeCloud].map((profile) => (
                      <Option key={profile._id} value={profile._id}>
                        {profile.name}
                      </Option>
                    ))}
                  </WrappedSelect>
                </Form.Item>
              </Col>
            </>
            }
            {currentStep === 1 && (
              <>
                <Col span={24} className={'wizard-col'}>
                  <Form.Item
                    name='provider'
                    className='modal-item-label'
                    label='Choose Connect'
                    rules={[{ required: true, message: 'Connect is required' }]}
                  >
                    <Row justify='start' align='middle'>
                      < Col
                        className={`cloud-area ${activeConnect === PCCW && 'active'}`}
                        onClick={handleClickConnect(PCCW)}
                      >
                        <div className={'provider-logo-container'}><PCCWLogo />PCCW</div>
                        {activeConnect === PCCW &&
                        <CheckCircleFilled className="cloud-area-check" />}
                      </Col>
                      < Col
                        className={`cloud-area ${activeConnect === MEGAPORT && 'active'}`}
                        onClick={handleClickConnect(MEGAPORT)}
                      >
                        <div className={'provider-logo-container'}><MegaportLogo />Megaport</div>
                        {activeConnect === MEGAPORT &&
                        <CheckCircleFilled className="cloud-area-check" />}
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={24} className={'wizard-col'}>
                  <Form.Item
                    name='providerProfile'
                    className='modal-item-label profile-item'
                    label={
                      <>
                        <span className='profile-span'>Profile</span>
                        <PlusOutlined onClick={handleNoConnectProfileClick} />
                      </>}
                    rules={[{ required: true, message: 'Profile is required' }]}
                    disabled={!hasProfiles}
                  >
                    <WrappedSelect className='modal-item-controller' disabled={!hasProfiles}>
                      {cloudCredentialData[activeConnect] &&
                      cloudCredentialData[activeConnect].map((profile) => (
                        <Option key={profile._id} value={profile._id}>
                          {profile.name}
                        </Option>
                      ))}
                    </WrappedSelect>
                  </Form.Item>
                </Col>
              </>
            )}
            {currentStep === 2 && (
              isPrefillLoading ? <Spin indicator={<LoadingOutlined spin style={{
                  fontSize: 100,
                  marginLeft: -50
                }} />} spinning={isPrefillLoading} /> :
                prefillError ?
                  <Col span={24}>
                    <div className='prefill-error-container'>
                      <div className='message-container'>
                        <ExclamationCircleOutlined />
                        There seems to be something amiss. Please check your credentials or try again later.
                      </div>
                      <Button className='error-back-btn' type='primary' onClick={() => setCurrentStep(currentStep - 1)}>Go
                        to previous step</Button>
                    </div>
                  </Col> :
                  <>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='name'
                        className='modal-item-label'
                        label='Name'
                        rules={[{ required: true, message: 'Name is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <Input className='modal-item-controller' placeholder='Name'
                               disabled={isPrefillLoading} />
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='location'
                        className='modal-item-label'
                        label='Location'
                        rules={[{ required: true, message: 'Location is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <Input className='modal-item-controller' placeholder='Location'
                               disabled={isPrefillLoading} />
                      </Form.Item>
                    </Col>
                    <Col span={24} className={'wizard-col'}>
                      <Form.Item
                        name='description'
                        className='modal-item-label'
                        label='Description'
                        rules={[{ required: true, message: 'Description is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <Input className='modal-item-controller' placeholder='Description'
                               disabled={isPrefillLoading} />
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='switchPort'
                        className='modal-item-label'
                        label='Switch Port'
                        rules={[{ required: true, message: 'Switch Port is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}>
                          {data[activeCloud] &&
                          data[activeCloud].switchPorts?.map((switchPort) => (
                            <Option key={switchPort.id} value={`${switchPort.id}:${switchPort.switch_id}`}>
                              {switchPort.name}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='ebgpOffloader'
                        className='modal-item-label'
                        label='EBGP Offloader'
                        disabled={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}
                                       defaultValue={'none-offload-port-id'}>
                          {data[activeCloud] &&
                          [{
                            offload_port_id: 'none-offload-port-id',
                            location: 'None'
                          }].concat(data[activeCloud]?.ebgpOffloaders || []).map((ebgpOffloader) => (
                            <Option key={ebgpOffloader.offload_port_id} value={ebgpOffloader.offload_port_id}>
                              {ebgpOffloader.location}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='srcPortId'
                        className='modal-item-label'
                        label='OnPrem Port'
                        rules={[{ required: true, message: 'OnPrem Port is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}>
                          {data[activeCloud] &&
                          data[activeCloud].onpremPorts?.map((onpremPort) => (
                            <Option key={onpremPort.id} value={onpremPort.id}>
                              {onpremPort.name}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    {activeCloud !== AZURE && <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='destPortId'
                        className='modal-item-label'
                        label='Cloud Port'
                        rules={[{ required: true, message: 'Cloud Port is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}>
                          {data[activeCloud] &&
                          data[activeCloud].cloudPorts?.map((cloudPort) => (
                            <Option key={cloudPort.id} value={cloudPort.id}>
                              {cloudPort.name}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>}
                    {activeCloud === AZURE && <>
                      <Col span={12} className={'wizard-col'}>
                        <Form.Item
                          name='azureServiceProviderName'
                          className='modal-item-label'
                          label='Provider Port'
                          disabled={isPrefillLoading}
                          rules={[{ required: true, message: 'Provider Port is required' }]}
                        >
                          <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}
                                         onChange={handleSelectProviderPort}>
                            {data[activeCloud] &&
                            (data[activeCloud]?.azureProviderPorts || []).map((providerPort) => (
                              <Option key={providerPort.name} value={providerPort.name}>
                                {providerPort.name}
                              </Option>
                            ))}
                          </WrappedSelect>
                        </Form.Item>
                      </Col>
                      <Col span={12} className={'wizard-col'}>
                        <Form.Item
                          name='azurePeeringLocation'
                          className='modal-item-label'
                          label='Peering Location'
                          disabled={isPrefillLoading}
                          rules={[{ required: true, message: 'Peering Location is required' }]}
                        >
                          <WrappedSelect className='modal-item-controller'
                                         disabled={isPrefillLoading || !(selectedProviderPort || form.getFieldValue('azureServiceProviderName'))}>
                            {data[activeCloud] &&
                            (data[activeCloud]?.azureProviderPorts?.find?.(port => port.name === selectedProviderPort || port.name === form.getFieldValue('azureServiceProviderName'))?.peeringLocations || []).map((peeringLocation) => (
                              <Option key={peeringLocation} value={peeringLocation}>
                                {peeringLocation}
                              </Option>
                            ))}
                          </WrappedSelect>
                        </Form.Item>
                      </Col>
                    </>}
                  </>
            )}
          </Row>
        </Form>
      }
    </BaseWizard>
    <AddCredentialModal
      loading={isProfileLoading}
      visible={visible}
      activeCloud={activeCloud}
      filter={[AWS, GOOGLE, AZURE]}
      handleCreate={onCreateProfile}
      onCancel={onCloseProfile}
    />
    <AddCredentialModal
      loading={isProfileLoading}
      visible={visibleConnectModal}
      activeCloud={activeConnect}
      filter={[PCCW, MEGAPORT, EQUINIX]}
      handleCreate={onCreateProfile}
      onCancel={onCloseProfile}
    />
  </>
}

export default AddCloudTemplateWizard;
