import React, { useEffect } from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import './modal.scss';

const EditProfileModal = ({ visible, data, loading, onCancel, handleUpdate }) => {
  const [form] = Form.useForm();
  const netAppUrl = 'http://cloud.netapp.com/user_metadata';
  const initialValues = {
    name: data ? data?.name : '',
    nickname: data ? data?.nickname: '',
    email: data ? data?.email : '',
    phone: data ? data[netAppUrl]?.phone : '',
    company: data ? data[netAppUrl]?.company : ''
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue('name', data.name);
      form.setFieldsValue('nickname', data.nickname);
      form.setFieldsValue('email', data.email);
      form.setFieldsValue('phone', data[netAppUrl]?.phone);
      form.setFieldsValue('company', data[netAppUrl]?.company);
    }
  }, [form, data])

  const handleSubmit = (form) => {
    handleUpdate(form);
  }

  return (
    <Modal
      visible={visible}
      title='Edit Profile'
      onOk={handleSubmit}
      onCancel={onCancel}
      width={640}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                handleSubmit(form);
                form.resetFields();
              })
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          Update
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' initialValues={initialValues} requiredMark={'optional'}>
        <Row gutter={24}>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='nickname'
              className='modal-item-label'
              label='Nickname'
              rules={[{ required: true, message: 'Nickname is required' }]}
            >
            <Input className='modal-item-controller' placeholder='Nickname' />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              rules={[{ required: true, message: 'Name is required' }]}
            >
            <Input className='modal-item-controller' placeholder='Name' />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='email'
              className='modal-item-label'
              label='Email'
              rules={[{ required: true, message: 'Email is required' }]}
            >
            <Input className='modal-item-controller' type="email" placeholder='Email' />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='phone'
              className='modal-item-label'
              label='Phone'
              rules={[{ required: true, message: 'Phone number is required' }]}
            >
            <Input className='modal-item-controller' placeholder='Phone' />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='company'
              className='modal-item-label'
              label='Company'
              rules={[{ required: true, message: 'Company is required' }]}
            >
            <Input className='modal-item-controller' placeholder='Company' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditProfileModal;
