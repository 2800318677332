import React from 'react';
import { prepareCloseWizard, setWizardPreValidationStep } from '../../reducers/wizardSlice';
import { useDispatch } from 'react-redux';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import { ReactComponent as WizardCloseIcon } from '../../assets/wizard_close_icon.svg';

const WizardMenu = ({ steps = [], activeStep }) => {
  const dispatch = useDispatch();

  const handleWizardStepChange = (idx) => () => {
    dispatch(setWizardPreValidationStep({
      step: idx
    }));
  }

  const handleCloseWizard = () => {
    dispatch(prepareCloseWizard());
  }

  return <>
    <div className="navigation-wizard-steps">
      {steps.filter(step => !step.visible || step.visible !== 'no').map((step, idx) =>
        <div
          key={step.name}
          className={`navigation-wizard-step ${activeStep === idx ? 'active' : ''}${activeStep > idx ? 'finished' : ''}`}
          onClick={handleWizardStepChange(idx)}
        >
        <div className='navigation-wizard-step-number'>
          {activeStep > idx ? <CheckOutlined/> : <span >{idx + 1}</span>}
          </div>
          {step.name}
        </div>)}
    </div>
    <div className="navigation-wizard-close" onClick={handleCloseWizard}><WizardCloseIcon /></div>
  </>;
}

export default WizardMenu;