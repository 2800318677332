import React, { useEffect, useState } from 'react';
import BaseTable from '../../components/table';
import { Button, Col, Row } from 'antd';
import ConfirmModal from '../../components/modals/confirmModal';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import InfoModal from '../../components/modals/infoModal';
import { deleteNodeFromMetalCluster, deprovisionNodeFromMetalCluster } from '../../api';
import { useHistory } from 'react-router';
import notification from '../../utils/notification';
import { useSelector, useDispatch } from 'react-redux';
import { fetchClusters } from '../../reducers/clusterSlice';
import ShowStatus from '../../components/showstatus';

const MetalNodes = ({ clusterDetails }) => {
  const { isLoading } = useSelector((state) => state.clusters);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSshModalVisible, setIsSshModalVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState('');
  const [currentCluster, setCurrentCluster] = useState('')
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentCluster(clusterDetails?.uuid);
  }, [clusterDetails])

  const columns = [
    {
      title: 'Hostname',
      dataIndex: 'hostname',
      width: 250
    },
    {
      title: 'Role',
      width: 120,
      dataIndex: 'role',
      render: (_, record) => {
        return record.role === 'AGENT' ? 'WORKER' : record.role;
      }
    },
    {
      title: 'OS Version',
      width: 150,
      dataIndex: 'osVersion',
    },
    {
      title: 'Kernel Version',
      width: 150,
      dataIndex: 'kernelVersion',
    },
    {
      title: 'Kube Version',
      width: 150,
      dataIndex: 'kubeVersion',
    },
    {
      title: 'Connection State',
      width: 180,
      dataIndex: 'connectionState',
      render: (_, record) => {
        return <ShowStatus status={record?.connectionState || undefined} />;
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 100,
      className: 'action-column',
      render: (_, record, index) => {
        return <Button className='node-btn' onClick={(e) => showConfirmModal(record)}>Drain</Button>;
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 100,
      className: 'action-column',
      render: (_, record, index) => {
        return <Button className='node-btn' onClick={(e) => showSshModal(record)}>SSH</Button>;
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 100,
      className: 'action-column',
      render: (_, record, index) => {
        return <Button className='node-btn' onClick={(e) => showDeleteModal(record)}>Delete</Button>;
      }
    }
  ];

  const handleDrainNode = async () => {
    try {
      await deprovisionNodeFromMetalCluster(selectedNode?.clusterUuid, selectedNode?.hostname);
      dispatch(fetchClusters);
      setIsConfirmModalVisible(false);
    } catch (err) {
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    }
  }

  const handleDeleteNode = async () => {
    try {
      await deleteNodeFromMetalCluster(selectedNode?.clusterUuid, selectedNode.uuid, selectedNode?.hostname);
      dispatch(fetchClusters);
      setIsDeleteModalVisible(false);
    } catch (err) {
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    }
  }

  const onCancelConfirm = () => {
    setIsConfirmModalVisible(false);
  }

  const showConfirmModal = (record) => {
    setSelectedNode(record);
    setIsConfirmModalVisible(true);
  }

  const onCancelDelete = () => {
    setIsDeleteModalVisible(false);
  }

  const showDeleteModal = (record) => {
    setSelectedNode(record);
    setIsDeleteModalVisible(true);
  }

  const onCancelSsh = () => {
    setIsSshModalVisible(false);
  }

  const showSshModal = (record) => {
    setSelectedNode(record);
    setIsSshModalVisible(true);
  }

  const openWizard = () => {
    history.push(`/cluster/${currentCluster}/nodes/`)
  };

  return (
    <div className='node-container'>
      <div className='header'>
        <div className='row'>
          <div className='node-header-description'>
            <span className='item-count'>{clusterDetails?.nodes?.length || 0}</span> Nodes
          </div>
        </div>
      </div>
      <div className='node-list'>
        <Row>
          <BaseTable
            columns={columns}
            data={clusterDetails?.nodes || []}
            className='node-table'
            pageSize={5}
            isLoading={isLoading}
            emptyRenderText='No Nodes available'
          />
        </Row>
        <Row justify='space-between' className='vm-footer'>
          <Col>
            <Button
              className='action-btn'
              onClick={(e) => openWizard()}
            >
              Add Nodes
            </Button>
          </Col>
        </Row>        
      </div>
      <DeleteItemModal
        title={selectedNode?.hostname || 'Node'}
        visible={isDeleteModalVisible}
        loading={isLoading}
        onCancel={onCancelDelete}
        onDelete={handleDeleteNode}
      />
      <ConfirmModal
        title={`draining node ${selectedNode?.hostname}` || 'draining node'}
        visible={isConfirmModalVisible}
        loading={isLoading}
        onCancel={onCancelConfirm}
        onConfirm={handleDrainNode}
        message={'This process can take up to 15 minutes.'}
      />
      <InfoModal
        title={`SSH to ${selectedNode?.hostname}` || 'SSH'}
        visible={isSshModalVisible}
        onClose={onCancelSsh}
        message={`ssh ${selectedNode?.uuid}@ssh.fluidhq.io`}
      />
    </div>
  );
};

export default MetalNodes;
