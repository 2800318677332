import { createSlice } from '@reduxjs/toolkit';
import { getNatIps } from '../api';

const natIpsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const natIps = createSlice({
  name: 'natIps',
  initialState: natIpsInitialState,
  reducers: {
    getNatIpsStart(state) {
      state.isLoading = true;
    },
    getNatIpsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((natIp) => {
          natIp['key'] = `${natIp.name}-${natIp.ip_address}`;
          return natIp;
        });
      } else {
        data.slice = [];
      }
    },
    getNatIpsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getNatIpsStart, getNatIpsSuccess, getNatIpsFailure } = natIps.actions;

export default natIps.reducer;

export const fetchNatIps = target => async (dispatch) => {
  try {
    dispatch(getNatIpsStart());
    const natIps = await getNatIps(target);
    dispatch(getNatIpsSuccess(natIps));
  } catch (err) {
    dispatch(getNatIpsFailure(err.toString()));
  }
};
