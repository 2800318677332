import React, { useState } from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import nameValidator from '../validators/nameValidator'

import './modal.scss';

const { Option } = Select;

const ClusterFileUploadModal = ({ target, visible, loading, kubernetes, onCancel, handleIsoUpload, handleHttpUpload }) => {
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedUploadMethod, setSelectedUploadMethod] = useState('file');

  const handleSubmit = (form) => {
    const formData = new FormData();
    formData.append("name", form.getFieldValue('name'));
    const kubernetes = form.getFieldValue('kubernetes');
    formData.append("kubernetes", kubernetes);
    formData.append("file", selectedFile);
    if (selectedUploadMethod === 'file') {
      handleIsoUpload(formData, kubernetes);
    } else if (selectedUploadMethod === 'http') {
      handleHttpUpload(form);
    }
  }

  const handleUploadChange = (value) => {
    form.setFieldsValue({ uploadMethod: value });
    setSelectedUploadMethod(value);
  }

  return (
    <Modal
      visible={visible}
      title='Upload Volume'
      onOk={handleSubmit}
      onCancel={onCancel}
      width={640}
      confirmLoading={loading}
      footer={[
        <div className='footer-text'>{loading ? <p>Uploading volume. This may take some time...</p> : <></>}</div>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                handleSubmit(form);
              })
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          Upload
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' requiredMark={'optional'}>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              validateTrigger='onBlur'
              hasFeedback
              rules={[{ required: true, message: 'Name is required' }, nameValidator(target)]}
            >
              <Input className='modal-item-controller' placeholder='Name' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='kubernetes'
              className='modal-item-label'
              label='Kubernetes'
              rules={[{ required: true, message: 'Namespace is required' }]}
            >
              <Select placeholder="Kubernetes" className='modal-item-controller' >
                {kubernetes && kubernetes.length > 0 && <>
                  {kubernetes.map(el => (
                    <Option key={el.clusterId} value={el.clusterId}>{el.displayName}</Option>
                  ))}
                </>}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='uploadMethod'
              className='modal-item-label'
              label='Upload Method'
              rules={[{ required: true, message: 'Upload method required' }]}
            >
              <Select placeholder='Select upload method' className='modal-item-controller' onChange={handleUploadChange}>
                <Option key='file' value='file'>File</Option>
                <Option key='http' value='http'>HTTP</Option>
              </Select>
            </Form.Item>
          </Col>
          {selectedUploadMethod === 'file' &&
            <>
              <Col span={12}>
                <Form.Item
                  name='file1'
                  className='modal-item-label'
                  label='File'
                  rules={[{ required: true, message: 'File is required' }]}
                >
                  <input type="file" name="file1" onChange={(e) => setSelectedFile(e.target.files[0])} />
                </Form.Item>
              </Col>
            </>
          }
          {selectedUploadMethod === 'http' &&
            <>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='url'
                  className='modal-item-label'
                  label='URL'
                  rules={[{ required: true, message: 'URL is required' }]}
                >
                  <Input className='modal-item-controller' placeholder='URL' />
                </Form.Item>
              </Col>
            </>
          }
        </Row>
      </Form>
    </Modal>
  );
};

export default ClusterFileUploadModal;
