import { createSlice } from '@reduxjs/toolkit';
import { getAcls } from '../api';

const aclsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const acls = createSlice({
  name: 'acls',
  initialState: aclsInitialState,
  reducers: {
    getAclsStart(state) {
      state.isLoading = true;
    },
    getAclsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((acl) => {
          acl['key'] = acl.id;
          return acl;
        });
      } else {
        state.data = [];
      }
    },
    getAclsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getAclsStart, getAclsSuccess, getAclsFailure } = acls.actions;

export default acls.reducer;

export const fetchAcls = target => async (dispatch) => {
  try {
    dispatch(getAclsStart());
    const acls = await getAcls(target);
    dispatch(getAclsSuccess(acls));
  } catch (err) {
    dispatch(getAclsFailure(err.toString()));
  }
};
