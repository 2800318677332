import { createSlice } from '@reduxjs/toolkit';
import { addSpot } from '../api';

const spotInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const spot = createSlice({
  name: 'spot',
  initialState: spotInitialState,
  reducers: {
    getSpotStart(state) {
      state.isLoading = true;
    },
    getSpotSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data;
    },
    getSpotFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getSpotStart, getSpotSuccess, getSpotFailure } = spot.actions;

export default spot.reducer;

export const installSpot = (form, target) => async (dispatch) => {
  try {
    dispatch(getSpotStart());
    const spot = await addSpot(form, target);
    dispatch(getSpotSuccess(spot));
  } catch (err) {
    dispatch(getSpotFailure(err.toString()));
  }
};
