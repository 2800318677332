import React, { useState } from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import './modal.scss';

const StatusModal = ({ visible, title, loading, onStatus, onCancel, state, message }) => {
  const [confirmValue, setConfirmValue] = useState('');
  const [incorrectInput, setIncorrectInput] = useState(false);

  const onInputChange = (evt) => {
    setConfirmValue(evt.target.value);
    if (incorrectInput) {
      setIncorrectInput(false);
    }
  }

  const onClickDelete = () => {
    if (confirmValue === 'confirm') {
      onStatus();
    } else {
      setIncorrectInput(true);
    }
  }

  const handleCancel = () => {
    setConfirmValue('');
    setIncorrectInput(false);
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      title={`${state === 'enabled' ? 'Disable' : 'Enable'} ${title}`}
      onOk={onStatus}
      onCancel={handleCancel}
      width={500}
      footer={[
        <Button key='submit' loading={loading} type={state === 'enabled' ? 'danger' : 'primary'} onClick={onClickDelete}>
          Continue
        </Button>,
        <Button key='cancel' type='secondary' onClick={handleCancel}>
          Cancel
        </Button>
      ]}
    >
      <div>
        <p>Please enter <b>confirm</b> below to continue</p>
        {message ? 
          <p>{message}</p> : 
          ''
        }
        <Input
          placeholder={'confirm'}
          onChange={onInputChange}
          value={confirmValue}
          style={{
            borderColor: incorrectInput ? 'rgba(255,0,0,0.8)' : '',
          }}
        />
      </div>
    </Modal>
  );
};

export default StatusModal;
