import React, { useEffect, useState, useMemo } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Form from 'antd/lib/form';
import useForm from 'antd/lib/form/hooks/useForm';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import { useDispatch, useSelector } from 'react-redux';
import { addAcl, updateAcl } from '../../../api';
import { useHistory, useParams } from 'react-router';
import notification from '../../../utils/notification';
import BaseWizard from '../baseWizard';
import { extractPorts, mergeObjects, transformPortRange } from '../../../utils';
import '../index.scss';
import './addACL.scss';

import WrappedSelect from '../../select';
import { icmpTypes } from '../../constants';
import Checkbox from 'antd/lib/checkbox';
import ipValidator from '../../validators/ipValidator';
import portRangeValidator from '../../validators/portRangeValidator';
import { fetchAcls } from '../../../reducers/aclsSlice';

const parseAclFormData = (formData) => {
  const data = {
    name: formData.name,
    valid_until: formData.valid_until || null,
    proto: formData.protocol,
    action: formData.action,
    src_prefix: formData.source ? String(formData.source).trim() : null,
    src_length: parseInt(formData.source.split('/')[1]),
    dst_prefix: formData.destination ? String(formData.destination).trim() : null,
    dst_length: parseInt(formData.destination.split('/')[1]),
    src_port_group: null,
    dst_port_group: null,
    comment: formData.comment,
    ip_version: formData.source.match(/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\/[0-9]{1,2}/) ? 'ipv4' : 'ipv6',
    reverse: 'yes',
    ...extractPorts(formData),
    tenantsID: 1, //TODO - update this?
  };

  switch (formData.protocol) {
    case 'all':
    case 'icmp_all':
    case 'icmpv6_all':
      data.icmp_type = 1;
      data.established = 1;
      break;
    case 'icmp_custom':
      data.icmp_type = formData.icmp_type;
      data.established = 1;
      break;
    case 'icmpv6_custom':
      data.icmpv6_type = formData.icmpv6_type;
      data.established = 1;
      break;
    case 'ip':
      data.protocol_number = parseInt(formData.protocol_number);
      data.icmp_type = 1;
      data.established = 1;
      break;
    case 'tcp':
      data.icmp_type = 1;
      data.established = formData.established ? 1 : 0;
      break;
    case 'udp':
      data.icmp_type = 1;
      data.established = 1;
      break;
    default:
      break;
  }
  return data;
}

const { Option } = Select;

const AddACLWizard = () => {
  const [currentData, setCurrentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [protocol, setProtocol] = useState('all');

  const { acls, target } = useSelector((state) => ({
    acls: state.acls,
    target: state.clusters?.target
  }));

  const dispatch = useDispatch();

  const params = useParams();
  const history = useHistory();

  const { data = {} } = acls;
  const [form] = useForm();

  useEffect(() => {
    target && dispatch(fetchAcls(target));
  }, [dispatch, target]);

  const handleChangeProtocol = () => {
    setProtocol(form.getFieldValue('protocol'));
  };

  const initialValues = params?.wizardId ?
    transformPortRange(data?.find(acl => String(acl.id) === String(params.wizardId)) || {}) :
    {
      protocol: 'all',
      action: 'permit',
      reverse: true
    };

  const wizardSteps = useMemo(() => [
    {
      title: 'Select protocol and action',
      name: 'Protocol & Action',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
    {
      title: 'Define details',
      name: 'Details',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
  ], [form]);

  const onCancelWizard = () => {
    history.push('/network/security');
  }

  const onComplete = async () => {
    // get form data and send
    setLoading(true);
    const formData = form.getFieldsValue();
    let combinedData = mergeObjects(currentData, formData);

    let transformedData = parseAclFormData(combinedData);

    params.wizardId ? updateAcl(target, transformedData).then(() => {
      notification.success({
        message: 'Updated ACL'
      });
      setLoading(false);
      setCurrentData({});
      history.push('/network/security');
    }).catch((e) => {
      setLoading(false);
      if (e?.response?.data?.msg?.message) {
        notification.error({
          message: e?.response?.data?.msg?.message
        });
      } else {
        notification.error({
          message: 'Sorry something went wrong. Please try again later.'
        });
      }
    }) : addAcl(target, transformedData).then(() => {
      notification.success({
        message: 'New ACL added.'
      });
      setLoading(false);
      setCurrentData({});
      history.push('/network/security');
    }).catch((e) => {
      setLoading(false);
      if (e?.response?.data?.msg?.message) {
        notification.error({
          message: e?.response?.data?.msg?.message
        });
      } else {
        notification.error({
          message: 'Sorry something went wrong. Please try again later.'
        });
      }
    });
  }

  const onStepChange = () => {
    // We do this so we can expand on the field values since form resets the field value for each step
    setCurrentData(mergeObjects(currentData, form.getFieldsValue()));
  }

  return <>
    <BaseWizard
      completeText={params?.wizardId ? 'Update' : 'Create'}
      name={`${params?.wizardId ? 'Update' : 'Create'} ACL`}
      stepName={'ACL'}
      onComplete={onComplete}
      onCancelWizard={onCancelWizard}
      steps={wizardSteps}
      loading={loading}
      onStepChange={onStepChange}
    >
      {(currentStep, setCurrentStep) =>
        <Form form={form} layout='vertical' initialValues={initialValues} requiredMark={'optional'}>
          <Row gutter={24}>
            {currentStep === 0 &&
            <>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='name'
                  className='modal-item-label'
                  label='Name'
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input className='modal-item-controller' placeholder='Name' />
                </Form.Item>
              </Col>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='valid_until'
                  className='modal-item-label'
                  label='Active Until'
                >
                  <Input
                    type='date'
                    allowClear
                    className='modal-item-controller'
                    placeholder='Active Until'
                  />
                </Form.Item>
              </Col>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='protocol'
                  className='modal-item-label'
                  label='Protocol'
                  rules={[{ required: true, message: 'Protocol is required' }]}
                >
                  <WrappedSelect className='modal-item-controller' onChange={handleChangeProtocol}>
                    <Option value='all'>ALL</Option>
                    <Option value='ip'>IP</Option>
                    <Option value='tcp'>TCP</Option>
                    <Option value='udp'>UDP</Option>
                    <Option value='icmp_all'>ICMP All</Option>
                    <Option value='icmp_custom'>ICMP Custom</Option>
                    <Option value='icmpv6_all'>ICMPv6 All</Option>
                    <Option value='icmpv6_custom'>ICMPv6 Custom</Option>
                  </WrappedSelect>
                </Form.Item>
              </Col>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='action'
                  className='modal-item-label'
                  label='Action'
                  rules={[{ required: true, message: 'Action is required' }]}
                >
                  <WrappedSelect className='modal-item-controller'>
                    <Option value='permit'>Permit</Option>
                    <Option value='deny'>Deny</Option>
                  </WrappedSelect>
                </Form.Item>
              </Col>
              {protocol === 'ip' && (
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='protocol_number'
                    className='modal-item-label'
                    label='Protocol number'
                    rules={[{ required: true, message: 'Name is required' }]}
                  >
                    <Input className='modal-item-controller' placeholder='Protocol Number' />
                  </Form.Item>
                </Col>
              )}
              {protocol === 'icmp_custom' && (
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='icmp_type'
                    className='modal-item-label'
                    label='ICMP Type'
                    rules={[{ required: true, message: 'ICMP type is required' }]}
                  >
                    <WrappedSelect className='modal-item-controller'>
                      {icmpTypes.map((icmpType) => (
                        <Option key={icmpType.id} value={icmpType.name}>
                          {icmpType.name}
                        </Option>
                      ))}
                    </WrappedSelect>
                  </Form.Item>
                </Col>
              )}
              {protocol === 'icmpv6_custom' && (
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='icmpv6_type'
                    className='modal-item-label'
                    label='ICMPv6 Type'
                    rules={[{ required: true, message: 'ICMPv6 type is required' }]}
                  >
                    <WrappedSelect className='modal-item-controller'>
                      {icmpTypes.map((icmpType) => (
                        <Option key={icmpType.id} value={icmpType.name}>
                          {icmpType.name}
                        </Option>
                      ))}
                    </WrappedSelect>
                  </Form.Item>
                </Col>
              )}
              {protocol === 'tcp' && (
                <Col span={12} className={'modal-col'}>
                  <Form.Item name='established' className='modal-item-label' valuePropName='checked'>
                    <Checkbox className='modal-item-controller checkbox'>Established</Checkbox>
                  </Form.Item>
                </Col>
              )}
            </>
            }
            {currentStep === 1 && (
              <>
                <Col span={12} style={{
                  marginBottom: 10,
                }}>
                  <span className='static-text'>SOURCE</span>
                </Col>
                <Col span={12} style={{
                  marginBottom: 10,
                }}>
                  <span className='static-text'>DESTINATION</span>
                </Col>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='source'
                    className='modal-item-label'
                    label='IP Address'
                    validateTrigger='onBlur'
                    rules={[{ required: true, message: 'Source is required' }, ipValidator]}
                  >
                    <Input className='modal-item-controller' placeholder='IPv4/IPv6' />
                  </Form.Item>
                </Col>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='destination'
                    className='modal-item-label'
                    label='IP Address'
                    validateTrigger='onBlur'
                    rules={[{ required: true, message: 'Destination is required' }, ipValidator]}
                  >
                    <Input className='modal-item-controller' placeholder='IPv4/IPv6' />
                  </Form.Item>
                </Col>
                {(protocol === 'tcp' || protocol === 'udp') && (
                  <>
                    <Col span={12} className={'modal-col'}>
                      <Form.Item
                        name='src_port_range'
                        className='modal-item-label'
                        label='Port Range'
                        rules={[{ required: true, message: 'Source Port Range is required' }, portRangeValidator]}
                      >
                        <Input className='modal-item-controller' placeholder='1-65535' />
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'modal-col'}>
                      <Form.Item
                        name='dst_port_range'
                        className='modal-item-label'
                        label='Port Range'
                        rules={[{ required: true, message: 'Destination Port Range is required' }, portRangeValidator]}
                      >
                        <Input className='modal-item-controller' placeholder='1-65535' />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <hr className={'hr-separator'} />
                <Col span={12} className={'modal-col'}>
                  <Form.Item name='comment' className='modal-item-label' label='Comment'>
                    <Input.TextArea className='modal-item-controller' autoSize={{
                      minRows: 4,
                      maxRows: 4
                    }} />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      }
    </BaseWizard>
  </>
}

export default AddACLWizard;
