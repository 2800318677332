import React from 'react';
import './index.scss';
import Button from 'antd/lib/button';
import { useHistory } from 'react-router';

const NoClusterMessage = ({ hasSkip, onClaimCluster }) => {
  const history = useHistory();

  const onSkip = () => {
    history.replace('/overview');
  }

  return <div className='no-cluster-box'>
    <div className='no-cluster-message'>
      <p>{`It looks like you don't have any clusters attached, would you like to add one?`}</p>
    </div>
    <div className='button-container'>
    {hasSkip && <Button type='secondary' onClick={onSkip} className='skip-btn'>Skip</Button>}
    <Button type='primary' className='claim-cluster-btn' onClick={onClaimCluster}>Claim Cluster</Button>
    </div>
  </div>
}

export default NoClusterMessage;