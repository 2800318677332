import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import BaseTable from '../../components/table';
// import AddCircuitsModal from '../../components/modals/addCircuitsModal';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import TableMoreItems from '../../components/modals/tableMoreItems';
import ShowStatus from '../../components/showstatus';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCircuit } from '../../api';
import { fetchCircuits } from '../../reducers/circuitSlice';
import notification from '../../utils/notification';
import { useHistory } from 'react-router';

const Circuits = () => {
  const [visibleDeleteModal, toggleVisibleDeleteModal] = useState(false);
  const [current, setCurrent] = useState(undefined);

  const { data, isLoading } = useSelector(state => state.circuits);
  const target = useSelector(state => state.clusters?.target);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    target && dispatch(fetchCircuits(target));
  }, [target, dispatch]);

  const circuitsColumns = [
      {
        title: 'NAME',
        dataIndex: 'name',
        width: 150,
        sorter: (a, b) => a.name > b.name
      },
      {
        title: 'VXLAN ID',
        dataIndex: 'vxlan_id',
        width: 200,
        sorter: (a, b) => a.vxlan_id > b.vxlan_id
      },
      {
        title: 'VLANS',
        dataIndex: 'va_vlans',
        width: 200,
        sorter: (a, b) => a.va_vlans > b.va_vlans
      },
      {
        title: 'ANYCAST MAC',
        dataIndex: 'mac_address',
        width: 250,
        sorter: (a, b) => a.mac_address > b.mac_address
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        sorter: (a, b) => a.state > b.state,
        render: (state) => <ShowStatus status={state} />
      },
      {
        title: '',
        dataIndex: 'actions',
        className: 'action-column',
        width: 20,
        render: (_, record) => (
          <TableMoreItems onDelete={openDeleteModal(record)} onUpdate={openUpdateWizard(record)} />
        )
      }
    ];

  const openWizard = () => {
    history.push('/network/circuits/wizard');
  };

  const onUpdate = () => {
    target && dispatch(fetchCircuits(target));
  }

  const openUpdateWizard = (record) => () => {
    history.push({
      pathname: `/network/circuits/wizard/${record.id}`,
      state: record
    });
  };

  const openDeleteModal = (record) => () => {
    setCurrent(record);
    toggleVisibleDeleteModal(true);
  };

  const closeDeleteModal = () => {
    toggleVisibleDeleteModal(false);
    setCurrent(undefined);
  };

  const handleDelete = () => {
    return deleteCircuit(target, current).then(() => {
      closeDeleteModal();
      notification.success({
        message: 'Circuit deleted.',
        placement: 'bottomRight'
      });
    }).catch((e) => {
      if (e?.response?.data?.message) {
        notification.error({
          message: e?.response?.data?.message,
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    })
  };

  return (
    <div>
      <Header
        type='button'
        buttonName='Add Circuits'
        title={{
          singular: 'Circuit',
          plural: 'Circuits'
        }}
        items={data}
        loading={isLoading}
        openModal={openWizard}
        onRefresh={onUpdate}
        onUpdate={onUpdate}
        description='View network subnets deployed at each of your locations'
      />
      <BaseTable 
        columns={circuitsColumns} 
        data={data}
        loading={isLoading}
      />
      <DeleteItemModal
        visible={visibleDeleteModal}
        title='Circuits'
        onDelete={handleDelete}
        onCancel={closeDeleteModal}
      />
    </div>
  );
};

export default Circuits;
