import React from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import './modal.scss';
import vlanIdValidator from '../validators/vlanIdValidator';

const BaseVlansModal = ({ visible, loading, handleCreate, onCancel, current }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title={current ? 'Update VLAN' : 'Add VLAN'}
      onOk={handleCreate}
      onCancel={onCancel}
      width={600}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => handleCreate(form))
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          {current ? 'Update' : 'Create' }
        </Button>,
        <Button
          key='cancel'
          type='secondary'
          onClick={onCancel}
        >
          Cancel
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' requiredMark={'optional'}>
        <Row gutter={24}>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              rules={[{ required: true, message: 'Name must be alphanumeric character only', pattern: new RegExp("^[a-zA-Z0-9]*$")}]}
            >
              <Input className='modal-item-controller' placeholder='Name' maxLength={24} />
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='vlanId'
              className='modal-item-label'
              label='VLAN ID'
              rules={[{ required: true, message: 'VLAN ID is required' }, vlanIdValidator]}
              disabled={current}
            >
              <InputNumber className='modal-item-controller' type='number' min={2} max={4094} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BaseVlansModal;
