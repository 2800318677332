import { createSlice } from '@reduxjs/toolkit';
import { getUnusedSubnets } from '../api';

const unusedSubnetsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const unusedSubnets = createSlice({
  name: 'unusedSubnets',
  initialState: unusedSubnetsInitialState,
  reducers: {
    getUnusedSubnetsStart(state) {
      state.isLoading = true;
    },
    getUnusedSubnetsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((subnet) => {
          subnet['key'] = subnet.id;
          subnet.assignments = subnet.children;
          delete subnet.children;
          return subnet;
        });
      } else {
        state.data = [];
      }
    },
    getUnusedSubnetsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getUnusedSubnetsStart, getUnusedSubnetsSuccess, getUnusedSubnetsFailure } = unusedSubnets.actions;

export default unusedSubnets.reducer;

export const fetchUnusedSubnets = target => async (dispatch) => {
  try {
    dispatch(getUnusedSubnetsStart());
    const unusedSubnets = await getUnusedSubnets(target);
    dispatch(getUnusedSubnetsSuccess(unusedSubnets));
  } catch (err) {
    dispatch(getUnusedSubnetsFailure(err.toString()));
  }
};
