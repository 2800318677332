import { createSlice } from '@reduxjs/toolkit';
import { getClouds } from '../api';

const cloudsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const clouds = createSlice({
  name: 'clouds',
  initialState: cloudsInitialState,
  reducers: {
    getCloudsStart(state) {
      state.isLoading = true;
    },
    getCloudsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((cloud) => {
          cloud['key'] = cloud.id || cloud.uuid;
          return cloud;
        });
      } else {
        state.data = [];
      }
    },
    getCloudsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getCloudsStart, getCloudsSuccess, getCloudsFailure } = clouds.actions;

export default clouds.reducer;

export const fetchClouds = target => async (dispatch) => {
  try {
    dispatch(getCloudsStart());
    const clouds = await getClouds(target);
    dispatch(getCloudsSuccess(clouds));
  } catch (err) {
    dispatch(getCloudsFailure(err.toString()));
  }
};
