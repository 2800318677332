import { createSlice } from '@reduxjs/toolkit';
import { getClusterCapacity } from '../api';

const capacityInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const capacity = createSlice({
  name: 'capacity',
  initialState: capacityInitialState,
  reducers: {
    getCapacityStart(state) {
      state.isLoading = true;
    },
    getCapacitySuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data;
    },
    getCapacityFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getCapacityStart, getCapacitySuccess, getCapacityFailure } = capacity.actions;

export default capacity.reducer;

export const fetchCapacity = (target) => async (dispatch) => {
  try {
    dispatch(getCapacityStart());
    const capacity = await getClusterCapacity(target);
    dispatch(getCapacitySuccess(capacity));
  } catch (err) {
    dispatch(getCapacityFailure(err.toString()));
  }
};
