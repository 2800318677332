import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import InputNumber from 'antd/lib/input';
import Slider from 'antd/lib/slider';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { miToGi } from '../../utils';

import { fetchDataVolumes } from '../../reducers/vmsSlice';
import './modal.scss';

function hddFormatter(value) {
  return `${value}Gi`;
}

const ResizeVMDiskModal = ({ visible, loading, handleComplete, onCancel, namespaces, target, capacityLimits, data }) => {
  const [form] = Form.useForm();
  const [hdd, setHDD] = useState(8);

  const initialValues = {
    namespace: data?.namespace || '',
    name: data?.name || '',
    storage: miToGi(data?.disk?.size) || 8
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataVolumes(target));
  }, [dispatch, target, data]);

  useEffect(() => {
    setHDD(initialValues.storage);
  }, [initialValues.storage]);

  useEffect(() => {
    form.setFieldsValue({
      storage: hdd
    });
  }, [form, hdd]);

  const onComplete = () => {
    handleComplete(form);
  }

  return (
    <Modal
      visible={visible}
      title='Resize Virtual Machine Disk'
      onOk={handleComplete}
      onCancel={onCancel}
      width={640}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
            .validateFields()
            .then(() => {
              onComplete()
            })
            .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          Resize
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' requiredMark={'optional'} initialValues={initialValues}>
        <Row gutter={24} align="bottom">
          <Col span={18}>
            <Form.Item
              name='storage'
              className='modal-item-label'
              label='HDD'
              rules={[{ required: true, message: 'HDD is required' }]}
            >
              <Slider 
                tipFormatter={hddFormatter} 
                onChange={(value) => setHDD(value)} 
                min={8}
                max={2048}
                step={8}
                //disabled={data} if vm is on?
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <InputNumber 
              className='modal-item-controller' 
              placeholder='HDD' 
              value={hdd} 
              onChange={(e) => setHDD(e.target.value)} 
              suffix="Gi"
              //disabled={data} if vm is on?
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ResizeVMDiskModal;
