import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import notification from '../../utils/notification';
import StatusModal from '../../components/modals/statusModal';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import { updateCloudState, deleteCloud } from '../../api';
import { fetchCloudStatus } from '../../reducers/cloudStatusSlice';
import { fetchClouds } from '../../reducers/cloudSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AwsIcon } from '../../assets/logo_aws.svg';
import { ReactComponent as GoogleIcon } from '../../assets/logo_google_cloud.svg';
import { ReactComponent as AzureIcon } from '../../assets/logo_microsoft_azure.svg';
import { useHistory } from 'react-router';

const CloudActions = ({ id, state, type }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const target = useSelector((state) => state.clusters?.target);

  const dispatch = useDispatch();
  const history = useHistory();

  const onStatus = () => {
    setStatusLoading(true);
    const stateChange = state === 'enabled' ? 'disabled' : 'enabled';
    updateCloudState(target, id, stateChange)
      .then(() => {
        setStatusLoading(false);
        setIsStatusModalVisible(false);
        dispatch(fetchCloudStatus(target, id));
        dispatch(fetchClouds(target));
      }).catch(() => {
      notification.error({
        message: 'Something went wrong. Please try again later'
      })
      setStatusLoading(false);
    })
  }

  const onDelete = () => {
    setDeleteLoading(true);
    deleteCloud(target, id)
      .then(() => {
        setDeleteLoading(false);
        setIsDeleteModalVisible(false);
        dispatch(fetchCloudStatus(target, id));
        dispatch(fetchClouds(target));
        history.push('/network/cloud');
      }).catch(() => {
      notification.error({
        message: 'Something went wrong. Please try again later'
      })
      setDeleteLoading(false);
    })
  }

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  }

  const showStatusModal = () => {
    setIsStatusModalVisible(true);
  }

  const onCancelStatus = () => {
    setIsStatusModalVisible(false);
  }

  const onCancelDelete = () => {
    setIsDeleteModalVisible(false);
  }

  return (
    <Row justify='space-between' className='extended-footer'>
      <Col>
        {String(type).toLowerCase() === 'aws' && <AwsIcon />}
        {String(type).toLowerCase() === 'gcp' && <GoogleIcon />}
        {String(type).toLowerCase() === 'azure' && <AzureIcon />}
      </Col>
      <Col>
        <Button className='destroy-btn' onClick={showDeleteModal} type='danger' loading={deleteLoading}>
          Delete
        </Button>
        {state && <Button className={`state-btn ${state === 'enabled' ? 'disable' : 'enable'}`} type='danger' onClick={showStatusModal} loading={statusLoading}>
          {state === 'enabled' ? 'Disable BGP' : 'Enable BGP'}
        </Button> }
      </Col>
      <StatusModal
        title={'Cloud'}
        visible={isStatusModalVisible}
        loading={statusLoading}
        state={state}
        onCancel={onCancelStatus}
        onStatus={onStatus}
        />
        <DeleteItemModal
          title={'Cloud'}
          visible={isDeleteModalVisible}
          loading={deleteLoading}
          onCancel={onCancelDelete}
          onDelete={onDelete}
        />
    </Row>
  );
};

export default CloudActions;
