import React, { useEffect, useState, useMemo } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Form from 'antd/lib/form';
import useForm from 'antd/lib/form/hooks/useForm';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import Spin from 'antd/lib/spin';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import Button from 'antd/lib/button';
import { createStore, applyMiddleware } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import { postCloudconnect, addCredential, postCloudconnectTemplate } from '../../../api';
import { fetchCloudPrefill } from '../../../reducers/cloudPrefillSlice';
import { fetchCloudCredentials } from '../../../reducers/cloudCredentialSlice';
import { useHistory, useParams } from 'react-router';
import notification from '../../../utils/notification';
import BaseWizard from '../baseWizard';
import { PROVIDERS } from '../../constants';
import { mergeObjects, transformToCredential } from '../../../utils';
import AddCredentialModal from '../../modals/addCredentialModal';
import '../index.scss';
import './addCloud.scss';

import { AzureLogo, GoogleLogo, AWSLogo, PCCWLogo, MegaportLogo } from '../../logo';
import cloudTemplateSlice, { fetchCloudTemplates } from '../../../reducers/cloudTemplateSlice';
import WrappedSelect from '../../select';
import { updateStepVisibility } from '../../../reducers/wizardSlice';

const store = createStore(cloudTemplateSlice, applyMiddleware(thunk));

// async function updateNewTemplate(target) {
//   return async function(dispatch, getState) {
//     await dispatch(fetchCloudTemplates(target));
//   }
// }

const { Option } = Select;
const { AWS, GOOGLE, AZURE, PCCW, MEGAPORT, EQUINIX } = PROVIDERS;

const getRouter = (prefill, type) => {
  if (!type) return [];
  if (type === AWS) {
    return prefill[AWS].directConnects;
  }
  if (type === GOOGLE) {
    return prefill[GOOGLE].gcpCloudRouters;
  }
  if (type === AZURE) {
    return prefill[AZURE].virtualNetworkGateways;
  }
  return [];
}

const getTransformedRouter = (data, type) => {
  if (!type) return {};
  if (type === AWS) {
    return {
      aws: {
        directConnectGatewayId: data.router
      }
    }
  }
  if (type === GOOGLE) {
    return {
      gcp: {
        routerALink: data.router,
        routerBLink: data.altRouter
      }
    }
  }
  if (type === AZURE) {
    return {
      virtualNetworkGatewayId: data.router
    }
  }
  return {};
}

const transformTemplate = data => ({
  type: data.type,
  provider: data.provider,
  description: data.description,
  location: data.location,
  name: data.name,
  srcPortId: data.pccw?.srcPortId,
  destPortId: data.pccw?.destPortId,
  ebgpOffloader: data.netris?.terminate_on_switch === 'yes' ? 'none-offload-port-id' : data.netris?.nfv_port_id,
  switchPort: `${data.netris?.switch_port_id}:${data.netris?.term_switch_id}`,
  profile: data.profiles?.[data.type],
  providerProfile: data.profiles?.[data.provider],
  azureServiceProviderName: data.azure?.serviceProviderName,
  azurePeeringLocation: data.azure?.peeringLocation
});

const AddCloudWizard = () => {
  const [activeCloud, setActiveCloud] = useState(AWS);
  const [currentData, setCurrentData] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [templateCreating, settemplateCreating] = useState(false);
  const { cloudPrefill, cloudCredential, cloudTemplate, target } = useSelector((state) => ({
    cloudPrefill: state.cloudPrefill,
    cloudCredential: state.cloudCredential,
    cloudTemplate: state.cloudTemplate,
    target: state.clusters?.target
  }));

  const params = useParams();

  const [selectedProviderPort, setSelectedProviderPort] = useState(undefined);
  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const wizard = useSelector((state) => state.wizard);

  const history = useHistory();
  const dispatch = useDispatch();

  const { data: cloudTemplateData, isLoading: isTemplateLoading, error: templateError } = cloudTemplate;
  const { data = {}, data: cloudPrefillData, isLoading: isPrefillLoading, error: prefillError } = cloudPrefill;
  const { data: cloudCredentialData } = cloudCredential;

  const [visible, setVisible] = useState(false);
  const [visibleConnectModal, setVisibleConnectModal] = useState(false);

  const [form] = useForm();

  const initialValues = params?.wizardId ?
    transformTemplate(cloudTemplateData?.find(template => String(template._id) === String(params.wizardId)) || {}) :
    {
      type: AWS,
      provider: PCCW
    };

  const [activeConnect, setActiveConnect] = useState(initialValues.provider || PCCW);

  const wizardSteps = useMemo(() => [
    {
      title: 'Select cloud provider and template',
      name: 'Template',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
    {
      title: 'Select Cloud provider and profile',
      name: 'Cloud Profile',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
      visible: 'no'
    },
    {
      title: 'Select Connect provider and profile',
      name: 'Connect Profile',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
      visible: 'no'
    },
    {
      title: 'Fill in cloud template details',
      name: 'Cloud Template Details',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
      error: !!prefillError,
      preAction: async () => {
        dispatch(fetchCloudPrefill(activeCloud, activeConnect, target, form.getFieldValue('profile'), form.getFieldValue('providerProfile')));
      },
      visible: 'no'
    },
    {
      title: 'Fill in cloud details',
      name: 'Cloud Details',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
      error: !!prefillError || !!templateError,
    },
  ], [activeCloud, activeConnect, dispatch, target, form, prefillError, templateError]);

  useEffect(() => {
    dispatch(fetchCloudTemplates(target));
  }, [dispatch, target]);

  const onCancelWizard = () => {
    history.push('/network/cloud');
  }

  const handleClickCloud = (val) => () => {
    form.resetFields();
    form.setFieldsValue({ type: val, profile: undefined });
    setActiveCloud(val);
  };

  const handleTemplateSelected = (val) => {
    const template = cloudTemplateData.find(template => template._id === val);
    const toggleSteps = [
      'Cloud Profile',
      'Connect Profile',
      'Cloud Template Details'
    ];
    if (val === 'create') {
      setSelectedTemplate(template);
      toggleSteps.forEach(step => {
        dispatch(updateStepVisibility({
          name: step,
          visible: 'yes'
        }));
      });
    } else if (template) {
      setSelectedTemplate(template);
      dispatch(fetchCloudPrefill(template.type, template.provider, target, template.profiles[activeCloud], template.profiles[template.provider]));
      toggleSteps.forEach(step => {
        dispatch(updateStepVisibility({
          name: step,
          visible: 'no'
        }));
      });
    }
  }

  const handleClickConnect = (val) => () => {
    form.resetFields();
    form.setFieldsValue({ provider: val, type: activeCloud, providerProfile: undefined });
    setActiveConnect(val);
  };

  const handleNoProfileClick = (evt) => {
    evt.preventDefault();
    setVisible(true);
    setVisibleConnectModal(false);
  }

  const handleNoConnectProfileClick = (evt) => {
    evt.preventDefault();
    setVisible(false);
    setVisibleConnectModal(true);
  }

  const handleSelectProviderPort = (value) => {
    setSelectedProviderPort(value);
  }

  const createCloudTemplate = async () => {
    // get form data and send
    settemplateCreating(true);
    const formData = form.getFieldsValue();
    let combinedData = mergeObjects(currentData, formData);
    const [switchPortId, termSwitchPortId] = String(combinedData.switchPort).split(':').map(elem => Number.parseInt(elem)).filter((elem) => !Number.isNaN(elem));

    let transformedData = {
      name: combinedData.name,
      description: combinedData.description,
      type: combinedData.type,
      provider: combinedData.provider,
      location: combinedData.location,
      pccw: {
        srcPortId: combinedData.srcPortId,
        destPortId: combinedData.destPortId
      },
      netris: {
        ...(combinedData.ebgpOffloader !== 'none-offload-port-id' && combinedData.ebgpOffloader != null ? {
          nfv_port_id: combinedData.ebgpOffloader,
          terminate_on_switch: undefined
        } : {
          terminate_on_switch: 'yes',
        }),
        switch_port_id: switchPortId,
        term_switch_id: termSwitchPortId
      },
      profiles: {
        [combinedData.type]: combinedData.profile,
        [combinedData.provider]: combinedData.providerProfile
      }
    }

    if (combinedData.type === AZURE) {
      transformedData = {
        ...transformedData,
        azure: {
          serviceProviderName: combinedData.azureServiceProviderName,
          peeringLocation: combinedData.azurePeeringLocation
        }
      }
    }

    postCloudconnectTemplate(target, transformedData).then(async () => {
      notification.success({
        message: 'New Cloud Template added.'
      });
      settemplateCreating(false);
      await store.dispatch(fetchCloudTemplates(target));
      dispatch(fetchCloudTemplates(target));
      //const storeData = JSON.parse(store.getState());

      const template = store.getState().data.find(template => template.name === transformedData.name);
      setSelectedTemplate(template);
      dispatch(fetchCloudPrefill(template.type, template.provider, target, template.profiles[activeCloud], template.profiles[template.provider]));
      setCurrentData({});
      //clear form
    }).catch(() => {
      settemplateCreating(false);
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    });
  }

  const onComplete = async () => {
    // get form data and send
    setLoading(true);
    const formData = form.getFieldsValue();
    let combinedData = mergeObjects(currentData, formData);

    let transformedData = {
      name: combinedData.name,
      type: selectedTemplate.type,
      description: combinedData.description,
      pccw: {
        speed: combinedData.speed,
      },
      netris: {
        circuit_id: combinedData.circuit
      },
      template: selectedTemplate._id
    }
    if (selectedTemplate.type === AZURE) {
      transformedData = {
        ...transformedData,
        azure: {
          bandwidthInMbps: combinedData.speed,
          ...getTransformedRouter(combinedData, selectedTemplate.type),
        }
      }
    } else {
      transformedData = {
        ...transformedData,
        ...getTransformedRouter(combinedData, selectedTemplate.type),
      }
    }

    setLoading(false);

    postCloudconnect(target, transformedData).then(() => {
      notification.success({
        message: 'New Cloud added.'
      });
      setLoading(false);
      setCurrentData({});
      history.push('/network/cloud');
    }).catch(() => {
      setLoading(false);
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    });
  }

  const onCloseProfile = () => {
    setVisible(false);
    setVisibleConnectModal(false);
  }

  const onCreateProfile = async (form) => {
    const data = form.getFieldsValue();
    setIsProfileLoading(true);
    const transformedData = transformToCredential(data);
    return addCredential(target, String(data.type).toLowerCase(), transformedData).then(() => {
      notification.success({
        message: 'New Profile added.'
      });
      setIsProfileLoading(false);
      setVisible(false);
      setVisibleConnectModal(false);
      dispatch(fetchCloudCredentials(target));
    }).catch(() => {
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
      setIsProfileLoading(false);
      return Promise.reject();
    });
  };

  const hasProfiles = Object.keys(cloudCredentialData || {}).length > 0;

  const onStepChange = (currentStep) => {
    // We do this so we can expand on the field values since form resets the field value for each step
    setCurrentData(mergeObjects(currentData, form.getFieldsValue()));
    if (currentStep === 3) {
      createCloudTemplate();
    }
  }

  return <>
    <BaseWizard
      completeText={'Create'}
      name={`Create Cloud Circuit`}
      stepName={'Cloud Circuit'}
      onComplete={onComplete}
      onCancelWizard={onCancelWizard}
      steps={wizardSteps}
      loading={loading}
      onStepChange={onStepChange}
    >
      {(currentStep, setCurrentStep) =>
        <Form form={form} layout='vertical' initialValues={initialValues} requiredMark={'optional'}>
          <Row gutter={24}>
            {currentStep === 0 &&
            <>
              <Col span={24} className={'wizard-col'}>
                <Form.Item name='type' className='modal-item-label' label='Choose Cloud' rules={[{ required: true, message: 'Cloud is required' }]}>
                  <Row justify='start' align='middle'>
                    < Col
                      className={`cloud-area ${activeCloud === AWS && 'active'}`}
                      onClick={handleClickCloud(AWS)}
                    >
                      <div className={'provider-logo-container'}><AWSLogo />Amazon Web Services</div>
                      {activeCloud === AWS &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                    <Col
                      className={`cloud-area ${activeCloud === GOOGLE && 'active'}`}
                      onClick={handleClickCloud(GOOGLE)}
                    >
                      <div className={'provider-logo-container'}><GoogleLogo />Google Cloud</div>
                      {activeCloud === GOOGLE &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                    <Col
                      className={`cloud-area ${activeCloud === AZURE && 'active'}`}
                      onClick={handleClickCloud(AZURE)}
                    >
                      <div className={'provider-logo-container'}><AzureLogo />Microsoft Azure</div>
                      {activeCloud === AZURE &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={24} className={'wizard-col'}>
                <Form.Item
                  name='template'
                  className='modal-item-label template-item'
                  label={'Template'}
                  rules={[{ required: true, message: 'Template is required' }]}
                  disabled={isTemplateLoading}
                >
                  <WrappedSelect className='modal-item-controller' onChange={handleTemplateSelected}
                          loading={isTemplateLoading} disabled={isTemplateLoading}>
                    {cloudTemplateData.filter(template => template.type === activeCloud).map((template) => (
                      <Option key={template._id} value={template._id}>
                        {template.name}
                      </Option>
                    ))}
                    <Option value="create">Create</Option>
                  </WrappedSelect>
                </Form.Item>
              </Col>
            </>
            }
            
            {/* CREATE TEMPLATE START*/}

            {currentStep === 1 && wizard.steps[currentStep].visible === 'yes' &&
            <>
              <Col span={24} className={'wizard-col'}>
                <Form.Item
                  name='type'
                  className='modal-item-label'
                  label='Choose Cloud'
                  rules={[{ required: true, message: 'Cloud is required' }]}
                >
                  <Row justify='start' align='middle'>
                    < Col
                      className={`cloud-area ${activeCloud === AWS && 'active'}`}
                      onClick={handleClickCloud(AWS)}
                    >
                      <div className={'provider-logo-container'}><AWSLogo />Amazon Web Services</div>
                      {activeCloud === AWS &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                    <Col
                      className={`cloud-area ${activeCloud === GOOGLE && 'active'}`}
                      onClick={handleClickCloud(GOOGLE)}
                    >
                      <div className={'provider-logo-container'}><GoogleLogo />Google Cloud</div>
                      {activeCloud === GOOGLE &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                    <Col
                      className={`cloud-area ${activeCloud === AZURE && 'active'}`}
                      onClick={handleClickCloud(AZURE)}
                    >
                      <div className={'provider-logo-container'}><AzureLogo />Microsoft Azure</div>
                      {activeCloud === AZURE &&
                      <CheckCircleFilled className="cloud-area-check" />}
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={24} className={'wizard-col'}>
                <Form.Item
                  name='profile'
                  className='modal-item-label profile-item'
                  label={
                    <>
                      <span className='profile-span'>Profile</span>
                      <PlusOutlined onClick={handleNoProfileClick} />
                    </>}
                  rules={[{ required: true, message: 'Profile is required' }]}
                  disabled={!hasProfiles}
                >
                  <WrappedSelect className='modal-item-controller' disabled={!hasProfiles}>
                    {cloudCredentialData[activeCloud] &&
                    cloudCredentialData[activeCloud].map((profile) => (
                      <Option key={profile._id} value={profile._id}>
                        {profile.name}
                      </Option>
                    ))}
                  </WrappedSelect>
                </Form.Item>
              </Col>
            </>
            }
            {currentStep === 2 && (
              <>
                <Col span={24} className={'wizard-col'}>
                  <Form.Item
                    name='provider'
                    className='modal-item-label'
                    label='Choose Connect'
                    rules={[{ required: true, message: 'Connect is required' }]}
                  >
                    <Row justify='start' align='middle'>
                      < Col
                        className={`cloud-area ${activeConnect === PCCW && 'active'}`}
                        onClick={handleClickConnect(PCCW)}
                      >
                        <div className={'provider-logo-container'}><PCCWLogo />PCCW</div>
                        {activeConnect === PCCW &&
                        <CheckCircleFilled className="cloud-area-check" />}
                      </Col>
                      < Col
                        className={`cloud-area ${activeConnect === MEGAPORT && 'active'}`}
                        onClick={handleClickConnect(MEGAPORT)}
                      >
                        <div className={'provider-logo-container'}><MegaportLogo />Megaport</div>
                        {activeConnect === MEGAPORT &&
                        <CheckCircleFilled className="cloud-area-check" />}
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={24} className={'wizard-col'}>
                  <Form.Item
                    name='providerProfile'
                    className='modal-item-label profile-item'
                    label={
                      <>
                        <span className='profile-span'>Profile</span>
                        <PlusOutlined onClick={handleNoConnectProfileClick} />
                      </>}
                    rules={[{ required: true, message: 'Profile is required' }]}
                    disabled={!hasProfiles}
                  >
                    <WrappedSelect className='modal-item-controller' disabled={!hasProfiles}>
                      {cloudCredentialData[activeConnect] &&
                      cloudCredentialData[activeConnect].map((profile) => (
                        <Option key={profile._id} value={profile._id}>
                          {profile.name}
                        </Option>
                      ))}
                    </WrappedSelect>
                  </Form.Item>
                </Col>
              </>
            )}
            {currentStep === 3 && (
              isPrefillLoading ? <Spin indicator={<LoadingOutlined spin style={{
                  fontSize: 100,
                  marginLeft: -50
                }} />} spinning={isPrefillLoading} /> :
                prefillError ?
                  <Col span={24}>
                    <div className='prefill-error-container'>
                      <div className='message-container'>
                        <ExclamationCircleOutlined />
                        There seems to be something amiss. Please check your credentials or try again later.
                      </div>
                      <Button className='error-back-btn' type='primary' onClick={() => setCurrentStep(currentStep - 1)}>Go
                        to previous step</Button>
                    </div>
                  </Col> :
                  <>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='name'
                        className='modal-item-label'
                        label='Name'
                        rules={[{ required: true, message: 'Name is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <Input className='modal-item-controller' placeholder='Name'
                               disabled={isPrefillLoading} />
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='location'
                        className='modal-item-label'
                        label='Location'
                        rules={[{ required: true, message: 'Location is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <Input className='modal-item-controller' placeholder='Location'
                               disabled={isPrefillLoading} />
                      </Form.Item>
                    </Col>
                    <Col span={24} className={'wizard-col'}>
                      <Form.Item
                        name='description'
                        className='modal-item-label'
                        label='Description'
                        rules={[{ required: true, message: 'Description is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <Input className='modal-item-controller' placeholder='Description'
                               disabled={isPrefillLoading} />
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='switchPort'
                        className='modal-item-label'
                        label='Switch Port'
                        rules={[{ required: true, message: 'Switch Port is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}>
                          {data[activeCloud] &&
                          data[activeCloud].switchPorts?.map((switchPort) => (
                            <Option key={switchPort.id} value={`${switchPort.id}:${switchPort.switch_id}`}>
                              {switchPort.name}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='ebgpOffloader'
                        className='modal-item-label'
                        label='EBGP Offloader'
                        disabled={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}
                                       defaultValue={'none-offload-port-id'}>
                          {data[activeCloud] &&
                          [{
                            offload_port_id: 'none-offload-port-id',
                            location: 'None'
                          }].concat(data[activeCloud]?.ebgpOffloaders || []).map((ebgpOffloader) => (
                            <Option key={ebgpOffloader.offload_port_id} value={ebgpOffloader.offload_port_id}>
                              {ebgpOffloader.location}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='srcPortId'
                        className='modal-item-label'
                        label='OnPrem Port'
                        rules={[{ required: true, message: 'OnPrem Port is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}>
                          {data[activeCloud] &&
                          data[activeCloud].onpremPorts?.map((onpremPort) => (
                            <Option key={onpremPort.id} value={onpremPort.id}>
                              {onpremPort.name}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    {activeCloud !== AZURE && <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='destPortId'
                        className='modal-item-label'
                        label='Cloud Port'
                        rules={[{ required: true, message: 'Cloud Port is required' }]}
                        disabled={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}>
                          {data[activeCloud] &&
                          data[activeCloud].cloudPorts?.map((cloudPort) => (
                            <Option key={cloudPort.id} value={cloudPort.id}>
                              {cloudPort.name}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>}
                    {activeCloud === AZURE && <>
                      <Col span={12} className={'wizard-col'}>
                        <Form.Item
                          name='azureServiceProviderName'
                          className='modal-item-label'
                          label='Provider Port'
                          disabled={isPrefillLoading}
                          rules={[{ required: true, message: 'Provider Port is required' }]}
                        >
                          <WrappedSelect className='modal-item-controller' disabled={isPrefillLoading}
                                         onChange={handleSelectProviderPort}>
                            {data[activeCloud] &&
                            (data[activeCloud]?.azureProviderPorts || []).map((providerPort) => (
                              <Option key={providerPort.name} value={providerPort.name}>
                                {providerPort.name}
                              </Option>
                            ))}
                          </WrappedSelect>
                        </Form.Item>
                      </Col>
                      <Col span={12} className={'wizard-col'}>
                        <Form.Item
                          name='azurePeeringLocation'
                          className='modal-item-label'
                          label='Peering Location'
                          disabled={isPrefillLoading}
                          rules={[{ required: true, message: 'Peering Location is required' }]}
                        >
                          <WrappedSelect className='modal-item-controller'
                                         disabled={isPrefillLoading || !(selectedProviderPort || form.getFieldValue('azureServiceProviderName'))}>
                            {data[activeCloud] &&
                            (data[activeCloud]?.azureProviderPorts?.find?.(port => port.name === selectedProviderPort || port.name === form.getFieldValue('azureServiceProviderName'))?.peeringLocations || []).map((peeringLocation) => (
                              <Option key={peeringLocation} value={peeringLocation}>
                                {peeringLocation}
                              </Option>
                            ))}
                          </WrappedSelect>
                        </Form.Item>
                      </Col>
                    </>}
                  </>
            )}

            {/* CREATE TEMPLATE FINISH*/}


            {currentStep === 4 && (
              isPrefillLoading || templateCreating ? <Spin indicator={<LoadingOutlined spin style={{
                  fontSize: 100,
                  marginLeft: -50,
                }} />} spinning={isPrefillLoading || templateCreating} /> :
                isPrefillLoading ?
                  <div className='prefill-error-container'>
                    <div className='message-container'>
                      <ExclamationCircleOutlined />
                      There seems to be something amiss. Please check your credentials or try again later.
                    </div>
                    <Button className='error-back-btn' type='primary' onClick={() => setCurrentStep(currentStep - 1)}>Go
                      to previous step</Button>
                  </div> :
                  <>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='name'
                        className='modal-item-label'
                        label='Name'
                        rules={[{ required: true, message: 'Name is required' }]}
                      >
                        <Input className='modal-item-controller' placeholder='Name' />
                      </Form.Item>
                    </Col>

                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='description'
                        className='modal-item-label'
                        label='Description'
                        rules={[{ required: true, message: 'Description is required' }]}
                      >
                        <Input className='modal-item-controller' placeholder='Description' />
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='speed'
                        className='modal-item-label'
                        label='Speed'
                        disabled={!selectedTemplate || isPrefillLoading}
                        rules={[{ required: true, message: 'Speed is required' }]}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={!selectedTemplate || isPrefillLoading}
                                loading={isPrefillLoading}>
                          {cloudPrefillData?.[selectedTemplate?.type]?.cloudPorts?.find(cloudPort => cloudPort._id === selectedTemplate?.dstPortId)?.partner?.connectionSpeeds?.map(connectionSpeed =>
                            <Option key={connectionSpeed.value}
                                    value={connectionSpeed.value}>{connectionSpeed.value} Mbit/s</Option>)}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='circuit'
                        className='modal-item-label'
                        label='Circuit'
                        rules={[{ required: true, message: 'Circuit is required' }]}
                        disabled={!selectedTemplate || isPrefillLoading} loading={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={!selectedTemplate || isPrefillLoading}
                                loading={isPrefillLoading}>
                          {cloudPrefillData?.[selectedTemplate?.type] &&
                          cloudPrefillData?.[selectedTemplate?.type].circuits.map((circuit) => (
                            <Option key={circuit.id} value={circuit.id}>
                              {circuit.name}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='router'
                        className='modal-item-label'
                        label='Router'
                        rules={[{ required: true, message: 'Router is required' }]}
                        disabled={!selectedTemplate || isPrefillLoading} loading={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={!selectedTemplate || isPrefillLoading}
                                loading={isPrefillLoading}>
                          {cloudPrefillData?.[selectedTemplate?.type] &&
                          getRouter(cloudPrefillData, selectedTemplate?.type).map((router) => (
                            <Option key={router.id} value={router.link || router.id}>
                              {router.name || router.prefixes?.[0]?.cidr || router.id}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    {selectedTemplate?.type === GOOGLE &&
                    <Col span={12} className={'wizard-col'}>
                      <Form.Item
                        name='altRouter'
                        className='modal-item-label'
                        label='Alternate Router'
                        rules={[{ required: true, message: 'Alternate Router is required' }]}
                        disabled={!selectedTemplate || isPrefillLoading} loading={isPrefillLoading}
                      >
                        <WrappedSelect className='modal-item-controller' disabled={!selectedTemplate || isPrefillLoading}
                                loading={isPrefillLoading}>
                          {cloudPrefillData?.[selectedTemplate?.type] &&
                          getRouter(cloudPrefillData, selectedTemplate?.type).map((router) => (
                            <Option key={router.id} value={router.link || router.id}>
                              {router.prefixes?.[0]?.cidr || router.id}
                            </Option>
                          ))}
                        </WrappedSelect>
                      </Form.Item>
                    </Col>
                    }
                  </>
            )}
          </Row>
        </Form>
      }
    </BaseWizard>
    <AddCredentialModal
      loading={isProfileLoading}
      visible={visible}
      activeCloud={activeCloud}
      filter={[AWS, GOOGLE, AZURE]}
      handleCreate={onCreateProfile}
      onCancel={onCloseProfile}
    />
    <AddCredentialModal
      loading={isProfileLoading}
      visible={visibleConnectModal}
      activeCloud={activeConnect}
      filter={[PCCW, MEGAPORT, EQUINIX]}
      handleCreate={onCreateProfile}
      onCancel={onCloseProfile}
    />
  </>
}

export default AddCloudWizard;
