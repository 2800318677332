const MIN_PORT = 1;
const MAX_PORT = 65535;

const portRangeValidator = () => ({
  validator(_, value) {
    // split the value
    const [startPort, endPort] = String(value).split('-');
    try {
      const numStartPort = Number.parseInt(startPort);
      const numEndPort = Number.parseInt(endPort);
      if (
        (startPort && Number.isInteger(numStartPort) && numStartPort >= MIN_PORT
          && endPort && Number.isInteger(numEndPort) && numEndPort <= MAX_PORT
          && numStartPort <= numEndPort
        ) ||
        (startPort && Number.isInteger(numStartPort) && numStartPort >= MIN_PORT && numStartPort <= MAX_PORT && !endPort)
      ) {
        return Promise.resolve();
      }
      if (
        startPort && Number.isInteger(numStartPort) && numStartPort >= MIN_PORT
        && endPort && Number.isInteger(numEndPort) && numEndPort <= MAX_PORT
        && numStartPort > numEndPort
      ) {
        return Promise.reject('START port needs to be equal or less than END port');
      }
      return Promise.reject('Port is a number or range between 1 and 65535');
    } catch {
      return Promise.reject('Port is a number or range between 1 and 65535');
    }
  },
})

export default portRangeValidator;