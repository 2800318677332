import { createSlice } from '@reduxjs/toolkit';
import { getCloudTemplates } from '../api';

const cloudTemplateInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const cloudTemplate = createSlice({
  name: 'cloudTemplate',
  initialState: cloudTemplateInitialState,
  reducers: {
    getCloudTemplateStart(state) {
      state.isLoading = true;
    },
    getCloudTemplateSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.cloudTemplates.data
    },
    getCloudTemplateFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const {
  getCloudTemplateStart,
  getCloudTemplateSuccess,
  getCloudTemplateFailure
} = cloudTemplate.actions;

export default cloudTemplate.reducer;

export const fetchCloudTemplates = (target) => async (dispatch) => {
  try {
    dispatch(getCloudTemplateStart());
    const cloudTemplates = await getCloudTemplates(target);
    dispatch(getCloudTemplateSuccess({ cloudTemplates }));
  } catch (err) {
    dispatch(getCloudTemplateFailure(err.toString()));
  }
};
