import Overview from './views/overview';
import Devices from './views/devices';
import Security from './views/security';
import Empty from './views/empty';
import Switches from './views/switches';
import Cloud from './views/cloud';
import Nat from './views/nat';
import ClusterDetails from './views/clusterDetails';
import CloudDetails from './views/cloudDetails';
import Clusters from './views/clusters';
//import AdminCloudTemplates from './views/cloudTemplates';
import AddCloudTemplateWizard from './components/wizards/cloud-templates/addCloudTemplate';
//import Credentials from './views/credentials';
import AddCloudWizard from './components/wizards/cloud/addCloud';
import AddNATWizard from './components/wizards/nat/addNat';
import AddACLWizard from './components/wizards/acl/addACL';
import Subnets from './views/subnets';
import Onboarding from './views/onboarding';
import OnboardingSignup from './views/onboarding/signup';
import OnboardingOverview from './views/onboarding/overview';
import OnboardingCluster from './views/onboarding/cluster';
import OnboardingCredentials from './views/onboarding/credentials';
import Complete from './views/onboarding/complete';
import Circuits from './views/circuits';
import CircuitWizard from './components/wizards/circuits';
import VMDetails from './views/VMDetails';
import VMs from './views/vms';
import VLANs from './views/vlans';
import BgpPeers from './views/bgpPeers';
import AddMetalEnvironmentWizard from './components/wizards/environment/addMetalEnvironment';
import AddMetalNodesWizard from './components/wizards/environment/addMetalNodes';
import Kubernetes from './views/kubernetes';
import KubernetesDetails from './views/kubernetesDetails';
import DataVolumes from './views/dataVolumes';
import KubeApplications from './views/kubeApplications';

const dashboardRoutes = [
  {
    name: 'MAIN MENU'
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    icon: 'rise',
    component: Onboarding,
    layout: '/admin',
    menuHidden: true,
    hideMenuItems: true,
    exact: true,
  },
  {
    path: '/onboarding/signup',
    name: 'Onboarding - Signup',
    icon: 'rise',
    component: OnboardingSignup,
    layout: '/admin',
    menuHidden: true,
    hideMenuItems: true,
    exact: true,
  },
  {
    path: '/onboarding/overview',
    name: 'Onboarding - Overview',
    icon: 'rise',
    component: OnboardingOverview,
    layout: '/admin',
    menuHidden: true,
    hideMenuItems: true,
    exact: true,
  },
  {
    path: '/onboarding/cluster',
    name: 'Onboarding - Cluster',
    icon: 'rise',
    component: OnboardingCluster,
    layout: '/admin',
    menuHidden: true,
    hideMenuItems: true,
    exact: true,
  },
  {
    path: '/onboarding/credentials',
    name: 'Onboarding - Credentials',
    icon: 'rise',
    component: OnboardingCredentials,
    layout: '/admin',
    menuHidden: true,
    hideMenuItems: true,
    exact: true,
  },
  {
    path: '/onboarding/metalEnvironment',
    name: 'Onboarding - Create Metal Environment',
    icon: '',
    component: AddMetalEnvironmentWizard,
    layout: '/admin',
    menuHidden: true,
    hideMenuItems: true,
    exact: true,
  },
  {
    path: '/onboarding/complete',
    name: 'Onboarding - Complete',
    icon: '',
    component: Complete,
    layout: '/admin',
    menuHidden: true,
    hideMenuItems: true,
    exact: true,
  },
  {
    path: '/overview',
    name: 'Dashboard',
    icon: 'rise',
    component: Overview,
    layout: '/admin'
  },
  {
    path: '/clusters',
    name: 'Environments',
    icon: 'cloud-sync',
    component: Clusters,
    layout: '/admin',
    exact: true
  },
  {
    path: '/cluster/:clusterUuid/nodes',
    name: 'Add Nodes',
    icon: 'cloud-sync',
    component: AddMetalNodesWizard,
    layout: '/admin',
    menuHidden: true,
    hideMenuItems: true,
    exact: true
  },
  {
    path: '/kubernetes',
    name: 'Kubernetes',
    icon: 'rise',
    component: Kubernetes,
    layout: '/admin',
    exact: true,
    children: [
      {
        path: '/kubernetes/addons',
        name: 'Add-ons',
        icon: '',
        component: KubeApplications,
        layout: '/admin'
      },
    ],
  },
  {
    path: '/kubernetes/:name',
    name: 'Nested Cluster Details',
    icon: 'cloud-sync',
    component: KubernetesDetails,
    layout: '/admin',
    menuHidden: true,
  },
  {
    path: '/vms',
    name: 'VMs',
    icon: 'laptop',
    component: VMs,
    layout: '/admin',
    exact: true,
    children: [
      {
        path: '/vms/dataVolumes',
        name: 'DataVolumes',
        icon: '',
        component: DataVolumes,
        layout: '/admin'
      },
    ],
  },
  {
    path: '/clusters/:clusterUuid',
    name: 'Cluster Details',
    icon: 'cloud-sync',
    component: ClusterDetails,
    layout: '/admin',
    menuHidden: true,
  },
  {
    path: '/network/cloud/wizard',
    name: 'Cloud - Wizard',
    icon: '',
    component: AddCloudWizard,
    layout: '/admin',
    menuHidden: true,
    exact: true,
    capability: 'netris',
  },
  {
    path: '/network/cloud/:cloudId',
    name: 'Cloud Details',
    icon: '',
    component: CloudDetails,
    layout: '/admin',
    menuHidden: true,
    capability: 'netris',
  },
  {
    path: '/network/bgppeers',
    name: 'Network',
    icon: 'ClusterOutlined',
    component: Empty,
    layout: '/admin',
    children: [
      {
        path: '/network/subnets',
        name: 'Subnets',
        icon: '',
        component: Subnets,
        layout: '/admin',
        capability: 'netris',
      },
      {
        path: '/network/circuits',
        name: 'Circuits',
        icon: '',
        component: Circuits,
        layout: '/admin',
        exact: true,
        capability: 'netris-vxlan',
      },
      {
        path: '/network/bgppeers',
        name: 'BGP Peers',
        icon: '',
        component: BgpPeers,
        layout: '/admin',
        exact: true,
      },
      {
        path: '/network/vlans',
        name: 'VLANs',
        icon: '',
        component: VLANs,
        layout: '/admin',
        exact: true,
        //capability: 'netris-vlan', 
      },
      {
        path: '/devices',
        name: 'Devices',
        icon: 'laptop',
        component: Devices,
        layout: '/admin',
        capability: 'netris',
      },
      {
        path: '/network/cloud',
        name: 'Cloud',
        icon: '',
        component: Cloud,
        exact: true,
        layout: '/admin',
        capability: 'netris',
      },
      {
        path: '/network/switches',
        name: 'Switches',
        icon: '',
        component: Switches,
        layout: '/admin',
        capability: 'netris',
      },
      {
        path: '/network/nat',
        name: 'NAT',
        icon: '',
        component: Nat,
        layout: '/admin',
        exact: true,
        capability: 'netris-nat',
      },
      {
        path: '/network/security',
        name: 'Security',
        icon: 'security-scan',
        component: Security,
        layout: '/admin',
        exact: true,
        capability: 'netris-acl',
      }
    ]
  },
  {
    path: '/network/security/wizard/:wizardId',
    name: 'ACL - Wizard',
    icon: '',
    component: AddACLWizard,
    layout: '/admin',
    menuHidden: true,
    exact: true,
    capability: 'netris',
  },
  {
    path: '/network/security/wizard',
    name: 'ACL - Wizard',
    icon: '',
    component: AddACLWizard,
    layout: '/admin',
    menuHidden: true,
    exact: true,
    capability: 'netris',
  },
  {
    path: '/settings/cloud-templates/wizard/:wizardId',
    name: 'Cloud Templates - Wizard',
    icon: '',
    component: AddCloudTemplateWizard,
    layout: '/admin',
    menuHidden: true,
    exact: true,
  },
  {
    path: '/settings/cloud-templates/wizard',
    name: 'Cloud Templates - Wizard',
    icon: '',
    component: AddCloudTemplateWizard,
    layout: '/admin',
    menuHidden: true,
  },
  {
    path: '/network/circuits/wizard/:wizardId',
    name: 'Circuit - Wizard',
    icon: '',
    component: CircuitWizard,
    layout: '/admin',
    menuHidden: true,
    exact: true,
    capability: 'netris',
  },
  {
    path: '/network/circuits/wizard',
    name: 'Circuit - Wizard',
    icon: '',
    component: CircuitWizard,
    layout: '/admin',
    menuHidden: true,
    capability: 'netris',
  },
  {
    path: '/network/nat/wizard/:wizardId',
    name: 'NAT - Wizard',
    icon: '',
    component: AddNATWizard,
    layout: '/admin',
    menuHidden: true,
    exact: true,
    capability: 'netris',
  },
  {
    path: '/network/nat/wizard',
    name: 'NAT - Wizard',
    icon: '',
    component: AddNATWizard,
    layout: '/admin',
    menuHidden: true,
    capability: 'netris',
  },
  // {
  //   path: '/settings/cloud-templates',
  //   name: 'Settings',
  //   icon: 'hdd',
  //   component: Empty,
  //   layout: '/admin',
  //   children: [
  //     {
  //       path: '/settings/cloud-templates',
  //       name: 'Cloud Templates',
  //       icon: '',
  //       component: AdminCloudTemplates,
  //       layout: '/admin',
  //       exact: true,
  //     },
  //     {
  //       path: '/settings/credentials',
  //       name: 'Credentials',
  //       icon: '',
  //       component: Credentials,
  //       layout: '/admin'
  //     },
  //   ]
  // },
  {
    path: '/vms/:name',
    name: 'Virtual Machine Details',
    icon: 'cloud-sync',
    component: VMDetails,
    layout: '/admin',
    menuHidden: true,
  },
];

export default dashboardRoutes;
