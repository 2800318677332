import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'antd/lib/button/button';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import ShowStatus from '../../components/showstatus';
import { fetchClusters } from '../../reducers/clusterSlice';

import './index.scss';

import { useHistory } from 'react-router';
import { fetchNodes } from '../../reducers/nodesSlice';
import { ipSorter, stringSorter } from '../../utils';

const Clusters = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.clusters);
  const history = useHistory();

  const handleClusterInfo = clusterUuid => (evt) => {
    evt.preventDefault();
    history.push(`/clusters/${clusterUuid}`);
  }
  
  const clustersColumns = [
    {
      title: 'NAME',
      dataIndex: 'clusterName',
      width: 150,
      sorter: stringSorter('clusterName')
    },
    {
      title: 'NODES',
      dataIndex: 'clusterUuid',
      width: 100,
      render: (_, record, index) => (
        record.nodes.length || 'Unknown'
      )
    },
    {
      title: 'MANAGEMENT IP',
      dataIndex: 'managementIp',
      width: 200,
      sorter: ipSorter('managementIp'),
    },
    {
      title: 'CLUSTER STATE',
      dataIndex: 'clusterState',
      width: 200,
      render: (_, record) => <ShowStatus status={record?.clusterState || undefined} />
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      location: 200,
      sorter: stringSorter('location')
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 160,
      className: 'action-column',
      render: (_, record, index) => {
        return <Button onClick={handleClusterInfo(record.clusterUuid)}>Environment Info</Button>;
      }
    }
  ];

  useEffect(() => {
    function getClusters() {
      dispatch(fetchClusters);
    }
    getClusters();
    const interval = setInterval(() => getClusters(), 10 * 1000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    data && data?.forEach(cluster => {
      dispatch(fetchNodes(cluster.clusterUuid));
    });
  }, [dispatch, data]);

  const openWizard = () => {
    history.push('/onboarding/metalEnvironment')
  };

  return (
    <div>
      <Header
        type='button'
        buttonName={data.length ? 'Claim your Environment' : 'Claim your first Environment'}
        items={data}
        title={{
          singular: 'Environment',
          plural: 'Environments'
        }}
        loading={true}
        openModal={openWizard}
        description='See your device clusters running FLUID in your data center or at your edge'
      />
      <BaseTable
        columns={clustersColumns}
        data={data}
        loading={isLoading}
        error={error}
        rowKey={(record) => record.dataValues?.id}
        id={'id'}
        emptyRenderText={'No existing environments'}
      />
    </div>
  );
};

export default Clusters;
