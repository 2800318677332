import React from 'react';
import { useHistory } from 'react-router';
import useForm from 'antd/lib/form/hooks/useForm';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { useSelector } from 'react-redux';

import '../index.scss';

import step2Image from '../../../assets/step_2_instructions.png';

const OnboardingCluster = () => {
  const [form] = useForm();
  const history = useHistory();

  const config = useSelector(state => state.config);

  const handleClick = () => {
    console.log('clean up onboarding stuff');
  }

  const handleSkip = () => {
    history.push('/overview');
  }

  const initialValues = {
    workspaceId: config.workspaceId
  }

  return <div className='onboarding-cluster-container'>
    <Form form={form} initialValues={initialValues} layout='vertical' requiredMark={'optional'}>
      <Row gutter={24}>
        <Col span={24} className={'modal-col'}>
          <div className={'step-1'}>
            <h1>Step 1</h1>
            <div className='info-box'>onie-nos-install http://storgrid-s3-dev.aseit.com.au:8084/ase-fluid/images/fluid-onie-master-installer-x86_64-latest.bin</div>
          </div>
        </Col>
        <Col span={24} className={'modal-col'}>
          <div>
            <Row gutter={24}>
              <Col span={24} className={'modal-col'}>
                <div className={'step-2'}>
                  <h1>Step 2</h1>
                  <div className='info-box'><img src={step2Image} alt={'Instructions'} /></div>
                </div>
              </Col>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='name'
                  className='modal-item-label'
                  label='Cluster Name'
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input className='modal-item-controller' placeholder='Name' />
                </Form.Item>
              </Col>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='clusterAdoptionToken'
                  className='modal-item-label'
                  label='Cluster Adoption Token'
                  rules={[{ required: true, message: 'Cluster Adoption Token is required' }]}
                >
                  <Input className='modal-item-controller' placeholder='cat-mouse-dog-0a1b2c' />
                </Form.Item>
              </Col>
              <Col span={12} className={'modal-col'}>
                <Form.Item
                  name='location'
                  className='modal-item-label'
                  label='Location'
                  rules={[{ required: true, message: 'Location is required' }]}
                >
                  <Input className='modal-item-controller' placeholder='Sydney' />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={24} className={'modal-col'}>
          <div>
            <h1>Step 3</h1>
            <div className='info-box' />
          </div>
        </Col>
        <Col span={24} className={'modal-col button-container'}>
          <Button type='secondary' onClick={handleSkip} className='skip-btn'>{'Skip'}</Button>
          <Button type='primary' onClick={handleClick} className='wizard-btn'>{'Next'}</Button>
        </Col>
      </Row>
    </Form>
  </div>;
}

export default OnboardingCluster;