import { createSlice } from '@reduxjs/toolkit';
import { getCircuits } from '../api';

const circuitsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const circuits = createSlice({
  name: 'circuits',
  initialState: circuitsInitialState,
  reducers: {
    getCircuitsStart(state) {
      state.isLoading = true;
    },
    getCircuitsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((circuit) => {
          circuit['key'] = circuit.id;
          return circuit;
        });
      } else {
        state.data = [];
      }
    },
    getCircuitsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getCircuitsStart, getCircuitsSuccess, getCircuitsFailure } = circuits.actions;

export default circuits.reducer;

export const fetchCircuits = target => async (dispatch) => {
  try {
    dispatch(getCircuitsStart());
    const circuits = await getCircuits(target);
    dispatch(getCircuitsSuccess(circuits));
  } catch (err) {
    dispatch(getCircuitsFailure(err.toString()));
  }
};
