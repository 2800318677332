import React from 'react';
import Ports from './ports';
import '../index.scss';

const ExpandedSwitches = (record, index, indent, expanded) => {
  return (
    expanded && (
      <div className='ports-container'>
        <h5 className='port-status-title'>PORT STATUS</h5>
        <Ports ip={record.ip_address} />
      </div>
    )
  );
};

export default ExpandedSwitches;
