import React from 'react';
import { Row, Col } from 'antd';
import { getStatusClass } from '../../utils';

import './status.scss';

const transformStatus = status => {
  if (!status) return;
  switch (String(status)) {
    case 'ok':
    case 'healthy':
      return 'Healthy';
    case 'unhealthy':
      return 'Unhealthy';
    case 'provisioning':
      return 'Provisioning';
    case 'active':
    case 'up':
    case 'connected':
      return 'Connected';
    case 'enabled':
      return 'Enabled';
    case 'disabled':
      return 'Disabled';
    case 'upgrading':
      return 'Upgrading';
    case 'standby':
      return 'Standby';
    case 'disable':
    case 'offline':
    case 'down':
    case 'disconnected':
    case 'fail':
      return 'Disconnected';
    case 'pending':
      return 'Pending';
    case 'deleting':
      return 'Deleting';
    case 'ready':
      return 'Ready';
    case 'sshonly':
      return 'SSH Only';
    case 'netonly':
      return 'Net Only'; 
    case 'nonodes':
      return 'No Nodes'
    case 'notready':
      return 'Not Ready'
    default:
      return undefined;
  }
}

const ShowStatus = ({ status, style }) => {
  const parsed = String(status + "").toLowerCase().trim();
  const transformedStatus = transformStatus(parsed);
  return (
    <Row align='middle' style={style}>
      <Col className={getStatusClass(transformedStatus)} />
      <Col className='status-body'>
        {!transformedStatus ? 'Unavailable' : transformedStatus}
      </Col>
    </Row>
  );
};

export default ShowStatus;
