import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import ShowStatus from '../../components/showstatus';
import { fetchClouds } from '../../reducers/cloudSlice';
import { fetchCloudTemplates } from '../../reducers/cloudTemplateSlice';
import Button from 'antd/lib/button/button';
import { useHistory } from 'react-router';
import { AWSTableLogo, AzureTableLogo, GoogleTableLogo, } from '../../components/logo';
import { PROVIDERS } from '../../components/constants';
import ExpandedClouds from './expandedClouds';
import { stringSorter } from '../../utils';

const { AWS, AZURE, GOOGLE, } = PROVIDERS;

const Cloud = () => {
  const { clouds, target } = useSelector((state) => {
    const { clouds, clusters } = state;
    return { clouds, target: clusters.target };
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCloudInfo = cloudId => (evt) => {
    evt.preventDefault();
    history.push(`/network/cloud/${cloudId}`);
  }

  const cloudColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: 150,
      sorter: stringSorter('name')
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: 200,
      sorter: stringSorter('description')
    },
    {
      title: 'SPEED',
      dataIndex: 'speed',
      width: 120,
      sorter: (a, b) => a.speed > b.speed,
      render: (speed) => <span>{speed ? `${speed} Mbit/s` : 'Unavailable'}</span>
    },
    {
      title: 'CLOUD',
      dataIndex: 'type',
      sorter: stringSorter('type', {
        transform: (text) => {
          if (text === AWS) {
            return 'Amazon Web Services'
          }
          if (text === GOOGLE) {
            return 'Google Cloud'
          }
          if (text === AZURE) {
            return 'Microsoft Azure'
          }
          return text;
        }
      }),
      render: (text) => {
        if (text === AWS) {
          return <div className={'provider-container'}><AWSTableLogo style={{
            top: 2,
            position: 'relative',
          }} />Amazon Web Services</div>
        }
        if (text === GOOGLE) {
          return <div className={'provider-container'}><GoogleTableLogo />Google Cloud</div>
        }
        if (text === AZURE) {
          return <div className={'provider-container'}><AzureTableLogo />Microsoft Azure</div>
        }
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      sorter: stringSorter('status'),
      render: (status, record) => <ShowStatus status={record.state ? record.isDeleting ? 'Deleting' : 'Pending' : status} />
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      className: 'action-column',
      width: 20,
      render: (_, record) => {
        return record.status === 'ACTIVE' ? <Button onClick={handleCloudInfo(record.id)}>Cloud Info</Button> : <></>;
      }
    }
  ];

  const handleUpdate = () => {
    target && dispatch(fetchClouds(target));
  };

  useEffect(() => {
    target && dispatch(fetchClouds(target));
    target && dispatch(fetchCloudTemplates(target));

    const getClouds = () => {
      target && dispatch(fetchClouds(target));
    }

    const interval = setInterval(() => getClouds(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);

  const openWizard = () => {
    history.push('/network/cloud/wizard');
  };

  return (
    <div>
      <Header
        type='button'
        buttonName='Add New Cloud'
        items={clouds.data}
        title={{
          plural: 'Cloud Circuits',
          singular: 'Cloud Circuit'
        }}
        openModal={openWizard}
        handleUpdate={handleUpdate}
        loading={clouds.isLoading}
        description='View connections to public clouds'
        onRefresh={handleUpdate}
      />
      <BaseTable
        columns={cloudColumns}
        data={clouds.data}
        loading={clouds.isLoading}
        error={clouds.error}
        rowKey={'key'}
        expandedRowRender={ExpandedClouds}
        rowExpandable={(record) => {
          return !!record.state;
        }}
      />
    </div>
  );
};

export default Cloud;
