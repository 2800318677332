import { createSlice } from '@reduxjs/toolkit';
import { getBgpPeers } from '../api';

const bgpPeersInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const bgpPeers = createSlice({
  name: 'bgpPeers',
  initialState: bgpPeersInitialState,
  reducers: {
    getBgpPeersStart(state) {
      state.isLoading = true;
    },
    getBgpPeersSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data;
      } else {
        state.data = [];
      }
    },
    getBgpPeersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getBgpPeersStart, getBgpPeersSuccess, getBgpPeersFailure } = bgpPeers.actions;

export default bgpPeers.reducer;

export const fetchBgpPeers = target => async (dispatch) => {
  try {
    dispatch(getBgpPeersStart());
    const bgpPeers = await getBgpPeers(target);
    dispatch(getBgpPeersSuccess(bgpPeers));
  } catch (err) {
    dispatch(getBgpPeersFailure(err.toString()));
  }
};
