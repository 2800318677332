import { createSlice } from '@reduxjs/toolkit';
import { getNodes } from '../api';

const nodesInitialState = {
  nodes: {},
  lastUpdated: new Date().getTime(),
  isLoading: false,
  error: null
};

const nodes = createSlice({
  name: 'nodes',
  initialState: nodesInitialState,
  reducers: {
    getNodesStart(state) {
      state.isLoading = true;
    },
    getNodesSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.nodes = {
        ...state.nodes,
        [payload.target]: payload.nodes?.data
      };
      state.lastUpdated = new Date().getTime();
    },
    getNodesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getNodesStart, getNodesSuccess, getNodesFailure } = nodes.actions;

export default nodes.reducer;

export const fetchNodes = (target) => async (dispatch) => {
  try {
    dispatch(getNodesStart());
    const nodes = await getNodes(target);
    dispatch(getNodesSuccess({ target, nodes }));
  } catch (err) {
    dispatch(getNodesFailure(err.toString()));
  }
};
