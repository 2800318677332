import BaseWizard from '../baseWizard';
import { Button, Col, Form, Input, Row, Select, Tag } from 'antd';
import useForm from 'antd/lib/form/hooks/useForm';
import { useHistory, useParams } from 'react-router';
import React, { useState, useMemo } from 'react';
import { mergeObjects } from '../../../utils';
import notification from '../../../utils/notification';
import { useDispatch } from 'react-redux';
import { fetchClusters } from '../../../reducers/clusterSlice';
import NodeTable from './nodeTable/nodeTable';
import { validateNodePairingKey, getSupportedNodeVersions, createMetalCluster } from '../../../api';
import '../index.scss';
import './addEnvironment.scss';

const { TextArea } = Input;
const { Option } = Select;

const AddMetalEnvironmentWizard = () => {
  const [isAdding, toggleIsAdding] = useState(false);
  const [currentData, setCurrentData] = useState({});
  //const [loading] = useState(false);
  const [form] = useForm();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [nodeData, setNodeData] = useState([]);
  const [nodeVersions, setNodeVersions] = useState({});
  const [createData, setCreateData] = useState({});
  const [pairingKeyStatus, setPairingKeyStatus] = useState([]);

  const wizardSteps = useMemo(() => [
      {
        title: 'Get Ready',
        name: 'Get Ready',
        description: '',
        validation: () => {
          return form.validateFields().then(() => {
            return true;
          }).catch(() => false);
        },
      },
      {
        title: 'Connect',
        name: 'Connect',
        description: '',
        validation: () => {
            return form.validateFields().then(() => {
            return true;
            }).catch(() => false);
        },
      },
      {
        title: 'Review',
        name: 'Review',
        description: '',
        validation: () => {
            return form.validateFields().then(() => {
            return true;
            }).catch(() => false);
        },
      }
    ], [form]);

    const onCancelWizard = () => {
        history.push('/clusters');
    }

    const onStepChange = () => {
      // We do this so we can expand on the field values since form resets the field value for each step
      setCurrentData(mergeObjects(currentData, form.getFieldsValue()));
    }

    const onComplete = async () => {
      toggleIsAdding(true);
      let reqData = {};
      if (Object.keys(createData).length === 0) {
        reqData = {
          nodes: [],
          genesisNode: "",
          ...currentData
        };
      } else {
        reqData = {
          ...createData,
          ...currentData
        };
      }
      delete reqData.pairingKeys;
      try {
        await createMetalCluster(reqData);
        toggleIsAdding(false);
        dispatch(fetchClusters);
        notification.success({
          message: 'New Environment has been added',
          placement: 'bottomRight'
        });
        history.push('/clusters');
      } catch (e) {
        if (e.response?.data) {
          notification.error({
            message: e.response?.data,
            placement: 'bottomRight'
          });
        } else {
          notification.error({
            message: 'Something went wrong, please try again later',
            placement: 'bottomRight'
          });
        }
        toggleIsAdding(false);
      }
    }

    const validatePairingKeys = async () => {
      setPairingKeyStatus([]);
      const pairingKeys = form.getFieldValue('pairingKeys').split(',').map((pairingKey) => {
        return pairingKey.trim();
      })

      const pairingKeyStatuses = []
      for await (const pairingKey of pairingKeys) {
        const valid = await validateNodePairingKey(pairingKey);
        pairingKeyStatuses.push({ pairingKey, valid: valid?.data?.isValid }); //valid: valid?.data?.isValid
      }
      setPairingKeyStatus(pairingKeyStatuses);

      const validPairingKeys = pairingKeyStatuses.filter((pairingKey) => pairingKey.valid === true).map((validPairingKey) => {
        return {
          pairingKey: validPairingKey.pairingKey
        }
      })

      const nodeVersionData = await getSupportedNodeVersions();
      setNodeVersions(nodeVersionData);
      const defaultVersions = {
        osVersion: nodeVersionData?.os?.find((osVersion) => osVersion?.isDefault).value,
        kernelVersion: nodeVersionData?.kernel?.find((kernelVersion) => kernelVersion?.isDefault).value,
        kubeVersion: nodeVersionData?.kube?.find((kubeVersion) => kubeVersion?.isDefault).value,
        role: 'MASTER'
      }
      setNodeData(validPairingKeys.map((node => {
        Object.assign(node, defaultVersions);
        return node;
      })));
    }

    const createMetalData = (createNodesData) => {
      setCreateData(createNodesData);
    }

    return (
    <div className='addEnv-container'>
    <BaseWizard
      completeText={params?.wizardId ? 'Update' : 'Create'}
      name= 'Create Environment'
      stepName={'Environment Creation'}
      onComplete={onComplete}
      onCancelWizard={onCancelWizard}
      steps={wizardSteps} 
      loading={isAdding}
      onStepChange={onStepChange}
    >
      {(currentStep, setCurrentStep) =>
        <Form form={form} layout='vertical' requiredMark={'optional'}>
          <Row gutter={24}>
            {currentStep === 0 && (
            <>
              <Col span={24} className={'info-col'}>
                <p>
                  To build your Environment you need:
                </p>
                <ul>
                  <li>
                    Access to an iPXE compatible server environment, either in your own infrastructure or in a hosted environment such as Equinix Metal. 
                  </li>
                  <li>
                    Cookies.
                  </li>
                </ul>
                <p>
                  Read our step by step guide: 
                  <a href='https://fluidhq.io' target='_blank' rel='noopener noreferrer'> https://fluidhq.io</a>
                </p>
              </Col>
            </>
            )}
            {currentStep === 1 && (
              <>
                <Col span={12} className={'info-col'}>
                  <Form.Item 
                    required={true} 
                    label='Cluster Name' 
                    name='clusterName'
                    rules={[{ required: true, message: 'Name is required' }]}
                  >
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={12} className={'info-col'}>
                  <Form.Item required={true} label='Node Count' name='expectedSize' >
                    <Select defaultValue={128}>
                      <Option value={128}>{'<128'}</Option>
                      <Option value={'128'}>128</Option>
                      <Option value={256}>256</Option>
                      <Option value={512}>512</Option>
                      <Option value={1024}>1024</Option>
                      <Option value={2048}>2048</Option>
                      <Option value={4096}>4096</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} className={'info-col'}>
                  <Form.Item required={false} label='Pairing Keys' name='pairingKeys' className='pairingTextArea'>
                    <TextArea></TextArea>
                  </Form.Item>

                  <Form.Item>
                    <Button type='primary' onClick={validatePairingKeys}>Validate</Button>
                  </Form.Item>
                </Col>
                <Col span={12} className={'info-col'}>
                  Pairing Key Validity
                  <br/>
                  {pairingKeyStatus.length > 0 && <>
                    { pairingKeyStatus.map((pairingKeyStatus) => {
                      return <Tag color={pairingKeyStatus.valid === true ? 'green' : 'red'}>{pairingKeyStatus.pairingKey}</Tag>
                    })
                    }
                  </>}
                </Col>
                <Col span={24} className={'info-col'}>
                  { nodeData.length > 0 && 
                    <NodeTable
                      data={nodeData}
                      versions={nodeVersions}
                      createMetalData={createMetalData}
                    />
                  }

                </Col>
              </>
            )}
            {currentStep === 2 && (
            <>
              <Col span={24} className={'info-col'}>
                <p>
                  Review your Environment details below!
                </p>
                <p className='env-details'>
                  <code>
                    Name: {currentData.clusterName}
                    <br/>
                    Expected Node Count: {currentData.expectedSize || '<128'}
                    <br/>
                    Pairing Keys: {nodeData.length}
                    <br/>
                    {
                      nodeData.map((data) => {
                        return <>{'--> '}{data.pairingKey}<br/></>
                      })
                    }
                  </code>
                </p>
                <p>
                  Click Create to start deploying workloads and breaking off-prem infrastructure forever!
                </p>
              </Col>
            </>
            )}
          </Row>
        </Form>
      }
    </BaseWizard>
  </div>
)}

export default AddMetalEnvironmentWizard;