import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input';
import Slider from 'antd/lib/slider';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import nameValidator from '../validators/nameValidator'
import ConfirmModal from './confirmModal';

import { fetchDataVolumes } from '../../reducers/vmsSlice';
import './modal.scss';
import { fetchCircuits } from '../../reducers/circuitSlice';
import { fetchVlans } from '../../reducers/vlansSlice';

const { Option } = Select;
const { TextArea } = Input;

function cpuFormatter(value) {
  return `${value}`;
}

function ramFormatter(value) {
  return `${value}Mi`;
}

function hddFormatter(value) {
  return `${value}Gi`;
}

const AddVMModal = ({ visible, loading, handleComplete, onCancel, namespaces, target, capacityLimits, data }) => {
  const [form] = Form.useForm();
  const [selectedNamespace, setSelectedNamespace] = useState(null);
  const { dataVolumes } = useSelector((state) => state.vms);
  const { data: capabilities } = useSelector((state) => state.capability);
  const { data: circuits } = useSelector(state => state.circuits);
  const { data: vlans } = useSelector(state => state.vlans);
  const [cpu, setCPU] = useState(1);
  const [ram, setRAM] = useState(256);
  const [hdd, setHDD] = useState(8);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const initialValues = {
    namespace: data?.namespace || '',
    name: data?.name || '',
    cores: data?.cpu || 1,
    memory: data?.ram || 256,
    cdIsoPVC: data?.volumes ? data.volumes.find((volume) => volume.name === 'cdromiso')?.persistentVolumeClaim?.claimName : '',
    vlanAttachments: data?.vlanAttachments,
    vxlanAttachments: data?.vxlanAttachments,
    description: data?.description || ''
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataVolumes(target));
    dispatch(fetchCircuits(target));
    dispatch(fetchVlans(target));
  }, [dispatch, target, data]);

  useEffect(() => {
    setCPU(initialValues.cores);
    setRAM(initialValues.memory);
    setSelectedNamespace(initialValues.namespace);
  }, [initialValues.cores, initialValues.memory, initialValues.namespace]);

  useEffect(() => {
    form.setFieldsValue({
      cores: cpu,
      memory: ram,
      storage: hdd
    });
  }, [form, cpu, ram, hdd]);

  const onComplete = () => {
    handleComplete(form);
  }

  const onConfirm = () => {
    setIsConfirmModalVisible(false);
    onComplete();
  }

  const onCancelConfirm = () => {
    setIsConfirmModalVisible(false);
  }

  return (
    <Modal
      visible={visible}
      title='Create a Virtual Machine'
      onOk={handleComplete}
      onCancel={onCancel}
      width={640}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
            .validateFields()
            .then(() => {
              data ?
                setIsConfirmModalVisible(true)
              :
                onComplete()
            })
            .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          {data ? 'Update' : 'Create'}
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' requiredMark={'optional'} initialValues={initialValues}>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              validateTrigger='onBlur'
              hasFeedback
              rules={[{ required: true, message: 'Name is required' }, nameValidator(target)]}
            >
              <Input className='modal-item-controller' placeholder='Name' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='namespace'
              className='modal-item-label'
              label='Namespaces'
              rules={[{ required: true, message: 'Namespace is required' }]}
            >
              <Select disabled={data} placeholder="Namespaces" className='modal-item-controller' onChange={(e) => setSelectedNamespace(e)} >
                {namespaces.length > 0 && <>
                  {namespaces.map(el => (
                    <Option key={el.clusterId} value={el.clusterId}>{el.displayName}</Option>
                  ))}
                </>}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='cdIsoPVC'
              className='modal-item-label'
              label='ISO PVC'
              rules={[{ required: false }]}
            >
              <Select placeholder="ISO PVC" className='modal-item-controller'>
                <Option key={null} value={null}>None</Option>
                {dataVolumes.length > 0 && <>
                  {dataVolumes.filter(dataVolume => dataVolume.namespace === selectedNamespace).map(el => (
                    <Option key={el.name} value={el.name}>{el.name}</Option>
                  ))}
                </>}
              </Select>
            </Form.Item>
          </Col>
          { capabilities && capabilities["netris-vxlan"] !== false ?
            <Col span={24} className={'modal-col'}>
              <Form.Item
                name='vxlanAttachments'
                className='modal-item-label'
                label='Attach to Circuit'
                rules={[{ required: false }]}
              >
                <Select mode='multiple' disabled={circuits.length < 1} placeholder='circuit' className='modal-item-controller'>
                  {circuits.length > 0 && <>
                    {circuits.map(circuit => (
                      <Option key={circuit.name} value={circuit.id}>{circuit.name}</Option>
                    ))}
                  </>}
                </Select>
              </Form.Item>
            </Col>
            : <></> }
          { capabilities && capabilities["netris-vlan"] !== false ?
            <Col span={24} className={'modal-col'}>
            <Form.Item
              name='vlanAttachments'
              className='modal-item-label'
              label='Attach to VLAN'
              rules={[{ required: false }]}
            >
              <Select mode='multiple' disabled={vlans.length < 1} placeholder='VLAN' className='modal-item-controller'>
                {vlans.length > 0 && <>
                  {vlans.map(vlan => (
                    <Option key={vlan.name} value={vlan.vlanId}>{vlan.vlanId}: {vlan.name}</Option>
                  ))}
                </>}
              </Select>
            </Form.Item>
          </Col>
          : <></> }
        </Row>
        <Row gutter={24} align="bottom">
          <Col span={18}>
            <Form.Item
              name='cores'
              className='modal-item-label'
              label='CPU'
              rules={[{ required: true, message: 'CPU is required' }]}
            >
              <Slider 
                tipFormatter={cpuFormatter} 
                onChange={(value) => setCPU(value)}
                value={cpu}
                min={1}
                max={capacityLimits ? capacityLimits.maxCores : 1}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <InputNumber 
              className='modal-item-controller' 
              value={cpu} 
              onChange={(e) => setCPU(e.target.value)}
              suffix='vCPUs'
            />
          </Col>
        </Row>
        <Row gutter={24} align="bottom">
          <Col span={18}>
            <Form.Item
              name='memory'
              className='modal-item-label'
              label='RAM'
              rules={[{ required: true, message: 'RAM is required' }]}
            >
              <Slider 
                tipFormatter={ramFormatter}
                onChange={(value) => setRAM(value)}
                min={256}
                max={capacityLimits ? capacityLimits.maxMem * 0.80 : 256}
                step={256}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <InputNumber 
              className='modal-item-controller' 
              placeholder='RAM' 
              value={ram} 
              onChange={(e) => setRAM(e.target.value)} 
              suffix="Mi" />
          </Col>
        </Row>
        { !data &&
        <Row gutter={24} align="bottom">
          <Col span={18}>
            <Form.Item
              name='storage'
              className='modal-item-label'
              label='HDD'
              rules={[{ required: true, message: 'HDD is required' }]}
            >
              <Slider 
                tipFormatter={hddFormatter} 
                onChange={(value) => setHDD(value)} 
                min={8}
                max={2048}
                step={8}
                disabled={data}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <InputNumber 
              className='modal-item-controller' 
              placeholder='HDD' 
              value={hdd} 
              onChange={(e) => setHDD(e.target.value)} 
              suffix="Gi"
              disabled={data}
            />
          </Col>
        </Row>
        }
        <Row gutter={24} align="bottom">
          <Col span={24}>
            <Form.Item
              name='description'
              className='modal-item-label'
              label='Description'
            >
              <TextArea rows={4} placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ConfirmModal
        title={'VM Edit'}
        visible={isConfirmModalVisible}
        loading={false}
        message={'This action will cause the VM to restart. Confirm?'}
        onCancel={onCancelConfirm}
        onConfirm={onConfirm}
      />
    </Modal>
  );
};

export default AddVMModal;
