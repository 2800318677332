import React, { useEffect, useState, useRef } from "react";
import './index.scss';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import { ReactComponent as EfficiencyIcon } from '../../assets/efficiency.svg';
import { ReactComponent as SecureIcon } from '../../assets/secure.svg';
import { ReactComponent as SimplifyIcon } from '../../assets/simplify.svg';
import { ReactComponent as CollaborateIcon } from '../../assets/collaborate.svg';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';

import Screen from '../../assets/fluid_screen.png';

import './index.scss';

const Onboarding = () => {
  const history = useHistory();
  const { location = {} } = history;
  const { state = [] } = location;
  const [loadedParams = {}] = state;
  const { infoOnly, registered } = loadedParams;
  const [height, setHeight] = useState(100);
  const onboardingRef = useRef(null);

  useEffect(() => {
    setHeight(onboardingRef.current.clientHeight);
  }, [height])

  return (
    <div className='onboarding-container' ref={onboardingRef}>
      <div className={'background'} style={{height: height * 1.11}}></div>
      <Row gutter={12} className={'marketing-row'}>
        <Col span={12} >
          <a href='https://www.fluidhq.io/' target='_blank' rel='noopener noreferrer' className={'how-link'}>
            How does it work? <QuestionCircleOutlined/>
          </a>
          <h1>Fluid</h1>
          <p>
            Self-Assembling, Smart Infrastructure.
          </p>
          <p>
            <h2>What is it?</h2> An orchestration and management platform to simplify on-prem infrastructure deployment and 
            cloud interconnection all from Cloud Manager. 
          </p>
          <p>
            <h2>What does it do?</h2> Fluid automates the provisioning of cloud-scale networking and compute so you can focus 
            on deploying applications instead of deploying infrastructure, offering you the flexibility and ease of management access. 
          </p>
          <p>
            <h2>Why would I use it?</h2> Quick, easy and agile infrastructure. Fluid turns complex projects into the simple, easy task it should be. 
          </p>
          <p>
            Fluid simplifies the network complexities of connecting various public and private clouds together to create a true homogenous, 
            multi-cloud environment. There’s more... Fluid is a one-touch interface to deploy your Kubernetes hybrid environment, all while having integration to your 
            NetApp ecosystem with tools such as Spot and Trident to extend the data fabric. 
          </p>
        </Col>
        <Col span={12} className={'img-col'}>
          <img src={Screen} alt={'Presentation'} />
        </Col>
        <Col span={12}>
          <h2>
            Ready to try Fluid?
          </h2>
          <p>
            {registered ? 'Thanks for letting us know, we\'ll get back to you shortly!' : 'What are you waiting for?'}
          </p>
          <Button
            type='primary'
            disabled={registered}
            onClick={() => history.push('/onboarding/signup', state)}>
            {infoOnly ? 'I want to demo Fluid!' : 'Get Started!'}
          </Button>
        </Col>
        <Col span={12}>
          <p className={'quote'}>  
            “We’ve pulled out all the best configuration, 
            principles, and methodologies that cloud providers operate in and worked them into Fluid, so that it is simply a click of a button 
            for customers to deploy cloud-like architectures.” 
            <br/>
            – Andrew Sjoquist, Fluid Founder 
          </p>
        </Col>
      </Row>
      <br />
      <Row gutter={12} className={'feature-row'}>
        <Col span={6} className={'feature-col'}>
          <EfficiencyIcon className={'feature-icon'}/>
          <div>Simple on-prem, bare metal, infrastructure deployment and management</div>
        </Col>
        <Col span={6} className={'feature-col'}>
          <SecureIcon className={'feature-icon'}/>
          <div>Connect on-premises, public cloud and managed storage environments, from a single portal or API call within minutes </div>
        </Col>
        <Col span={6} className={'feature-col'}>
          <SimplifyIcon className={'feature-icon'}/>
          <div>
            Unify your global data fabric with a combination of cloud and on-prem storage, 
            with rapidly accessible and scalable compute on demand 
          </div>
        </Col>
        <Col span={6} className={'feature-col'}>
          <CollaborateIcon className={'feature-icon'}/>
          <div>
            Rapidly test, iterate and deploy with the ability to create virtual Kubernetes clusters in seconds, 
            on the edge; where you need them most
          </div>
        </Col>
      </Row>
    </div>

  );
};

export default Onboarding;
