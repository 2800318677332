import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useTracking = (
  trackingId = process.env.REACT_APP_GTAG_ID
) => {
  const { listen } = useHistory();
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return
      if (!trackingId) {
        console.log(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
        )
        return
      }

      if (!initialised) {
        window.gtag('config', trackingId);
        setInitialised(true);
      } else {
        window.gtag('event', 'page_view', { page_path: location.pathname })
      }
    });

    return unlisten
  }, [initialised, trackingId, listen])
}