import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import ShowStatus from '../../components/showstatus';
import { fetchNetworks } from '../../reducers/networkSlice';
import { ipSorter, stringSorter } from '../../utils';

const devicesColumns = [
  {
    title: 'NAME',
    dataIndex: 'switch_name',
    sorter: stringSorter('switch_name')
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    sorter: stringSorter('type')
  },
  {
    title: 'LOCATION',
    dataIndex: 'site_name',
    sorter: stringSorter('site_name')
  },
  {
    title: 'MANAGEMENT IP',
    dataIndex: 'mng_ip_address',
    sorter: ipSorter('mng_ip_address')
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    sorter: stringSorter('status'),
    render: (status) => <ShowStatus status={status} />
  }
];

const Devices = () => {
  const dispatch = useDispatch();
  const { data = [], isLoading, error } = useSelector((state) => state.networks);
  const target = useSelector(state => state.clusters?.target);

  useEffect(() => {
    function getNetworks() {
      dispatch(fetchNetworks(target));
    }
    getNetworks();
    const interval = setInterval(() => getNetworks(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);

  const handleUpdate = () => {
    dispatch(fetchNetworks(target));
  };

  return (
    <div>
      <Header
        items={data}
        title={{
          plural: 'Devices',
          singular: 'Device'
        }}
        loading={isLoading}
        onUpdate={handleUpdate}
        onRefresh={handleUpdate}
        description='See all your physical devices running FLUID in your data center or at your edge'
      />
      <BaseTable
        columns={devicesColumns}
        data={data}
        loading={isLoading}
        error={error}
        rowKey={'id'}
      />
    </div>
  );
};

export default Devices;
