import { createSlice } from '@reduxjs/toolkit';
import { getCloudCredential } from '../api';

const cloudCredentialInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const cloudCredential = createSlice({
  name: 'cloudCredential',
  initialState: cloudCredentialInitialState,
  reducers: {
    getCloudCredentialStart(state) {
      state.isLoading = true;
    },
    getCloudCredentialSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = (payload.cloudCredential?.data || []).reduce((acc, profile) => {
        const { type } = profile;
        const existingProfiles = acc[type] || [];
        return {
          ...acc,
          [type]: existingProfiles.concat(profile)
        };
      }, {});
    },
    getCloudCredentialFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const {
  getCloudCredentialStart,
  getCloudCredentialSuccess,
  getCloudCredentialFailure
} = cloudCredential.actions;

export default cloudCredential.reducer;

export const fetchCloudCredentials = (target) => async (dispatch) => {
  try {
    dispatch(getCloudCredentialStart());
    const cloudCredential = await getCloudCredential(target).catch(() => []);
    dispatch(getCloudCredentialSuccess({ cloudCredential }));
  } catch (err) {
    dispatch(getCloudCredentialFailure(err.toString()));
  }
};
