import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Upload from 'antd/lib/upload';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import './modal.scss';
import { googleRegions, awsRegions, PROVIDERS } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPCCWCompanies, resetPCCWState } from '../../reducers/pccwSlice';
import WrappedSelect from '../select';

const { Option } = Select;
const { Dragger } = Upload;
const { AWS, GOOGLE, AZURE, PCCW, MEGAPORT } = PROVIDERS;

const profileOptions = [
  {
    type: AWS,
    name: 'Amazon Web Services'
  },
  {
    type: AZURE,
    name: 'Microsoft Azure'
  },
  {
    type: GOOGLE,
    name: 'Google Cloud'
  },
  {
    type: PCCW,
    name: 'PCCW'
  },
  {
    type: MEGAPORT,
    name: 'Megaport'
  }
];

const AddCredentialModal = ({ visible, loading, filter, activeCloud, handleCreate, onCancel }) => {
  const [selectedProvider, setSelectedProvider] = useState(AWS);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { target, pccw } = useSelector(state => ({
    target: state.clusters?.target,
    pccw: state.pccw
  }));

  const initialValues = {
    type: selectedProvider
  };

  const filteredOptions = filter ? profileOptions.filter(option => filter.indexOf(option.type) > -1) : profileOptions;

  const { data = [], isLoading: pccwIsLoading, error: pccwError } = pccw;

  useEffect(() => {
    form?.resetFields?.();
    form?.setFieldsValue?.({ type: activeCloud });
    setSelectedProvider(activeCloud);
  }, [activeCloud, form]);

  const clearFileState = () => {
    setFileList([]);
  }

  const handleFetchPCCW = (e) => {
    e.preventDefault();
    const { pccwEmail, pccwPassword } = form?.getFieldsValue();
    target && pccwEmail && pccwPassword && dispatch(fetchPCCWCompanies(target, pccwEmail, pccwPassword));
  }

  const handleProviderChange = (value) => {
    form.resetFields();
    form.setFieldsValue({ type: value });
    clearFileState();
    setSelectedProvider(value);
  }

  const handleFileUpload = type => file => {
    setFileList([file]);
    if (type === GOOGLE) {
      return file.text().then(res => {
        form.setFieldsValue({
          googleKeyFile: res
        });
        return Promise.reject();
      });
    }
    if (type === AZURE) {
      return file.text().then(res => {
        const encodedFile = btoa(res);
        form.setFieldsValue({
          azureKeyFile: encodedFile
        });
        return Promise.reject();
      });
    }
    return Promise.reject();
  }

  const onRemoveFile = type => file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
    if (type === GOOGLE) {
      form.setFieldsValue({
        googleKeyFile: undefined
      });
    }
    if (type === AZURE) {
      form.setFieldsValue({
        azureKeyFile: undefined
      });
    }
  }

  const handleOnCancel = () => {
    dispatch(resetPCCWState());
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      title='Add Credential'
      onOk={handleCreate}
      onCancel={handleOnCancel}
      width={600}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                handleCreate(form);
                form.resetFields();
              })
              .then(() => setFileList([]))
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          Create
        </Button>,
        <Button
          key='cancel'
          type='secondary'
          onClick={handleOnCancel}
        >
          Cancel
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' initialValues={initialValues} requiredMark={'optional'}>
        <Row gutter={24}>
          <Col span={12} className={'modal-col'}>
            <Form.Item name='type' className='modal-item-label' label='Service Provider'
                       rules={[{ required: true, message: 'Cloud is required' }]}>
              <WrappedSelect className='modal-item-controller' onChange={handleProviderChange}>
                {filteredOptions.map(option => <Option key={option.type} value={option.type}>{option.name}</Option>)}
              </WrappedSelect>
            </Form.Item>
          </Col>
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input className='modal-item-controller' placeholder='Name' />
            </Form.Item>
          </Col>
          {(selectedProvider === AWS || selectedProvider === GOOGLE) &&
          <Col span={12} className={'modal-col'}>
            <Form.Item
              name='region'
              className='modal-item-label'
              label='Region'
              rules={[{ required: true, message: 'Region is required' }]}
            >
              <WrappedSelect className='modal-item-controller'>
                {selectedProvider === AWS &&
                awsRegions.map(region =>
                  <Option key={region.region}
                          value={region.region}>{`${region.name} (${region.region})`}
                  </Option>)
                }
                {selectedProvider === GOOGLE &&
                googleRegions.map(region =>
                  <Option key={region.region}
                          value={region.region}>{`${region.name} (${region.region})`}
                  </Option>)
                }
              </WrappedSelect>
            </Form.Item>
          </Col>
          }
          {selectedProvider === AWS &&
          <>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='awsAccessKeyId'
                className='modal-item-label'
                label='Access Key Id'
                rules={[{ required: true, message: 'Access Key ID is required' }]}
              >
                <Input className='modal-item-controller' placeholder='Access Key Id' />
              </Form.Item>
            </Col>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='awsSecretAccessKey'
                className='modal-item-label'
                label='Secret Access Key'
                rules={[{ required: true, message: 'Secret Access Key is required' }]}
              >
                <Input className='modal-item-controller' placeholder='Secret Access Key' />
              </Form.Item>
            </Col>
          </>
          }
          {selectedProvider === GOOGLE &&
          <>
            <Col span={24} className={'modal-col'}>
              <Form.Item
                name='googleKeyFile'
                className='modal-item-label'
                label='Service Key File'
                rules={[{ required: true, message: 'File is required' }]}
              >
                <Dragger
                  multiple={false}
                  accept={['.json']}
                  beforeUpload={handleFileUpload(GOOGLE)}
                  onRemove={onRemoveFile(GOOGLE)}
                  fileList={fileList}
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                    showDownloadIcon: false
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
              </Form.Item>
            </Col>
          </>
          }
          {selectedProvider === AZURE &&
          <>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='azureSubscriptionId'
                className='modal-item-label'
                label='Subscription ID'
                rules={[{ required: true, message: 'Subscription ID is required' }]}
              >
                <Input className='modal-item-controller' placeholder='Subscription ID' />
              </Form.Item>
            </Col>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='azureClientId'
                className='modal-item-label'
                label='Client ID'
                rules={[{ required: true, message: 'Client ID is required' }]}
              >
                <Input className='modal-item-controller' placeholder='Client ID' />
              </Form.Item>
            </Col>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='azureTenantId'
                className='modal-item-label'
                label='Tenant ID'
                rules={[{ required: true, message: 'Tenant ID is required' }]}
              >
                <Input className='modal-item-controller' placeholder='Tenant ID' />
              </Form.Item>
            </Col>
            <Col span={24} className={'modal-col'}>
              <Form.Item
                name='azureKeyFile'
                className='modal-item-label'
                label='Certificate File'
                rules={[{ required: true, message: 'File is required' }]}
              >
                <Dragger
                  multiple={false}
                  accept={['.json']}
                  beforeUpload={handleFileUpload(AZURE)}
                  onRemove={onRemoveFile(AZURE)}
                  fileList={fileList}
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                    showDownloadIcon: false
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
              </Form.Item>
            </Col>
          </>
          }
          {selectedProvider === PCCW &&
          <>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='pccwEmail'
                className='modal-item-label'
                label='Email'
                rules={[{ required: true, type: 'email', message: 'Email is required' }]}
              >
                <Input className='modal-item-controller' placeholder='Email' type='email' onPressEnter={handleFetchPCCW}
                       onBlur={handleFetchPCCW} />
              </Form.Item>
            </Col>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='pccwPassword'
                className='modal-item-label'
                label='Password'
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <Input.Password className='modal-item-controller' placeholder='Password' onPressEnter={handleFetchPCCW}
                                onBlur={handleFetchPCCW} />
              </Form.Item>
            </Col>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='pccwCompany'
                className='modal-item-label'
                label='Company'
                rules={[{ required: true, message: 'Company is required' }]}
                help={pccwError ? 'Credentials are incorrect. Please try again' : undefined}
              >
                <WrappedSelect className='modal-item-controller' disabled={pccwIsLoading} loading={pccwIsLoading}>
                  {data.map(datum => <Option key={datum.id} value={datum.id}>{datum.name}</Option>)}
                </WrappedSelect>
              </Form.Item>
            </Col>
          </>
          }
          {selectedProvider === MEGAPORT &&
          <>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='megaportUsername'
                className='modal-item-label'
                label='Email'
                rules={[{ required: true, type: 'email', message: 'Email is required' }]}
              >
                <Input className='modal-item-controller' placeholder='Email' type='email' />
              </Form.Item>
            </Col>
            <Col span={12} className={'modal-col'}>
              <Form.Item
                name='megaportPassword'
                className='modal-item-label'
                label='Password'
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <Input.Password className='modal-item-controller' placeholder='Password'/>
              </Form.Item>
            </Col>
          </>
          }
        </Row>
      </Form>
    </Modal>
  );
};

export default AddCredentialModal;
