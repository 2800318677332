import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button'; 
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Typography from 'antd/lib/typography';
import useForm from 'antd/lib/form/hooks/useForm';
import { createSshKey } from '../../api';
import { fetchSshKeys } from '../../reducers/sshKeysSlice';
import notification from '../../utils/notification';
import './modal.scss';

const { Paragraph } = Typography;

const AddSshKeyModal = ({ visible, loading, onCancel, metalClusterInfo }) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const addSshKey = async () => {
    const values = form.getFieldsValue();
    try {
      await createSshKey(metalClusterInfo.uuid, values);
      dispatch(fetchSshKeys(metalClusterInfo.uuid));
      notification.success({
        message: 'New SSH key added',
        placement: 'bottomRight'
      });
      onCancel();
    } catch (e) {
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    }
  }

  return (
    <Modal
      visible={visible}
      title='Add SSH Key'
      onCancel={onCancel}
      width={640}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={addSshKey}
        >
          OK
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' >
        <Paragraph>Add an SSH Key to the cluster.</Paragraph>
        <Col span={24} className={'modal-col'}>
          <p>
            <b className='boot-key-label'>Cluster Name:</b> {metalClusterInfo.name}
          </p>
        </Col>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='key'
              className='modal-item-label'
              label='SSH Key'
              validateTrigger='onBlur'
              hasFeedback
            >
              <Input className='modal-item-controller' placeholder='SSH key'/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddSshKeyModal;
