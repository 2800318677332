import React, { useState } from 'react';
import Search from '../../components/search';
import BaseTable from '../../components/table';

const NameSpaces = ({ data = {}, isLoading }) => {
  const [filter, setFilter] = useState(false);

  const onSearch = (val) => {
    setFilter(val);
  };

  // We use this to filter instead of the antd column filter as it doesn't update the pagination
  const filteredData = filter.length > 0 ? data.filter((el) => {
    return el.name.toLowerCase().includes(filter.toLowerCase());
  }) : data;

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      sorter: (a, b) => a.name > b.name,
      width: 150
    },
    {
      title: 'PODS',
      dataIndex: 'podCount',
      sorter: (a, b) => a.podCount < b.podCount,
      width: 150
    },
  ];

  return (
    <div className='node-container'>
      <div className='header'>
        <div className='row'>
          <div className='node-header-description'>
            <span className='item-count'>{data.length || 0}</span> NameSpaces
          </div>
          <Search onSearch={onSearch} />
        </div>
      </div>
      <div className='node-list'>
        <BaseTable
          columns={columns}
          data={filteredData || []}
          className='node-table'
          pageSize={5}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default NameSpaces;
