import { createSlice } from '@reduxjs/toolkit';
import { getNats } from '../api';

const natsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const nats = createSlice({
  name: 'nats',
  initialState: natsInitialState,
  reducers: {
    getNatsStart(state) {
      state.isLoading = true;
    },
    getNatsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((nat) => {
          nat['key'] = nat.id;
          return nat;
        });
      } else {
        state.data = [];
      }
    },
    getNatsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { getNatsStart, getNatsSuccess, getNatsFailure } = nats.actions;

export default nats.reducer;

export const fetchNats = target => async (dispatch) => {
  try {
    dispatch(getNatsStart());
    const nats = await getNats(target);
    dispatch(getNatsSuccess(nats));
  } catch (err) {
    dispatch(getNatsFailure(err.toString()));
  }
};
