const MIN_ID = 2;
const MAX_ID = 4094;

const vlanIdValidator = () => ({
  validator(_, value) {
    try {
      const vlanId = Number.parseInt(value);
      if (
        Number.isInteger(vlanId) && vlanId >= MIN_ID && vlanId <= MAX_ID
      ) {
        return Promise.resolve();
      }
      return Promise.reject('VLAN ID must be a number between 2 and 4094');
    } catch {
      return Promise.reject('VLAN ID must be a number between 2 and 4094');
    }
  },
})

export default vlanIdValidator;