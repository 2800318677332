import React, { useState, useEffect } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Button from 'antd/lib/button';
import './index.scss';
import { isFunction } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setWizardConfig, closeWizard, setWizardStep } from '../../reducers/wizardSlice';

const WizardFooter = ({ onContinue, onPrevious, numSteps, currentStep, loading, error, completeText }) => {
  return <div className='wizard-footer'>
      <Button type='primary' onClick={onPrevious} loading={loading} disabled={currentStep === 0}>
        Previous
      </Button>
      <Button type='primary' onClick={onContinue} loading={loading} disabled={error}>
        {currentStep === (numSteps - 1) ? completeText : 'Next'}
      </Button>
  </div>
}

const BaseWizard = ({ name, stepName, steps = [], onCancelWizard, onComplete, children, loading, onStepChange, forceShowInner, completeText }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const wizard = useSelector((state) => state.wizard);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWizardConfig({
      name,
      steps
    }));

    return () => {
      dispatch(closeWizard());
    }
  }, [name, steps, dispatch]);

  useEffect(() => {
    if (wizard?.close) {
      onCancelWizard();
      dispatch(closeWizard());
    }
  }, [wizard?.close, onCancelWizard, dispatch]);

  const onPrevious = async () => {
    if (currentStep === 4 && steps[4].name === 'Cloud Details') {
      dispatch(setWizardStep({
        activeStep: 0,
        preValidationActiveStep: 0
      }));
      setCurrentStep(0);
    } else if (currentStep > 0) {
      dispatch(setWizardStep({
        activeStep: currentStep - 1,
        preValidationActiveStep: currentStep - 1
      }));
      setCurrentStep(currentStep - 1);
    }
  }

  const onContinue = async (current) => {
    if (Number.isInteger(current)) {
      if (current === 0 || current <= currentStep) {
        dispatch(setWizardStep({
          activeStep: Number(current),
          preValidationActiveStep:  Number(current),
        }));
        onStepChange(currentStep);
        setCurrentStep(current);
      } else {
        if (await steps[current - 1]?.validation()) {
          await steps[current]?.preAction?.();
          dispatch(setWizardStep({
            activeStep: Number(current),
            preValidationActiveStep:  Number(current),
          }));
          onStepChange(currentStep);
          setCurrentStep(current);
        }
      }
    } else if (current.target) {
      if (await steps[currentStep]?.validation()) {
        if (currentStep < (steps.length - 1)) {
          if (currentStep === 0 && wizard.steps[1].visible === 'no') {
            await steps[4]?.preAction?.();
            dispatch(setWizardStep({
              activeStep: Number(currentStep + 1),
              preValidationActiveStep: Number(4),
            }));
            onStepChange(currentStep);
            setCurrentStep(4);
          } else {
            await steps[currentStep + 1]?.preAction?.();
            dispatch(setWizardStep({
              activeStep: Number(currentStep + 1),
              preValidationActiveStep: Number(currentStep + 1),
            }));
            onStepChange(currentStep);
            setCurrentStep(currentStep + 1);
          }
        } else {
          onComplete();
        }
      }
      return false;
    }
  }

  // We need to use effects here if the flow was changed using the header (different component)
  useEffect(() => {
    const validate = async (currentStep, newStep) => {
      if (newStep < currentStep) {
        await steps[newStep]?.preAction?.();
        dispatch(setWizardStep({
          activeStep: newStep,
          preValidationActiveStep:  newStep,
        }));
        onStepChange(currentStep);
        setCurrentStep(newStep);
      } else if (newStep === currentStep + 1 && await steps[newStep - 1]?.validation()) {
        await steps[newStep]?.preAction?.();
        dispatch(setWizardStep({
          activeStep: newStep,
          preValidationActiveStep:  newStep,
        }));
        onStepChange(currentStep);
        setCurrentStep(newStep);
      } else {
        dispatch(setWizardStep({
          activeStep: currentStep,
          preValidationActiveStep: currentStep
        }))
      }
    }

    if (wizard?.preValidationActiveStep != null && wizard?.dirty && wizard?.activeStep !== wizard?.preValidationActiveStep) {
      validate(Number(wizard?.activeStep), Number(wizard?.preValidationActiveStep));
    }
  }, [wizard?.dirty, wizard?.activeStep, wizard?.preValidationActiveStep, dispatch, onStepChange, steps]);

  const handleStepChange = (step) => {
    dispatch(
      setWizardStep({
        activeStep: Number(step),
        preValidationActiveStep: Number(step)
      })
    )
    setCurrentStep(step);
  }

  // FIXME - inner steps needs a different work flow
  return <div className='wizard-container'>
    <Row style={{
      justifyContent: 'center'
    }}>
      {/*{(forceShowInner || steps.length > 3) &&*/}
      {/*<Col span={6} className={'wizard-steps'}>*/}
      {/*  <div className={'wizard-steps-title'}>*/}
      {/*    {stepName} Steps*/}
      {/*  </div>*/}
      {/*  <Steps direction={'vertical'} current={currentStep} onChange={onContinue}>*/}
      {/*    {steps.map(step => <Step key={step.title} title={step.title || ''} />)}*/}
      {/*  </Steps>*/}
      {/*</Col>*/}
      {/*}*/}
      <Col span={18} className={'wizard-content'}>
        <div className={'wizard-step-title'}>{steps[currentStep].name}</div>
        {isFunction(children) ? children(currentStep, handleStepChange) : children}
      </Col>
    </Row>
    <WizardFooter
      onContinue={onContinue}
      onPrevious={onPrevious}
      onCancel={onCancelWizard}
      currentStep={currentStep}
      completeText={completeText || 'Complete'}
      numSteps={steps.length}
      loading={loading}
      error={!!steps?.[currentStep].error}
    />
  </div>
}

export default BaseWizard;