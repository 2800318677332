import React, { useState } from 'react';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import Input from 'antd/lib/input';

import './index.scss'

const Search = ({ onSearch }) => {
  const [showInput, setShowInput] = useState(false);
  const [text, setText] = useState('');

  const handleSearchClick = () => {
    if (showInput) {
      setText('');
      onSearch('');
    }
    setShowInput(!showInput);
  }

  const handleClear = () => {
    setText('');
    onSearch('');
    setShowInput(false);
  }

  const handleChange = (evt) => {
    setText(evt.target.value);
  }

  const onPressEnter = (evt) => {
    onSearch(evt.target.value);
  }

  return <div className='search-container'>
    <SearchOutlined onClick={handleSearchClick} />
    <Input
      style={{
        width: showInput ? '100%' : 0,
        maxWidth: 200,
        opacity: showInput ? 1 : 0,
        padding: showInput ? '4px 11px 4px 20px' : '4px 0',
        marginLeft: showInput ? -15 : 0,
        marginRight: showInput ? -15 : 0
      }}
      value={text}
      onChange={handleChange}
      onPressEnter={onPressEnter}
    />
    <CloseOutlined
      style={{
        opacity: showInput ? 1 : 0,
        zIndex: 1,
        color: 'grey',
        fontSize: '10px',
        width: showInput ? 'auto' : 0,
      }}
      onClick={handleClear}
    />
  </div>
}

export default Search;