import { createSlice } from '@reduxjs/toolkit';
import { getCloudPrefill } from '../api';

const cloudPrefillInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const cloudPrefill = createSlice({
  name: 'cloudPrefill',
  initialState: cloudPrefillInitialState,
  reducers: {
    getCloudPrefillStart(state) {
      state.isLoading = true;
    },
    getCloudPrefillSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = {
        ...state.data,
        [payload.type]: payload.cloudPrefill.data
      };
    },
    getCloudPrefillFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const {
  getCloudPrefillStart,
  getCloudPrefillSuccess,
  getCloudPrefillFailure
} = cloudPrefill.actions;

export default cloudPrefill.reducer;

export const fetchCloudPrefill = (type,  connectType, target, profileId, connectProfileId) => async (dispatch) => {
  try {
    dispatch(getCloudPrefillStart());
    const cloudPrefill = await getCloudPrefill(type, connectType, target, profileId, connectProfileId);
    dispatch(getCloudPrefillSuccess({ type, cloudPrefill }));
  } catch (err) {
    dispatch(getCloudPrefillFailure(err.toString()));
  }
};
