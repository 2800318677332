import { createSlice } from '@reduxjs/toolkit';
import { getVersions, getMaestroVersion } from '../api';

const versionsInitialState = {
  data: {},
  isLoading: false,
  error: null
};

const versions = createSlice({
  name: 'versions',
  initialState: versionsInitialState,
  reducers: {
    getVersionsStart(state) {
      state.isLoading = true;
    },
    getCloudVersionSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data.fluidCloud = payload.fluidCloud.data
    },
    getClusterVersionSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data.fluidMaestro = payload.fluidMaestro.data
    },
    getCloudVersionsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data.fluidCloud = undefined;
    },
    getClusterVersionsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data.fluidMaestro = undefined;
    }
  }
});

export const { getVersionsStart, getCloudVersionSuccess, getClusterVersionSuccess, getCloudVersionsFailure, getClusterVersionsFailure } = versions.actions;

export default versions.reducer;

export const fetchVersions = target => async (dispatch) => {
  try {
    dispatch(getVersionsStart());
    const versions = {};
    versions.fluidCloud = await getVersions();
    dispatch(getCloudVersionSuccess(versions));
  } catch (err) {
    dispatch(getCloudVersionsFailure(err.toString()));
  }

  try {
    dispatch(getVersionsStart());
    const versions = {};
    versions.fluidMaestro = await getMaestroVersion(target);
    dispatch(getClusterVersionSuccess(versions));
  } catch (err) {
    dispatch(getClusterVersionsFailure(err.toString()));
  }
};