import React from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from 'antd/lib/breadcrumb';
import { ReactComponent as TriangleIcon } from '../../assets/ic_triangle_add.svg';
import './breadcrumb.scss';

const Breadcrumbs = ({ history }) => {
  const { crumbs = [] } = useSelector(state => state.breadcrumbs);

  const handleLink = link => () => {
    history.push(link);
  }

  return <div className="breadcrumb-container">
    <Breadcrumb separator={<TriangleIcon style={{
      transform: "rotate(-90deg)",
      width: 15,
      height: 15,
    }} />}>
      {crumbs?.map((item) =>
        item.link ? (
          <Breadcrumb.Item key={item.title} className="ant-breadcrumb-link has-link" onClick={handleLink(item.link)}>
            {item.title}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={item.title}>{item.title}</Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  </div>;
}

export default Breadcrumbs;