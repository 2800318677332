import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Menu from 'antd/lib/menu';
import Badge from 'antd/lib/badge';
import Popover from 'antd/lib/popover';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Dropdown from 'antd/lib/dropdown';
import Divider from 'antd/lib/divider';
import BellOutlined from '@ant-design/icons/BellOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import ClusterOutlined from '@ant-design/icons/ClusterOutlined';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import Messages from '../../views/overview/messages';
import './menubar.scss';
import { updateClusterTarget } from '../../reducers/clusterSlice';
import { fetchCapabilities } from '../../reducers/clusterCapabilitySlice';
import WizardMenu from './wizard';

import { ReactComponent as TriangleIcon } from '../../assets/ic_triangle_add.svg';
import { ReactComponent as FluidLogo } from '../../assets/fluid.svg';
import EditProfileModal from '../modals/editProfileModal';
import { updateUserProfile } from '../../api';
import notification from '../../utils/notification';

const { SubMenu } = Menu;

const Menubar = ({ routes, history }) => {
  const dispatch = useDispatch();
  const { data: { userInfo } } = useSelector((state) => state.user);
  const [activeKey, setActiveKey] = useState('menuOverview');
  const { notificationClusters } = useSelector((state) => state.notification);
  const clusters = useSelector((state) => state.clusters);
  const wizard = useSelector((state) => state.wizard);
  const { data } = clusters;
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: capabilities } = useSelector((state) => state.capability);

  const unreadCnt = notificationClusters?.[clusters?.target]?.unreadCnt || 0;

  const handleChangeCluster = (value) => {
    dispatch(updateClusterTarget(value));
    dispatch(fetchCapabilities(value));
  };

  useEffect(() => {
    Array.isArray(routes) &&
      routes.forEach((item) => {
        if (Array.isArray(item.children)) {
          if (item.path === history.location.pathname) {
            handleClick({
              key: 'sub' + item.name,
              keyPath: ['sub' + item.name]
            });
          }
          item.children.forEach((node) => {
            if (node.path === history.location.pathname) {
              handleClick({
                key: 'menu' + node.name,
                keyPath: ['menu' + node.name, 'sub' + item.name]
              });
            }
          });
        } else {
          if (item.path === history.location.pathname) {
            handleClick({
              key: 'menu' + item.name,
              keyPath: ['menu' + item.name]
            });
          }
        }
      });
  }, [routes, history.location.pathname]);

  const handleRootClick = (item, path) => () => {
    history.push(path);
  };

  const handleClick = (item) => {
    setActiveKey(item.key);
  };

  const handleProfileUpdate = (form) => {
    setLoading(true);
    const data = form.getFieldsValue();

    return updateUserProfile({
      ...data,
      eulaDate: new Date().toISOString(),
    }).then(() => {
      setLoading(false);
      notification.success({
        message: 'Thanks for registering your interest!'
      });
    }).catch(() => {
      setLoading(false);
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    })
  }

  const getMenus = (nodes, pkey) =>
    Array.isArray(nodes)
      ? nodes.filter(node => !node.menuHidden).map((item) => {
        if (item.capability && !capabilities[item.capability]) {
          return null;
        }
          const menu = getMenus(item.children, item.name);
          if (menu.length > 0) {
            return (
              <SubMenu
                key={'sub' + item.name}
                popupOffset={-1}
                popupClassName='menubar-submenu-popup'
                title={
                  <span className='flex-center'>
                    {item.name}
                    <TriangleIcon style={{
                      marginLeft: 3
                    }}/>
                  </span>
                }
                onTitleClick={handleRootClick(
                  { key: 'sub' + item.name, keyPath: ['sub' + item.name] },
                  item.path
                )}
              >
                {menu}
              </SubMenu>
            );
          } else {
            return (
              item.path && (
                <Menu.Item key={'menu' + item.name}>
                  <Link to={item.path}>
                    <span>{item.name}</span>
                  </Link>
                </Menu.Item>
              )
            );
          }
        }).filter(i => i)
      : [];

  const profileMenu = (
    <Menu>
      {userInfo && <Menu.Item key="profile" className="user-info">
        <b>{userInfo.nickname}</b><br />
        <span>{userInfo.email}</span>
      </Menu.Item>}
      <Divider style={{ margin: '12px 0' }} />
      <Menu.Item key="edit" icon={<UserOutlined />} onClick={() => setOpen(!isOpen)}>
        Edit Profile
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Log out
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu onClick={(e) => handleChangeCluster(e.key)} selectedKeys={[clusters?.target]}>
      {data.map((cluster) => (
        <Menu.Item key={cluster.clusterUuid} icon={<ClusterOutlined />}>
          {cluster.clusterName}
        </Menu.Item>
      ))}
    </Menu>
  );

  const currentRoute = routes.find(route => route.path === history.location.pathname) || {};
  const { hideMenuItems = false } = currentRoute;

  return (
    <>
      <Row align='middle' className={`navigation-bar ${wizard?.name ? 'active-wizard' : ''}`}>
        <Col className='fluid-title'>
          <FluidLogo />
          {wizard?.name ? <span>{wizard.name}</span> : <span>FLUID</span>}
        </Col>
        {(wizard?.name && wizard?.steps) ? <WizardMenu {...wizard} /> :
          hideMenuItems ? <></> : <>
            <Col>
              <Menu
                mode='horizontal'
                onClick={handleClick}
                selectedKeys={[activeKey]}
                style={{
                  minWidth: '750px'
                }}
              >
                {getMenus(routes)}
              </Menu>
            </Col>
            <Col className='cluster-selector'>
              <Dropdown overlay={menu} placement="bottomCenter">
                <div className='cluster-selector'>
                  <b>Environment</b><br />
                  <span className="cluster">{clusters?.target ? data.find(el => el.clusterUuid === clusters.target)?.clusterName : 'N/A'}</span>
                  <div className='icon'>
                    <TriangleIcon />
                  </div>
                </div>
              </Dropdown>
            </Col>
            <Col className='cluster-selector profile-button'>
              <Dropdown overlay={profileMenu} placement="bottomCenter">
                <div className='cluster-selector'>
                  <b><UserOutlined /></b>
                </div>
              </Dropdown>
            </Col>
            <Col className='notification-container'>
              <Popover content={Messages} trigger='click' className="notification-popover" placement='bottomLeft'>
                <Row className='sidebar-notification' align='middle' justify='space-between'>
                  <Col>
                    <BellOutlined />
                  </Col>
                  <Col>
                    <Badge count={unreadCnt} style={{ backgroundColor: '#00ca35' }} />
                  </Col>
                </Row>
              </Popover>
            </Col>
          </>
        }
      </Row>
      <EditProfileModal
        visible={isOpen}
        onCancel={() => setOpen(!isOpen)}
        data={userInfo}
        loading={loading}
        handleUpdate={handleProfileUpdate}
      />
    </>
  );
}

export default Menubar;
