import { createSlice } from '@reduxjs/toolkit';
import { getKubeVersions } from '../api';

const kubernetesVersionsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const kubernetesVersions = createSlice({
  name: 'kubernetesVersions',
  initialState: kubernetesVersionsInitialState,
  reducers: {
    getKubernetesVersionsStart(state) {
      state.isLoading = true;
    },
    getKubernetesVersionsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data;
    },
    getKubernetesVersionsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    }
  }
});

export const { getKubernetesVersionsStart, getKubernetesVersionsSuccess, getKubernetesVersionsFailure } = kubernetesVersions.actions;

export default kubernetesVersions.reducer;

export const fetchKubernetesVersions = (target) => async (dispatch) => {
  try {
    dispatch(getKubernetesVersionsStart());
    const kubernetesVersions = await getKubeVersions(target);
    dispatch(getKubernetesVersionsSuccess(kubernetesVersions));
  } catch (err) {
    dispatch(getKubernetesVersionsFailure(err.toString()));
  }
};