import React  from 'react';
import { Modal, Button, Form, Input, Row, Col } from 'antd';
import './modal.scss';

const { TextArea } = Input;

const EditCloudInitConfigModal = ({ visible, loading, onEdit, onCancel, existingData = {} }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title='Edit CloudInit Configuration'
      onOk={onEdit}
      onCancel={onCancel}
      width={'80vw'}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then(onEdit)
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          Update
        </Button>,
        <Button
          key='cancel'
          type='secondary'
          onClick={onCancel}
        >
          Cancel
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' initialValues={existingData}>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='startScript'
              className='modal-item-label'
              label='CloudInit Configuration'
              help='Enter you bash script here'
            >
              <TextArea className='modal-item-controller' rows={20} maxLength={4096} showCount/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditCloudInitConfigModal;
