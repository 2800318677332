import BaseWizard from '../baseWizard';
import { Button, Col, Form, Input, Row, Tag } from 'antd';
import useForm from 'antd/lib/form/hooks/useForm';
import { useHistory, useParams } from 'react-router';
import React, { useState, useMemo } from 'react';
import { mergeObjects } from '../../../utils';
import notification from '../../../utils/notification';
import { useDispatch } from 'react-redux';
import { fetchClusters } from '../../../reducers/clusterSlice';
import NodeTable from './nodeTable/nodeTable';
import { validateNodePairingKey, getSupportedNodeVersions, addNodesToMetalCluster } from '../../../api';
import '../index.scss';
import './addEnvironment.scss';

const { TextArea } = Input;

const AddMetalNodesWizard = () => {
  const [isAdding, toggleIsAdding] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [form] = useForm();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [nodeData, setNodeData] = useState([]);
  const [nodeVersions, setNodeVersions] = useState({});
  const [createData, setCreateData] = useState({});
  const [pairingKeyStatus, setPairingKeyStatus] = useState([]);

  const wizardSteps = useMemo(() => [
      {
        title: 'Add Nodes',
        name: 'Add Nodes',
        description: '',
        validation: () => {
            return form.validateFields().then(() => {
            return true;
            }).catch(() => false);
        },
      }
    ], [form]);

    const onCancelWizard = () => {
        history.push(`/clusters/${params.clusterUuid}`);
    }

    const onStepChange = () => {
      // We do this so we can expand on the field values since form resets the field value for each step
      setCurrentData(mergeObjects(currentData, form.getFieldsValue()));
    }

    const onComplete = async () => {
      toggleIsAdding(true);
      try {
        await addNodesToMetalCluster(params.clusterUuid, createData.nodes);
        toggleIsAdding(false);
        dispatch(fetchClusters);
        notification.success({
          message: 'New Node has been added',
          placement: 'bottomRight'
        });
        history.push(`/clusters/${params.clusterUuid}`);
      } catch (e) {
        console.error(e);
        if (e.response?.data) {
          notification.error({
            message: e.response?.data,
            placement: 'bottomRight'
          });
        } else {
          notification.error({
            message: 'Something went wrong, please try again later',
            placement: 'bottomRight'
          });
        }
        toggleIsAdding(false);
      }
    }

    const validatePairingKeys = async () => {
      setPairingKeyStatus([]);
      const pairingKeys = form.getFieldValue('pairingKeys').split(',').map((pairingKey) => {
        return pairingKey.trim();
      })

      const pairingKeyStatuses = []
      for await (const pairingKey of pairingKeys) {
        const valid = await validateNodePairingKey(pairingKey);
        pairingKeyStatuses.push({ pairingKey, valid: valid?.data?.isValid }); //valid: valid?.data?.isValid
      }
      setPairingKeyStatus(pairingKeyStatuses);

      const validPairingKeys = pairingKeyStatuses.filter((pairingKey) => pairingKey.valid === true).map((validPairingKey) => {
        return {
          pairingKey: validPairingKey.pairingKey
        }
      })

      const nodeVersionData = await getSupportedNodeVersions();
      setNodeVersions(nodeVersionData);
      const defaultVersions = {
        osVersion: nodeVersionData?.os?.find((osVersion) => osVersion?.isDefault).value,
        kernelVersion: nodeVersionData?.kernel?.find((kernelVersion) => kernelVersion?.isDefault).value,
        kubeVersion: nodeVersionData?.kube?.find((kubeVersion) => kubeVersion?.isDefault).value,
        role: 'MASTER'
      }
      setNodeData(validPairingKeys.map((node => {
        Object.assign(node, defaultVersions);
        return node;
      })));
    }

    const createMetalData = (createNodesData) => {
      setCreateData(createNodesData);
    }

    return (
    <div className='addEnv-container'>
    <BaseWizard
      completeText={'Add'}
      name= 'Add Nodes'
      stepName={'Add Nodes'}
      onComplete={onComplete}
      onCancelWizard={onCancelWizard}
      steps={wizardSteps} 
      loading={isAdding}
      onStepChange={onStepChange}
    >
      {
        <Form form={form} layout='vertical' requiredMark={'optional'}>
          <Row gutter={24}>
            <Col span={12} className={'info-col'}>
              <Form.Item required={true} label='Pairing Keys' name='pairingKeys' className='pairingTextArea'>
                <TextArea></TextArea>
              </Form.Item>
              <Form.Item>
                <Button type='primary' onClick={validatePairingKeys}>Validate</Button>
              </Form.Item>
            </Col>
            <Col span={12} className={'info-col'}>
              Pairing Key Validity
              <br/>
              {pairingKeyStatus.length > 0 && <>
                { pairingKeyStatus.map((pairingKeyStatus) => {
                  return <Tag color={pairingKeyStatus.valid === true ? 'green' : 'red'}>{pairingKeyStatus.pairingKey}</Tag>
                })
                }
            </>}
            </Col>
            <Col span={24} className={'info-col'}>
              { nodeData.length > 0 && 
                <NodeTable
                  data={nodeData}
                  versions={nodeVersions}
                  createMetalData={createMetalData}
                  editMode={true}
                />
              }
            </Col>
          </Row>
        </Form>
      }
    </BaseWizard>
  </div>
)}

export default AddMetalNodesWizard;