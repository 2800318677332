import React, { useState } from 'react';
import Search from '../../components/search';
import BaseTable from '../../components/table';
import { formatDistanceToNow, parseJSON } from 'date-fns';

const LogEvents = ({ data = [] }) => {
  const [filter, setFilter] = useState('');

  const onSearch = (val) => {
    setFilter(val);
  };

  // We use this to filter instead of the antd column filter as it doesn't update the pagination
  const filteredData = filter.length > 0 ? data.filter(({ name, namespace }) => name.indexOf(filter) > -1 || namespace.indexOf(filter) > -1) : data;

  const columns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type > b.type,
      width: 160
    },
    {
      title: 'TIMESTAMP',
      dataIndex: 'lastTransitionTime',
      key: 'lastTransitionTime',
      sorter: (a, b) => a.lastTransitionTime > b.lastTransitionTime,
      defaultSortOrder: 'descend',
      width: 160,
      render: lastTransitionTime => (
        <>
        {
          lastTransitionTime && formatDistanceToNow(parseJSON(lastTransitionTime))
        }
        </>
      )
    },
    {
      title: 'MESSAGE',
      dataIndex: 'message',
      key: 'message',
      sorter: (a, b) => a.message > b.message,
    },
  ];

  return (
    <div className='log-events-container'>
      <div className='header'>
        <div className='row'>
          <div className='header-description'>
            <span className='item-count'>{data.length || 0}</span> Events
          </div>
          <Search onSearch={onSearch} />
        </div>
      </div>
      <div className='container-body'>
        <BaseTable
          columns={columns}
          data={filteredData}
          className='events-table'
          pageSize={5}
        />
      </div>
    </div>
  );
};

export default LogEvents;
