import { getAxios } from '../utils';

export const getClusters = async () => {
  const url = '/fluid/cluster/';
  return getAxios()?.axiosCall(url, 'get');
};

export const updateCluster = (data) => {
  const url = '/fluid/cluster/';
  return getAxios()?.axiosCall(url, 'put', data);
}

export const deleteCluster = (clusterUuid) => {
  const url = '/fluid/cluster/';
  return getAxios()?.axiosCall(url, 'delete', {
    clusterUuid
  });
}

export const addCluster = async (form) => {
  const url = '/fluid/cluster/assign';
  return getAxios()?.axiosCall(url, 'post', form);
};

export const validatePairingKey = async (pairingKey) => {
  const url = `/fluid/cluster/validate/${pairingKey}`;
  return getAxios()?.axiosCall(url, 'get');
}

export const getUser = async () => {
  const url = '/user/';
  return getAxios()?.axiosCall(url, 'get');
};

export const getNotification = async () => {
  const url = '/fluid/notification/';
  return getAxios()?.axiosCall(url, 'get');
};

export const getMaestroNotification = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/notifications',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getNetworks = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/network',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getSwitchPorts = async (target, ip) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/switch/${ip}/port`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getNats = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/network/nat',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getNatIps = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/network/nat/ips',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const addNat = async (target, form) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/nat`,
    target,
    method: 'POST',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const updateNat = async (target, form) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/nat`,
    target,
    method: 'PUT',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const deleteNat = async (target, id) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/nat`,
    target,
    method: 'DELETE',
    body: { id }
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getClouds = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/cloudconnect?active=true',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getCloudStatus = async (target, id) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/${id}/status`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getCloudPrefill = async (type, connectType, target, profileId, connectProfileId) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/prefill/`,
    target,
    method: 'POST',
    body: {
      type: type,
      provider: connectType,
      profiles: {
        [type]: profileId,
        [connectType]: connectProfileId
      }
    }
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const postPrefillPorts = async (target, form) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/prefill/ports`,
    target,
    method: 'POST',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const postCloudconnect = async (target, data) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect`,
    target,
    method: 'POST',
    body: data
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const postCloudconnectTemplate = async (target, data) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/template`,
    target,
    method: 'POST',
    body: data
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const updateCloudconnectTemplate = async (target, id, data) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/template/${id}`,
    target,
    method: 'PUT',
    body: data
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const deleteCloudconnectTemplate = async (target, id) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/template/${id}`,
    target,
    method: 'DELETE',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}


export const getAcls = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/acl`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const addAcl = async (target, form) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/acl`,
    target,
    method: 'POST',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const updateAcl = async (target, form) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/acl`,
    target,
    method: 'PUT',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const deleteAcl = async (target, form) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/acl`,
    target,
    method: 'DELETE',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getNodes = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/nodes`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const deprovisionNodeFromMetalCluster = async (target, nodeName) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/deprovision-node`,
    target,
    method: 'DELETE',
    body: {
      name: nodeName
    }
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getSvcs = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/svc`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getClusterCapacity = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/kube/capacity',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getClusterCapabilities = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/capabilities',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getKubeCtl = async (target, nestedClusterName) => {
  const url = `/fluid/cluster/kube/kubectl/${target}/${nestedClusterName}`;
  return getAxios()?.axiosCall(url, 'get');
};

export const validateName = async (target, name) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/validateName`,
    target,
    method: 'POST',
    body: name
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const addSpot = async (form, target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/spot/install`,
    target,
    method: 'POST',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getCloudTemplates = (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/template`,
    target,
    method: 'GET',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getCloudCredential = (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/key`,
    target,
    method: 'GET',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const updateCloudState = (target, id, state) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/${id}/state`,
    target,
    method: 'PUT',
    body: {
      state
    }
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const deleteCloud = (target, id) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/${id}`,
    target,
    method: 'DELETE',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const addCredential = (target, type, profile) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/key/${type}`,
    target,
    method: 'POST',
    body: profile
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const deleteCredentials = (target, id) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/key/${id}`,
    target,
    method: 'DELETE',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const updateCredentials = (target, id, profile) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/key/${id}`,
    target,
    method: 'PUT',
    body: profile
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const splitSwitch = (target, switchIp, portId) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/switch/${switchIp}/port/${portId}/split`,
    target,
    method: 'POST',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const unsplitSwitch = (target, switchIp, portId) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/switch/${switchIp}/port/${portId}/unsplit`,
    target,
    method: 'POST',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getPCCWCompanies = (target, email, password) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/cloudconnect/key/company/pccw`,
    target,
    method: 'POST',
    body: {
      email,
      password
    }
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getCircuits = (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/circuit`,
    target,
    method: 'GET',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getSubnets = (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/subnet`,
    target,
    method: 'GET',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getUnusedSubnets = (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/circuit/unusedsubnets`,
    target,
    method: 'GET',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const createSubnet = (target, data) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/subnet`,
    target,
    method: 'POST',
    body: data
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const updateSubnet = (target, data) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/subnet`,
    target,
    method: 'PUT',
    body: data
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const deleteSubnet = (target, data) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/subnet`,
    target,
    method: 'DELETE',
    body: data
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const checkUserStatus = () => {
  const url = '/user/health';
  return getAxios()?.axiosCall?.(url, 'get');
}

export const setUserProfile = (data) => {
  const url = '/user/profile';
  return getAxios()?.axiosCall?.(url, 'post', data);
}

export const updateUserProfile = (data) => {
  const url = '/user/profile';
  return getAxios()?.axiosCall?.(url, 'put', data);
}

export const addCircuit = (target, data) => {
  const url = `/fluid/cluster/upstream/${target}/fluid/circuit`;
  return getAxios()?.axiosCall(url, 'post', data);
}

export const getVersions = async () => {
  const url = '/fluid/version';
  return getAxios()?.axiosCall(url, 'get');
};

export const getMaestroVersion = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/version',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getKubeApplications = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/application',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getNestedClusters = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/nested`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const addNestedCluser = async ({ target, ...form }) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/nested`,
    target,
    method: 'POST',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const renameNestedCluser = async (target, clusterId, newName) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/nested/${clusterId}/rename`,
    target,
    method: 'POST',
    body: { newName }
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getKubeConfigByName = async (name, target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/nested/${name}/kubeconf`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const updateCircuit = (target, data) => {
  const url = `/fluid/cluster/upstream/${target}/fluid/circuit`;
  return getAxios()?.axiosCall(url, 'put', data);
}

export const getSingleNestedCluster = async (clusterId, target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/nested/${clusterId}`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getNestedClusterAssets = async (clusterId, target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/nested/${clusterId}/assets`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const deleteNestedCluster = async (clusterId, target, assets) => {
  const url = `/fluid/cluster/upstream/${target}/fluid/kube/nested/${clusterId}`;
  return getAxios()?.axiosCall(url, 'delete', assets);
}

export const deleteCircuit = (target, current) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/circuit`,
    target,
    method: 'DELETE',
    body: {
      id: current?.id
    }
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getKubeVersions = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/kube/nested/versions`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getCircuitFreePorts = (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/circuit/freeports`,
    target,
    method: 'GET',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const uploadFile = (target, nestedName, formData) => {
  const url = `/fluid/cluster/vms/upload/${target}/${nestedName}`;
  return getAxios()?.axiosCall(url, 'post', formData);
}

export const getVMs = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/vms`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getAllVMs = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/vms/`,
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const addNewVM = async ({ target, namespace, ...form }) => {
  const url = `/fluid/cluster/upstream`;
  const body = {
    api: `/fluid/vms/${namespace}`,
    target,
    method: 'POST',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getDataVolumes = async (target) => {
  const url = `/fluid/cluster/upstream`;
  const body = {
    api: `/fluid/vms/datavolumes`,
    target,
    method: 'GET',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const postDataVolume = async ({target, clusterId, form}) => {
  const url = `/fluid/cluster/upstream`;
  const body = {
    api: `/fluid/vms/datavolumes/${clusterId}`,
    target,
    method: 'POST',
    body: form
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const playVirtualMachine = async (target, namespace, name) => {
  const url = `/fluid/cluster/upstream`;
  const body = {
    api: `/fluid/vms/${namespace}/${name}/start`,
    target,
    method: 'PUT',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const stopVirtualMachine = async (target, namespace, name) => {
  const url = `/fluid/cluster/upstream`;
  const body = {
    api: `/fluid/vms/${namespace}/${name}/stop`,
    target,
    method: 'PUT',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const restartVirtualMachine = async (target, namespace, name) => {
  const url = `/fluid/cluster/upstream`;
  const body = {
    api: `/fluid/vms/${namespace}/${name}/restart`,
    target,
    method: 'PUT',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const deleteVirtualMachine = async (target, namespace, name) => {
  const url = `/fluid/cluster/upstream`;
  const body = {
    api: `/fluid/vms/${namespace}/${name}`,
    target,
    method: 'DELETE',
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getVNCToken = async (target, namespace, name) => {
  const url = `/fluid/cluster/vms/vnc/request`;
  const body = {
    cluster: target,
    vmName: name,
    vmNamespace: namespace,
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const updateVirtualMachine = async (target, namespace, name, values) => {
  const url = `/fluid/cluster/upstream`;
  const body = {
    api: `/fluid/vms/${namespace}/${name}`,
    target,
    method: 'PUT',
    body: values
  };
  return getAxios()?.axiosCall(url, 'post', body);
}

export const getVlans = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/network/vlan',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const createVlan = async (target, name, vlanId) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/network/vlan',
    target,
    method: 'POST',
    body: {
      name,
      vlanId
    }
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const deleteVlan = async (target, name, vlanId) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/network/vlan',
    target,
    method: 'DELETE',
    body: {
      name,
      vlanId
    }
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const resizeVmDisk = async (target, namespace, name, data) => {
  const url = `/fluid/cluster/upstream/${target}/fluid/vms/${namespace}/${name}/resize`;
  return getAxios()?.axiosCall(url, 'put', data);
}

export const resizeVmDiskStatus = async (target, namespace, name) => {
  const url = `/fluid/cluster/upstream/${target}/fluid/vms/${namespace}/${name}/resizeStatus`;
  return getAxios()?.axiosCall(url, 'get');
}

export const getMetalClusters = async () => {
  const url = `/fluid/metal/cluster`;
  return getAxios()?.axiosCall(url, 'get');
}

export const createMetalCluster = async (data) => {
  const url = `/fluid/metal/cluster`;
  return getAxios()?.axiosCall(url, 'post', data);
}

export const deleteMetalCluster = async (clusterUuid) => {
  const url = `/fluid/metal/cluster/${clusterUuid}`;
  return getAxios()?.axiosCall(url, 'delete');
}

export const editMetalCluster = async (clusterUuid, data) => {
  const url = `/fluid/metal/cluster/${clusterUuid}`;
  return getAxios()?.axiosCall(url, 'put', data);
}

export const addNodesToMetalCluster = async (clusterUuid, data) => {
  const url = `/fluid/metal/cluster/${clusterUuid}/node`;
  return getAxios()?.axiosCall(url, 'put', data);
}

export const deleteNodeFromMetalCluster = async (clusterUuid, nodeUuid, nodeName) => {
  const url = `/fluid/metal/cluster/${clusterUuid}/node/${nodeUuid}`;
  const body = {
    nodeName
  }
  return getAxios()?.axiosCall(url, 'delete', body);
}

export const validateNodePairingKey = async (pairingKey) => {
  const url = `/fluid/metal/cluster/validate/${pairingKey}`;
  return getAxios()?.axiosCall(url, 'get');
}

export const createMetalBootKey = async (clusterUuid, data) => {
  const url = `/fluid/metal/cluster/${clusterUuid}/keys`;
  return getAxios()?.axiosCall(url, 'post', data);
}

export const getMetalBootKeys = async (clusterUuid) => {
  const url = `/fluid/metal/cluster/${clusterUuid}/keys`;
  return getAxios()?.axiosCall(url, 'get');
}

export const deleteMetalBootKey = async (clusterUuid, keyUuid) => {
  const url = `/fluid/metal/cluster/${clusterUuid}/keys/${keyUuid}`;
  return getAxios()?.axiosCall(url, 'delete');
}

export const createSshKey = async (clusterUuid, data) => {
  const url = `/fluid/metal/cluster/tools/${clusterUuid}/ssh`;
  return getAxios()?.axiosCall(url, 'put', data);
}

export const getSshKeys = async (clusterUuid) => {
  const url = `/fluid/metal/cluster/tools/${clusterUuid}/ssh`;
  return getAxios()?.axiosCall(url, 'get');
}

export const deleteSshKey = async (clusterUuid, keyUuid) => {
  const url = `/fluid/metal/cluster/tools/${clusterUuid}/ssh/${keyUuid}`;
  return getAxios()?.axiosCall(url, 'delete');
}

export const updateCloudInitConfig = async (clusterUuid, data) => {
  const url = `/fluid/metal/cluster/tools/${clusterUuid}/cloudinit`;
  return getAxios()?.axiosCall(url, 'put', data);
}

export const getCloudInitConfig = async (clusterUuid) => {
  const url = `/fluid/metal/cluster/tools/${clusterUuid}/cloudinit`;
  return getAxios()?.axiosCall(url, 'get');
}

export const getClusterState = async (clusterUuid) => {
  const url = `/fluid/metal/cluster/${clusterUuid}/state`;
  return getAxios()?.axiosCall(url, 'get');
}

export const getBgpPeers = async (target) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: '/fluid/network/bgp/peers',
    target,
    method: 'GET'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const createBgpPeer = async (target, name, data) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/bgp/peers/${name}`,
    target,
    method: 'POST',
    body: data
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const updateBgpPeer = async (target, name, data) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/bgp/peers/${name}`,
    target,
    method: 'PUT',
    body: data
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const deleteBgpPeer = async (target, name) => {
  const url = '/fluid/cluster/upstream';
  const body = {
    api: `/fluid/network/bgp/peers/${name}`,
    target,
    method: 'DELETE'
  };
  return getAxios()?.axiosCall(url, 'post', body);
};

export const getSupportedNodeVersions = async () => {
  return {
    os: [
      {
        display: "Ubuntu 20.04 LTS",
        table: "Ubuntu 20.04",
        value: "20.04",
        isDefault: false
      },
      {
        display: "Ubuntu 22.04 LTS",
        table: "Ubuntu 22.04",
        value: "22.04",
        isDefault: true
      }
    ],
    kernel: [
      {
        display: "Linux 5.15 LTS",
        table: "5.15",
        value: "5.15",
        isDefault: false
      },
      {
        display: "Linux 5.18 Stable",
        table: "5.18",
        value: "5.18",
        isDefault: true
      }
    ],
    kube: [
      {
        display: "Kube 1.21",
        table: "1.21",
        value: "1.21",
        isDefault: false
      },
      {
        display: "Kube 1.22",
        table: "1.22",
        value: "1.22",
        isDefault: false
      },
      {
        display: "Kube 1.23",
        table: "1.23",
        value: "1.23",
        isDefault: false
      },
      {
        display: "Kube 1.24",
        table: "1.24",
        value: "1.24",
        isDefault: true
      }
    ]
  }
}