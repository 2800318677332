import { createSlice } from '@reduxjs/toolkit';
import { setupAxios } from '../utils';

const configInitialState = {
  token: undefined,
  accountId: undefined,
  workspaceId: undefined,
  userMetadata: undefined
};

const config = createSlice({
  name: 'config',
  initialState: configInitialState,
  reducers: {
    setConfig(state, { payload }) {
      state = {
        ...state,
        ...payload
      };
      setupAxios(state);
      return state;
    },
  }
});

export const { setConfig } = config.actions;

export default config.reducer;