import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchCloudStatus } from '../../reducers/cloudStatusSlice';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Chart from './chart';
import Header from '../../components/header';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { Button } from 'antd';
import CloudOutlined from '@ant-design/icons/CloudOutlined';
import ThunderboltOutlined from '@ant-design/icons/ThunderboltOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import CloudServerOutlined from '@ant-design/icons/CloudServerOutlined';
import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import ShowStatus from '../../components/showstatus';
import CloudActions from '../cloudDetails/actions';
import Card from 'antd/lib/card';
import { updateBreadcrumbs } from '../../reducers/breadcrumbSlice';
import Tabs, { TabPane } from '../../components/tabs';

const CloudDetails = () => {
  const [selectedCloud, setSelectedCloud] = useState({});
  const target = useSelector((state) => state.clusters?.target);
  const cloudStatus = useSelector((state) => state.cloudStatus);
  const { data: cloudData } = useSelector((state) => state.clouds);

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs([
      {
        title: 'Cloud',
        link: '/network/cloud/'
      }, {
        title: 'Cloud Details'
      }
    ]));
    return () => {
      dispatch(updateBreadcrumbs([]));
    }
  }, [dispatch]);

  useEffect(() => {
    target && dispatch(fetchCloudStatus(target, params?.cloudId));
  }, [dispatch, params?.cloudId, target]);

  const history = useHistory();

  const goViewClouds = () => {
    history.push('/network/cloud');
  };

  useEffect(() => {
    const cloud = cloudData?.find(cloud => cloud.id === params.cloudId);
    setSelectedCloud(cloud);
  }, [cloudData, params?.cloudId]);

  return <>
    <Header
      items={undefined}
      title={undefined}
      description=' '
    />
    <Skeleton loading={cloudStatus?.isLoading} active>
      {
        selectedCloud ?
          <>
            <Row justify='space-between' className='cloud-detail-info'>
              <Row justify='space-between'>
                <Col span={5} className='cloud-detail-info-block'>
                  <div className='icon-block'>
                    <CloudOutlined />
                  </div>
                  <div className='cloud-detail-info-description'>
                    <div>{selectedCloud?.name || 'Default'}</div>
                    <div className='cloud-detail-sub-title'>Name</div>
                  </div>
                </Col>
                <Col span={5} className='cloud-detail-info-block'>
                  <div className='icon-block'>
                    <ThunderboltOutlined />
                  </div>
                  <div className='cloud-detail-info-description'>
                    <div>{selectedCloud?.speed ? `${selectedCloud?.speed} Mbp/s` : 'Unavailable'}</div>
                    <div className='cloud-detail-sub-title'>Speed</div>
                  </div>
                </Col>
                <Col span={5} className='cloud-detail-info-block'>
                  <div className='icon-block'>
                    <ApartmentOutlined />
                  </div>
                  <div className='cloud-detail-info-description'>
                    <div>{selectedCloud?.type?.toUpperCase() || 'Unavailable'}</div>
                    <div className='cloud-detail-sub-title'>Provider</div>
                  </div>
                </Col>
                <Col span={5} className='cloud-detail-info-block'>
                  <div className='icon-block'>
                    <GlobalOutlined />
                  </div>
                  <div className='cloud-detail-info-description'>
                    <ShowStatus status={selectedCloud?.status} />
                    <div className='cloud-detail-sub-title'>Status</div>
                  </div>
                </Col>
              </Row>
              <CloudActions id={selectedCloud?.id} state={cloudStatus.data[0]?.status} type={selectedCloud?.type} />
            </Row>

            <Row justify='space-around'>
              <Tabs defaultActiveKey="1" type="card" size={"large"}>
                {cloudStatus.data.map((data, idx) =>
                  <TabPane key={`${selectedCloud?.name}${idx}`} tab={cloudStatus?.bgpNaming || selectedCloud?.bgpNaming?.[idx] || `Endpoint ${idx + 1}`}>
                    <Col span={24} style={{
                      marginBottom: 20
                    }}>
                      <Card
                        title={<div className='cloud-card-title'>
                          <div>
                            <CloudServerOutlined />
                            {cloudStatus?.bgpNaming || selectedCloud?.bgpNaming?.[idx] || `Endpoint ${idx + 1}`}
                          </div>
                          <div>
                            <ShowStatus status={cloudStatus.data[idx]?.status} />
                          </div>
                        </div>}
                        headStyle={{
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                        className='chart-card'
                      >
                        <Row align="top" justify="space-around" className="cloud-block">
                          <Col span={10}>
                            <Row className='info-block'>
                              <Col span={24}>
                                <div><b>INFO</b></div>
                              </Col>
                              <Col span={16} className='info-detail'>
                                <div>BGP Prefixes</div>
                              </Col>
                              <Col span={8} className='info-detail'>
                                <div>{cloudStatus.data[idx]?.bgp_prefixes}</div>
                              </Col>
                              <Col span={16} className='info-detail'>
                                <div>BGP State</div>
                              </Col>
                              <Col span={8} className='info-detail'>
                                <div>{cloudStatus.data[idx]?.bgp_state}</div>
                              </Col>
                              <Col span={16} className='info-detail'>
                                <div>BGP Uptime</div>
                              </Col>
                              <Col span={8} className='info-detail'>
                                <div>{cloudStatus.data[idx]?.bgp_uptime}</div>
                              </Col>
                              <Col span={16} className='info-detail'>
                                <div>Port Status</div>
                              </Col>
                              <Col span={8} className='info-detail'>
                                <div>{cloudStatus.data[idx]?.port_status}</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={14} className="chart-block">
                            <Row align="top">
                              <Col span={24}>
                                <div><b>TRANSFER OF DATA</b></div>
                              </Col>
                              {cloudStatus.chartData?.[idx].length ? <Col span={24}>
                                <Chart data={cloudStatus.chartData[idx]} />
                              </Col> : <div>No chart data available</div>}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </TabPane>
                )}
              </Tabs>
            </Row>
          </> :
          <div className='cloud-not-available'>
            <div className='message-container'>
              <ExclamationCircleOutlined />
              The cloud instance you are trying to access is not available. Please go back and try again.
            </div>
            <Button className='view-clouds-btn' type='primary' onClick={goViewClouds}>View Cloud</Button>
          </div>
      }
    </Skeleton>
  </>
}

export default CloudDetails;
