import { createSlice } from '@reduxjs/toolkit';
import { getMetalBootKeys } from '../api';

const metalBootKeysInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const metalBootKeys = createSlice({
  name: 'metalBootKeys',
  initialState: metalBootKeysInitialState,
  reducers: {
    getMetalBootKeysState(state) {
      state.isLoading = true;
    },
    getMetalBootKeysSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data || [];
    },
    getMetalBootKeysFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    }
  }
});

export const { getMetalBootKeysState, getMetalBootKeysSuccess, getMetalBootKeysFailure } = metalBootKeys.actions;

export default metalBootKeys.reducer;

export const fetchMetalBootKeys = (target) => async (dispatch) => {
  try {
    dispatch(getMetalBootKeysState());
    const bootKeys = await getMetalBootKeys(target);
    dispatch(getMetalBootKeysSuccess(bootKeys));
  } catch (err) {
    dispatch(getMetalBootKeysFailure(err.toString()));
  }
};
