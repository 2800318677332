import { createSlice } from '@reduxjs/toolkit';
import { getSingleNestedCluster } from '../api';

const nestedClusterDetailsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const nestedClusterDetails = createSlice({
  name: 'nestedClusterDetails',
  initialState: nestedClusterDetailsInitialState,
  reducers: {
    getNestedClusterDetailsStart(state) {
      state.isLoading = true;
    },
    getNestedClusterDetailsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data;
    },
    getNestedClusterDetailsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    }
  }
});

export const { getNestedClusterDetailsStart, getNestedClusterDetailsSuccess, getNestedClusterDetailsFailure } = nestedClusterDetails.actions;

export default nestedClusterDetails.reducer;

export const fetchNestedClusterDetails = (clusterId, target) => async (dispatch) => {
  try {
    dispatch(getNestedClusterDetailsStart());
    const nestedClusterDetails = await getSingleNestedCluster(clusterId, target);
    dispatch(getNestedClusterDetailsSuccess(nestedClusterDetails));
  } catch (err) {
    dispatch(getNestedClusterDetailsFailure(err.toString()));
  }
};