import { createSlice } from '@reduxjs/toolkit';
import { getSshKeys } from '../api';

const sshKeysInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const sshKeys = createSlice({
  name: 'sshKeys',
  initialState: sshKeysInitialState,
  reducers: {
    getSshKeysState(state) {
      state.isLoading = true;
    },
    getSshKeysSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data || [];
    },
    getSshKeysFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    }
  }
});

export const { getSshKeysState, getSshKeysSuccess, getSshKeysFailure } = sshKeys.actions;

export default sshKeys.reducer;

export const fetchSshKeys = (target) => async (dispatch) => {
  try {
    dispatch(getSshKeysState());
    const sshKeys = await getSshKeys(target);
    dispatch(getSshKeysSuccess(sshKeys));
  } catch (err) {
    dispatch(getSshKeysFailure(err.toString()));
  }
};
