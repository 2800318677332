import notification from 'antd/lib/notification';
import { ReactComponent as CloseIcon } from '../../assets/wizard_close_icon.svg';

const error = (props) => {
  notification.error({
    ...props,
    className: 'notification-error',
    placement: 'bottom',
    closeIcon: <CloseIcon style={{
      width: 10
    }}/>
  });
}

const success = (props) => {
  notification.success({
    ...props,
    className: 'notification-success',
    placement: 'bottom',
    closeIcon: <CloseIcon style={{
      width: 10
    }}/>
  })
}

const warn = (props) => {
  notification.warn({
    ...props,
    className: 'notification-warn',
    placement: 'bottom',
    closeIcon: <CloseIcon style={{
      width: 10
    }}/>
  })
}

const info = (props) => {
  notification.info({
    ...props,
    className: 'notification-info',
    placement: 'bottom',
    closeIcon: <CloseIcon style={{
      width: 10
    }}/>
  })
}

const exports = {
  error,
  success,
  info,
  warn
};
export default exports;