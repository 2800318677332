import { createSlice } from '@reduxjs/toolkit';
import { getNestedClusterAssets } from '../api';

const kubernetesAssetsInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const kubernetesAssets = createSlice({
  name: 'kubernetesAssets',
  initialState: kubernetesAssetsInitialState,
  reducers: {
    getKubernetesAssetsStart(state) {
      state.isLoading = true;
    },
    getKubernetesAssetsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data;
    },
    getKubernetesAssetsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    }
  }
});

export const { getKubernetesAssetsStart, getKubernetesAssetsSuccess, getKubernetesAssetsFailure } = kubernetesAssets.actions;

export default kubernetesAssets.reducer;

export const fetchKubernetesAssets = (clusterId, target) => async (dispatch) => {
  try {
    dispatch(getKubernetesAssetsStart());
    const kubernetesAssets = await getNestedClusterAssets(clusterId, target);
    dispatch(getKubernetesAssetsSuccess(kubernetesAssets));
  } catch (err) {
    dispatch(getKubernetesAssetsFailure(err.toString()));
  }
};