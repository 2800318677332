import React, { useState } from 'react';
import Popover from 'antd/lib/popover';
import { ReactComponent as Dots } from '../../assets/dots.svg';

const TableMoreItems = ({ onUpdate, onDelete }) => {
  const [visible, setVisible] = useState(false);

  const handleUpdate = () => {
    setVisible(false);
    onUpdate();
  };

  const handleDelete = () => {
    setVisible(false);
    onDelete();
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const content = (
    <>
      <div className='more-action-menu' onClick={handleUpdate}>
        Update
      </div>
      <div className='more-action-menu' onClick={handleDelete}>
        Delete
      </div>
    </>
  );

  return (
    <Popover
      placement='bottom'
      content={content}
      trigger='click'
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div className='more-action-button'>
        <Dots />
      </div>
    </Popover>
  );
};

export default TableMoreItems;
