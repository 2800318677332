import React from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import './modal.scss';

const ConfirmModal = ({ visible, title, loading, onConfirm, onCancel, message }) => {
  const onClickConfirm = () => {
    onConfirm();
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      title={`Confirm ${title}?`}
      onOk={onConfirm}
      onCancel={handleCancel}
      width={500}
      footer={[
        <Button key='confirm' loading={loading} type='primary' onClick={onClickConfirm}>
          Continue
        </Button>,
        <Button key='cancel' type='secondary' onClick={handleCancel}>
          Cancel
        </Button>
      ]}
    >
      <div>
        {message ? 
          <p>{message}</p> : 
          ''
        }
      </div>
    </Modal>
  );
};

export default ConfirmModal;
