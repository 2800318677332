import { createSlice } from '@reduxjs/toolkit';

const wizardInitialState = {
  name: undefined,
  steps: [],
  activeStep: 0,
  preValidationActiveStep: 0,
  dirty: false,
  close: false
};

const wizard = createSlice({
  name: 'wizard',
  initialState: wizardInitialState,
  reducers: {
    setWizardConfig(state, { payload = {} }) {
      return {
        ...state,
        name: payload.name,
        steps: payload.steps
      };
    },
    setWizardPreValidationStep(state, { payload }) {
      return {
        ...state,
        preValidationActiveStep: payload.step,
        dirty: true
      }
    },
    setWizardStep(state, { payload }) {
        return {
          ...state,
          activeStep: payload.activeStep != null ? payload.activeStep : state.activeStep,
          preValidationActiveStep: payload.preValidationActiveStep != null ? payload.preValidationActiveStep : state.preValidationActiveStep
        }
    },
    prepareCloseWizard(state) {
      state.close = true;
    },
    updateStepVisibility(state, { payload }) {
      return {
        ...state,
        steps: state.steps.map(step =>
          step.name === payload.name ?
          {...step, visible: payload.visible} :
          step
        )
      };
    },
    closeWizard(state) {
      state = {
        ...wizardInitialState
      }
      return state;
    }
  }
});

export const { setWizardConfig, closeWizard, setWizardStep, setWizardPreValidationStep, prepareCloseWizard, updateStepVisibility } = wizard.actions;

export default wizard.reducer;