import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import Port from './port';
import { fetchSwitchPorts } from '../../../reducers/switchPortsSlice';

const chunk = (arr) => {
  const src = [...arr];
  const dest = [[], [], []];
  while (src.length) {
    dest[0] = dest[0].concat(src.splice(0, 1));
    dest[1] = dest[1].concat(src.splice(0, 1));
    dest[2] = dest[2].concat(src.splice(0, 1));
  }
  return dest;
};

const Ports = ({ ip }) => {
  const dispatch = useDispatch();
  const { ips } = useSelector((state) => state.ports);
  const target = useSelector(state => state.clusters?.target);
  const { data: portData = [], isLoading } = ips[ip] || {};
  const portArrays = chunk(portData);

  useEffect(() => {
    const getSwitchPorts = () => {
      target && dispatch(fetchSwitchPorts(target, ip));
    };
    getSwitchPorts();
    const interval = setInterval(() => getSwitchPorts(), 10 * 1000);
    return () => clearInterval(interval);
  }, [dispatch, ip, target]);

  return (
    <>
      <Spin spinning={isLoading}>
          <Row className={"ports-row"} justify='space-between' gutter={5} align='top' style={{height: '400px', overflow: 'auto'}}>
            {portArrays.map((portArray, index1) => (
              <Col span={7} key={index1} className='ports-column'>
                {portArray.map((port, index2) => (
                  <Port data={port} ip={ip} key={index2} />
                ))}
              </Col>
            ))}
          </Row>
      </Spin>
    </>
  );
};

export default Ports;
