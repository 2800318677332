import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import ShowStatus from '../../components/showstatus';
import { fetchKubeApplications } from '../../reducers/kubeApplicationsSlice';
import { stringSorter } from '../../utils';

const kubeApplicationsColumns = [
  {
    title: 'NAME',
    dataIndex: 'name',
    width: 200,
    sorter: stringSorter('name')
  },
  {
    title: 'SYNC STATUS',
    dataIndex: 'syncStatus',
    width: 100,
    sorter: stringSorter('syncStatus')
  },
  {
    title: 'HEALTH STATUS',
    dataIndex: 'healthStatus',
    width: 100,
    sorter: stringSorter('healthStatus'),
    render: (healthStatus) => <ShowStatus status={healthStatus} />
  }
];

const KubeApplications = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.kubeApplications);
  const target = useSelector(state => state.clusters.target);

  useEffect(() => {
    function getKubeApplications() {
      target && dispatch(fetchKubeApplications(target));
    }
    getKubeApplications();
  }, [target, dispatch]);


  return (
    <div>
      <Header
        title={{
          plural: 'Add-ons',
          singular: 'Add-on'
        }}
        items={data}
        loading={isLoading}
        description='View add-ons and their status'
      />
      <BaseTable
        className={'switch-table'}
        columns={kubeApplicationsColumns}
        data={data}
        loading={isLoading}
        error={error}
        rowKey={'name'}
      />
    </div>
  );
};

export default KubeApplications;
