import { createSlice } from '@reduxjs/toolkit';
import { getVMs, getAllVMs, getDataVolumes } from '../api';
import { calculateCpuUsage } from '../utils';

const vmsInitialState = {
  data: [],
  dataVolumes: [],
  target: undefined,
  isLoading: false,
  error: null
};

const vms = createSlice({
  name: 'vms',
  initialState: vmsInitialState,
  reducers: {
    getVMsStart(state) {
      state.isLoading = true;
    },
    getDataVolumesStart(state) {
      state.isLoading = true;
    },
    getVMsSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.data = data.map((vm, index) => {
          return {
            key: index,
            ...vm,
            cpuUtilised: calculateCpuUsage(vm.cpuUsage, vm.cpu).toFixed(1),
            ramUtilised: Number(vm.memoryUsage || 0).toFixed(1) + 'Mi / ' + vm.ram + 'Mi'
          };
        });
        state.target = state.target || state.data[0].name;
      } else {
        state.data = [];
        state.target = undefined;
      }
    },
    getDataVolumesSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      const data = payload.data;
      if (data.length > 0) {
        state.dataVolumes = data.map((dataVolume, index) => {
          return {
            key: index,
            ...dataVolume,
          };
        });
      } else {
        state.dataVolumes = [];
      }
    },
    getVMsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
      state.target = undefined;
    },
    getDataVolumesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.dataVolumes = [];
    },
    updateVMTarget(state, { payload }) {
      state.target = payload;
      return state;
    }
  }
});

export const { getVMsStart, getVMsSuccess, getVMsFailure, getDataVolumesStart, getDataVolumesSuccess, getDataVolumesFailure, updateVMTarget } = vms.actions;

export default vms.reducer;

export const fetchVMs = (target) => async (dispatch) => {
  try {
    dispatch(getVMsStart());
    const vms = await getVMs(target);
    dispatch(getVMsSuccess(vms));
  } catch (err) {
    console.log(err);
    dispatch(getVMsFailure(err.toString()));
  }
};

export const fetchDataVolumes = (target) => async (dispatch) => {
  try {
    dispatch(getDataVolumesStart());
    const dataVolumes = await getDataVolumes(target);
    dispatch(getDataVolumesSuccess(dataVolumes));
  } catch (err) {
    dispatch(getDataVolumesFailure(err.toString()));
  }
};

export const fetchAllVMs = (target) => async (dispatch) => {
  try {
    dispatch(getVMsStart());
    const vms = await getAllVMs(target);
    dispatch(getVMsSuccess(vms));
  } catch (err) {
    dispatch(getVMsFailure(err.toString()));
  }
};