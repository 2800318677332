import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import Button from 'antd/lib/button/button';

import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import FullscreenOutlined from '@ant-design/icons/FullscreenOutlined';
import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
//import CloudOutlined from '@ant-design/icons/CloudOutlined';

import { useHistory } from 'react-router';
import './easyButtons.scss';

const EasyButtons = () => {
  const target = useSelector(state => state.clusters?.target);
  const history = useHistory();

  const goAddEnvironment = () => {
    history.push('/onboarding/metalEnvironment');
  };

  const goManageBgpPeers = () => {
    history.push('/network/bgppeers');
  };

  const goManageVlans = () => {
    history.push(`/network/vlans`);
  };

  // const goAddCloud = () => {
  //   history.push(`/network/cloud/wizard`);
  // };

  return (

    <div>
      <h4 className='heading'>
        Easy Buttons
      </h4>
      <div className='getting-started-container'>
        <Row gutter={[12, 24]} justify="space-between">
          <Col span={5} className={target ? 'action-col' : 'action-col-animated'}>
            <AppstoreOutlined />
            <br/>
            <Button onClick={goAddEnvironment}>Add an Environment</Button>
          </Col>
          <Col span={5} className="action-col">
            <ApartmentOutlined />
            <br/>
            <Button onClick={goManageVlans}>Manage Vlans</Button>
          </Col>
          <Col span={5} className={'action-col'}>
            <FullscreenOutlined />
            <br/>
            <Button onClick={goManageBgpPeers}>Manage BGP Peers</Button>
          </Col>
          {/* <Col span={5} className={'action-col'}>
            <CloudOutlined />
            <br/>
            <Button onClick={goAddCloud}>Add a Cloud Connection</Button>
          </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default EasyButtons;
