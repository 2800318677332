import axios from 'axios';
import { PROVIDERS } from '../components/constants';

const { AWS, AZURE, GOOGLE, PCCW, MEGAPORT } = PROVIDERS;

let axiosInstance = undefined;

export const axiosCall = config => async (url, method, body) => {
  const fullURL = `${process.env.REACT_APP_API_URL}${url}`;
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      ContentType: 'application/json',
      'frame-meta': JSON.stringify({
        accountId: config.accountId,
        workspaceId: config.workspaceId,
        userMetadata: config.userMetadata
      })
    },
  };

  try {
    switch (method) {
      case 'get':
        return axios.get(fullURL, axiosConfig);
      case 'post':
        return axios.post(fullURL, body, axiosConfig);
      case 'put':
        return axios.put(fullURL, body, axiosConfig);
      case 'delete':
        return axios.delete(fullURL, {
          ...axiosConfig,
          data: body
        });
      default:
        return 'Unsupported method';
    }
  } catch (err) {
    throw err;
  }
};

export const getStatusClass = (status) => {
  let classes = 'status-icon ';
  switch (status) {
    case 'Enabled':
    case 'Connected':
    case 'Healthy':
    case 'Ready':
      classes += 'ok';
      break;
    case 'Disabled':
    case 'Disconnected':
    case 'Net Only':
    case 'No Nodes':
      classes += 'fail';
      break;
    case 'Upgrading':
      classes += 'hold';
      break;
    case 'Standby':
    case 'Pending':
    case 'Provisioning':
    case 'SSH Only':
    case 'Not Ready':
      classes += 'standby';
      break;
    case 'Offline':
      classes += 'offline';
      break;
    default:
      classes += 'unavailable';
      break;
  }
  return classes;
};

export const setupAxios = (config) => {
  axiosInstance = {
    axiosCall: axiosCall(config),
    getStatusClass
  };
}

export const getAxios = () => axiosInstance;

const transformToNumber = (data) => {
  try {
    const value = Number.parseInt(data);
    if (Number.isNaN(value)) {
      return undefined;
    }
    return value;
  } catch {
    return undefined;
  }
}

export const extractPorts = (formData) => {
  const { src_port_range, dst_port_range } = formData;
  let src_port_from = 1, dst_port_from = 1, dst_port_to = 65535, src_port_to = 65535;

  if (src_port_range) {
    ([src_port_from, src_port_to] = src_port_range.split('-').map(transformToNumber));
  }

  if (dst_port_range) {
    ([dst_port_from, dst_port_to] = dst_port_range.split('-').map(transformToNumber));
  }

  return {
    src_port_to,
    src_port_from,
    dst_port_to,
    dst_port_from
  }
};

export const transformPortRange = (initialValues = {}) => {
  let src_port_range = 'all';
  let dst_port_range = 'all';

  if (initialValues.protocol === 'all') {
    return {
      ...initialValues,
      src_port_range,
      dst_port_range,
    }
  }

  if (initialValues.src_port_to != null && initialValues.src_port_to > 0) {
    src_port_range = `${initialValues.src_port_from}-${initialValues.src_port_to}`;
    dst_port_range = `${initialValues.dst_port_from}-${initialValues.dst_port_to}`;
  } else {
    src_port_range = `${initialValues.src_port_from}`;
    dst_port_range = `${initialValues.dst_port_from}`;
  }

  return {
    ...initialValues,
    src_port_range,
    dst_port_range
  }
}

export const isFunction = (obj) => typeof obj === 'function';

export const mergeObjects = (a, b) => ({
  ...removeEmpty(a),
  ...removeEmpty(b)
})

export const removeEmpty = obj => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

export const transformToCredential = data => {
  let transformedData = {
    name: data.name,
    type: data.type,
  };
  if (data.type === AWS) {
    transformedData = {
      ...transformedData,
      key: {
        accessKeyId: data.awsAccessKeyId,
        secretAccessKey: data.awsSecretAccessKey,
      },
      config: {
        region: data.region
      }
    }
  }
  if (data.type === GOOGLE) {
    transformedData = {
      ...transformedData,
      key: data.googleKeyFile,
      config: {
        region: data.region
      }
    }
  }
  if (data.type === AZURE) {
    transformedData = {
      ...transformedData,
      key: {
        value: data.azureKeyFile
      },
      config: {
        subscriptionId: data.azureSubscriptionId,
        servicePrincipal: {
          clientId: data.azureClientId,
          tenantId: data.azureTenantId
        }
      }
    }
  }
  if (data.type === PCCW) {
    transformedData = {
      ...transformedData,
      key: {
        email: data.pccwEmail,
        password: data.pccwPassword,
      },
      config: {
        company: data.pccwCompany
      }
    }
  }
  if (data.type === MEGAPORT) {
    transformedData = {
      ...transformedData,
      key: {
        username: data.megaportUsername,
        password: data.megaportPassword,
      },
      config: {}
    }
  }
  return transformedData;
}

export const stringSorter = (field, option) => (a, b) => {
  const transformedA = option?.transform?.(a[field]) || a[field];
  const transformedB = option?.transform?.(b[field]) || b[field];
  if (String(transformedA).toLocaleLowerCase() < String(transformedB).toLocaleLowerCase()) return -1;
  if (String(transformedA).toLocaleLowerCase() > String(transformedB).toLocaleLowerCase()) return 1;
  return 0;
}

const pad = (text, padding) => {
  const textLength = text.length;
  const paddingLength = padding - textLength;
  return Array(paddingLength > 0 ? paddingLength : 0).fill(0).concat(text).join('');
}

const padIpv4 = (ipv4) => ipv4.split('.').map(value => pad(value, 3)).join('.')
const padIpv6 = (ipv6) => ipv6.split(':').map(value => pad(value, 4)).join(':');

export const ipSorter = (field) => (a, b) => {
  const ipA = a[field];
  const ipB = b[field];

  const ipv6A = ipA.indexOf(':') > -1;
  const ipv6B = ipB.indexOf(':') > -1;

  if (ipv6A && ipv6B) {
    if (padIpv6(ipA) < padIpv6(ipB)) return -1;
    if (padIpv6(ipA) > padIpv6(ipB)) return 1;
    return 0;
  } else if (!ipv6A && !ipv6B) {
    if (padIpv4(ipA) < padIpv4(ipB)) return -1;
    if (padIpv4(ipA) > padIpv4(ipB)) return 1;
    return 0;
  } else if (ipv6A && !ipv6B) {
    return -1;
  }
  return 1;
}

export const calculateCpuUsage = (cpuUsageMetric, cpuCount) => {
  return cpuUsageMetric / (1000 * cpuCount) * 100;
}

// check setup and capcity values of capabilities to see if some creation functionality should be disabled
export const disableCreation = (capabilities) => {
  return !!capabilities && (capabilities?.setupComplete === false || capabilities?.hasCapacity === false)
}

export const miToGi = (sizeMi) => {
  return parseInt(parseInt(sizeMi) / 1024)
}