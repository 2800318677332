import React from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Spin from 'antd/lib/spin';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import RedoOutlined from '@ant-design/icons/RedoOutlined';
import './header.scss';

const Header = (props) => {
  const { title, items, type, buttonName, buttonDisabled, openModal, onSearch, onRefresh, loading } = props;

  return (
    <Row justify='space-between' align='bottom' className='header-breadcrumb'>
      <Col align='bottom'>
        <div className={'header-section'}>
          {items && <div className={'header-item-count'}>{items?.length}</div>}
          <div className={'header-title'}>{items?.length === 1 ? title?.singular : title?.plural }</div>
        </div>
      </Col>
      <Col align='middle' className='d-flex'>
        {onRefresh && <Button className='refresh-button' onClick={onRefresh}>{loading ? <Spin indicator={<RedoOutlined spin />} /> : <RedoOutlined />}</Button>}
        {type === 'button' && buttonName ? (
          <Button className='add-button' onClick={openModal} disabled={buttonDisabled}>
            {buttonName}
          </Button>
        ) : (
          type === 'search' && (
            <Input.Search
              className='search-box'
              placeholder='Search ...'
              prefix={<SearchOutlined />}
              onSearch={onSearch}
              size='large'
              disabled
            />
          )
        )}
      </Col>
    </Row>
  );
};

export default Header;
