import React, { useState } from 'react';
import Row from 'antd/lib/row';

import './tabs.scss';

const Tabs = ({ children, direction }) => {
  const [activeIndex, setActiveIndex] = useState(1);

  const onChangeTab = (idx) => () => {
    setActiveIndex(idx);
  }

  const filteredChildren = children.filter(child => child?.props?.tab);

  return <div className={`tabs-container`}>
    <Row className="tabs-navigation-container">
      <div className="tab-nav-wrapper">
        {
          filteredChildren.map((child, idx) =>
            <div className={`tab-nav ${activeIndex === idx + 1 ? 'active' : ''}`}
                 key={child.props.tab} onClick={onChangeTab(idx + 1)}>
              {child.props.tab}
            </div>)
        }
      </div>
      {filteredChildren[activeIndex - 1]?.props?.actionComponent ? filteredChildren[activeIndex - 1]?.props?.actionComponent : <></>}
    </Row>
    <Row className={`${direction ? `tab-direction-${direction}` : ''}`}>
      {filteredChildren[activeIndex - 1]}
    </Row>
  </div>
};

export const TabPane = ({ children }) => <>{children}</>;

export default Tabs;