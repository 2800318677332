import { createSlice } from '@reduxjs/toolkit';
import { getPCCWCompanies } from '../api';

const pccwInitialState = {
  data: [],
  isLoading: 0,
  error: null
};

const pccw = createSlice({
  name: 'pccw',
  initialState: pccwInitialState,
  reducers: {
    resetPCCWState(state) {
      state = {
        ...pccwInitialState
      }
      return state;
    },
    getPCCWStart(state) {
      state.isLoading = true;
      state.error = undefined;
    },
    getPCCWSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = (payload.pccw?.data || [])
    },
    getPCCWFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const {
  resetPCCWState,
  getPCCWStart,
  getPCCWSuccess,
  getPCCWFailure
} = pccw.actions;

export default pccw.reducer;

export const fetchPCCWCompanies = (target, email, password) => async (dispatch) => {
  try {
    dispatch(getPCCWStart());
    const pccw = await getPCCWCompanies(target, email, password);
    dispatch(getPCCWSuccess({ pccw }));
  } catch (err) {
    dispatch(getPCCWFailure(err.toString()));
  }
};
