import React, { useState } from 'react';
import Search from '../../components/search';
import BaseTable from '../../components/table';

const LoadBalancers = ({ data = {}, isLoading }) => {
  const [filter, setFilter] = useState(false);

  const onSearch = (val) => {
    setFilter(val);
  };

  const filteredData = filter.length > 0 ? data.filter((el) => {
    return el.name.toLowerCase().includes(filter.toLowerCase());
  }) : data;

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      sorter: (a, b) => a.name > b.name,
      width: 150
    },
    {
      title: 'IP ADDRESS',
      dataIndex: 'ipAddress',
      sorter: (a, b) => a.ip > b.ip,
      width: 150,
      render: ipAddress => (
        <>
        {
          ipAddress && ipAddress.map((ip) => `${ip.ip}`).join(', ')
        }
        </>
      )
    },
    {
      title: 'PORTS',
      dataIndex: 'ports',
      sorter: (a, b) => a.health > b.health,
      width: 150,
      render: ports => (
        <>
        {
          ports && ports.map((port) => `${port.protocol}:${port.port}`).join(', ')
        }
        </>
      )
    },
  ];

  return (
    <div className='node-container'>
      <div className='header'>
        <div className='row'>
          <div className='node-header-description'>
            <span className='item-count'>{data.length || 0}</span> Load Balancers
          </div>
          <Search onSearch={onSearch} />
        </div>
      </div>
      <div className='node-list'>
        <BaseTable
          columns={columns}
          data={filteredData || []}
          className='node-table'
          pageSize={5}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default LoadBalancers;
