import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import './modal.scss';

const DeleteItemModal = ({ visible, title, loading, onDelete, onCancel, deleteNestedCluster }) => {
  const [deleteValue, setDeleteValue] = useState('');
  const [incorrectInput, setIncorrectInput] = useState(false);
  const { data: clusterDeleteInfo } = useSelector((state) => state.kubernetesAssets);

  const onInputChange = (evt) => {
    setDeleteValue(evt.target.value);
    if (incorrectInput) {
      setIncorrectInput(false);
    }
  }

  const onClickDelete = () => {
    if (deleteValue === 'delete') {
      if (deleteNestedCluster) {
        setDeleteValue('');
        onDelete(clusterDeleteInfo);
      } else {
        setDeleteValue('');
        onDelete();
      }
    } else {
      setIncorrectInput(true);
    }
  }

  const handleCancel = () => {
    setDeleteValue('');
    setIncorrectInput(false);
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      title={`Delete ${title}`}
      onOk={onDelete}
      onCancel={handleCancel}
      width={500}
      footer={[
        <Button key='submit' loading={loading} type='danger' onClick={onClickDelete}>
          Yes
        </Button>,
        <Button key='cancel' type='secondary' onClick={handleCancel}>
          Cancel
        </Button>
      ]}
    >
      <div className='delete-body'>
        <p className='delete-network-modal-text'>Are you sure you want to delete this?</p>
        {deleteNestedCluster && clusterDeleteInfo ? 
          <div className='delete-cluster-info-container'>
            {clusterDeleteInfo?.loadBalancers?.length > 0 ? 
              clusterDeleteInfo?.loadBalancers.map((loadBalancer) => {
                return <p className='delete-cluster-info-text'>{loadBalancer?.name}</p>
              })
            : ''
            }
            <p className='delete-cluster-info-heading'>Pods:</p>
            {clusterDeleteInfo?.pods?.length > 0 ? 
              clusterDeleteInfo?.pods.map((pod) => {
                return <p className='delete-cluster-info-text'>{pod}</p>
              })
            : ''
            }
            <p className='delete-cluster-info-heading'>Namespaces:</p>
            {clusterDeleteInfo?.namespaces?.length > 0 ? 
              clusterDeleteInfo?.namespaces.map((namespace) => {
                return <p className='delete-cluster-info-text'>{namespace?.name}</p>
              })
            : ''
            }
            <p className='delete-cluster-info-heading'>VMs:</p>
            {clusterDeleteInfo?.vms?.length > 0 ? 
              clusterDeleteInfo?.vms.map((vm) => {
                return <p className='delete-cluster-info-text'>{vm?.name}</p>
              })
            : ''
            }
            <p className='delete-cluster-info-heading'>DataVolumes:</p>
            {clusterDeleteInfo?.dvs?.length > 0 ? 
              clusterDeleteInfo?.dvs.map((dv) => {
                return <p className='delete-cluster-info-text'>{dv?.name}</p>
              })
            : ''
            }
          </div> :
          <></>
        }
        <p>Please enter <b>delete</b> below to confirm</p>
        <Input
          placeholder={'delete'}
          onChange={onInputChange}
          value={deleteValue}
          style={{
            borderColor: incorrectInput ? 'rgba(255,0,0,0.8)' : '',
          }}
        />
      </div>
    </Modal>
  );
};

export default DeleteItemModal;
