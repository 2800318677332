import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Spin, Tooltip } from 'antd';
import { ExclamationCircleOutlined, PlayCircleOutlined, StopOutlined, CloseCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';

import { updateBreadcrumbs } from '../../reducers/breadcrumbSlice';
import ClusterFileUploadModal from '../../components/modals/clusterFileUploadModal';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import AddVMModal from '../../components/modals/addVMModal';
import ResizeVMDiskModal from '../../components/modals/resizeVMDiskModal';
import { fetchVMs, fetchDataVolumes } from '../../reducers/vmsSlice';
import { fetchKubernetes } from '../../reducers/kubernetesSlice';
import { fetchCapacity } from '../../reducers/capacitySlice';
import Header from '../../components/header';
import Events from './events';
import { uploadFile, playVirtualMachine, stopVirtualMachine, restartVirtualMachine, deleteVirtualMachine, getVNCToken, updateVirtualMachine, postDataVolume, resizeVmDisk, resizeVmDiskStatus } from '../../api';
import notification from '../../utils/notification';
import { miToGi } from '../../utils';

import './index.scss';

const VMDetails = () => {
  const history = useHistory();
  const params = useParams();
  const { token, accountId, workspaceId, userMetadata } = useSelector((state) => state.config);
  const { name } = params;
  const { data, isLoading } = useSelector((state) => state.vms);
  const [vmDetail, setVMDetail] = useState({});
  const [toggleVisible] = useState(false);
  const [isUpdating, toggleIsUpdating] = useState(false);
  const [updateVisible, toggleUpdateVisible] = useState(false);
  const [resizeVisible, toggleResizeVisible] = useState(false);
  const [isResizing, toggleIsResizing] = useState(false);
  const [resizingState, setResizingState] = useState('');

  const dispatch = useDispatch();
  const { target } = useSelector((state) => state.clusters);
  const { data: namespaces } = useSelector((state) => state.kubernetes);
  const { data: capacity } = useSelector((state) => state.capacity);
  const [visibleFileUploadModal, toggleVisibleFileUpload] = useState(false);
  const [isUploading, toggleIsUploading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const goViewVMs = () => {
    history.push('/vms');
  };

  useEffect(() => {
    function getVMInformation() {
      dispatch(fetchVMs(target))
      dispatch(fetchCapacity(target));
    }
    getVMInformation();
    const interval = setInterval(() => getVMInformation(), 10 * 1000);
    return () => clearInterval(interval);
  }, [target, dispatch]);

  useEffect(() => {
    const detail = data.find((el) => el.name === name);
    setVMDetail(detail);
  }, [dispatch, target, data, name]);

  useEffect(() => {
    async function getResizeStatus() {
      if (isResizing) {
        const resp = await resizeVmDiskStatus(target, vmDetail?.namespace, vmDetail?.vmId);
        setResizingState(resp.data);
        if (resizingState === 'COMPLETE') {
          toggleIsResizing(false);
        }
      }
    }
    const interval = setInterval(() => getResizeStatus(), 10 * 1000);
    return () => clearInterval(interval);

  }, [dispatch, target, name, data, isResizing, resizingState, vmDetail?.namespace, vmDetail?.vmId]);

  useEffect(() => {
    dispatch(updateBreadcrumbs([
      {
        title: 'Virtual Machines',
        link: '/vms'
      }, {
        title: 'Virtual Machine Details'
      }
    ]))
    dispatch(fetchKubernetes(target))
    return () => {
      dispatch(updateBreadcrumbs([]));
    }
  }, [dispatch, target]);

  const handleUpdate = async (form) => {
    toggleIsUpdating(true);
    await updateVirtualMachine(target, vmDetail.namespace, vmDetail.vmId, {
      cdIsoPVC: form.getFieldValue('cdIsoPVC'),
      cores: form.getFieldValue('cores'),
      memory: form.getFieldValue('memory'),
      description: form.getFieldValue('description'),
      vlanAttachments: form.getFieldValue('vlanAttachments'),
      vxlanAttachments: form.getFieldValue('vxlanAttachments')
    });
    dispatch(fetchVMs(target, vmDetail.namespace));
    toggleIsUpdating(false);
    closeUpdateModal();
  }

  const handleResize = async (form) => {
    try {
      toggleIsResizing(true);
      await resizeVmDisk(target, vmDetail.namespace, vmDetail.vmId, {
        size: form.getFieldValue('storage') * 1024,
        volumeName: vmDetail.volumes.find((volume) => volume.name === vmDetail.disk?.name)?.persistentVolumeClaim?.claimName
      });
      closeResizeModal()
      setResizingState('RESIZING');
      notification.success({
        message: 'Resize in progress',
        placement: 'bottomRight'
      });
    } catch (error) {
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
      toggleIsResizing(false);
      setResizingState('');
    }
  }

  const handleHttpUpload = (form) => {
    toggleIsUploading(true);
    const { kubernetes, name, url, size } = form.getFieldsValue();
    return postDataVolume({
      target,
      clusterId: kubernetes,
      form: { name: name, url: url, size: size },
    }).then((res) => {
      toggleIsUploading(false);
      if (res.status === 200) {
        closeModal();
        form.resetFields();
        dispatch(fetchDataVolumes(target));
        notification.success({
          message: 'New DataVolume has been added',
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
    }).catch(e => {
      if (e.response?.data) {
        notification.error({
          message: e.response?.data,
          placement: 'bottomRight'
        });
      } else {
        notification.error({
          message: 'Something went wrong, please try again later',
          placement: 'bottomRight'
        });
      }
      toggleIsUploading(false);
    });
  };

  const handleIsoUpload = async (form, clusterId) => {
    toggleIsUploading(true);
    try {
      await uploadFile(target, clusterId, form);
      dispatch(fetchDataVolumes(target));
      notification.success({
        message: 'New DataVolume has been added',
        placement: 'bottomRight'
      });
      form.resetFields();
      closeModal();
    } catch (error) {
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    }
    toggleIsUploading(false);
  }

  const closeModal = () => {
    toggleVisible(false);
  };

  const onCancelDelete = () => {
    setIsDeleteModalVisible(false);
  }

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  }

  const closeUpdateModal = () => {
    toggleUpdateVisible(false);
  };

  const openUpdateModal = () => {
    toggleUpdateVisible(true);
  };

  const closeResizeModal = () => {
    toggleResizeVisible(false);
  };

  const openResizeModal = () => {
    toggleResizeVisible(true);
  };

  const playVM = async () => {
    await playVirtualMachine(target, vmDetail.namespace, vmDetail.vmId);
    dispatch(fetchVMs(target, vmDetail.namespace))
  }

  const stopVM = async () => {
    await stopVirtualMachine(target, vmDetail.namespace, vmDetail.vmId);
    dispatch(fetchVMs(target, vmDetail.namespace))
  }

  const restartVM = async () => {
    await restartVirtualMachine(target, vmDetail.namespace, vmDetail.vmId);
    dispatch(fetchVMs(target, vmDetail.namespace))
  }

  const deleteVM = async () => {
    try {
      setDeleteLoading(true);
      await deleteVirtualMachine(target, vmDetail.namespace, vmDetail.vmId);
      //dispatch(fetchVMs(target, vmDetail.namespace))
      setDeleteLoading(false);
      setIsDeleteModalVisible(false);
      history.push('/vms');
    } catch (e) {
      notification.error({
        message: 'Something went wrong. Please try again later'
      })
    }
    setDeleteLoading(false);
  }

  const openVNCViewer = async () => {
    const { data } = await getVNCToken(target, vmDetail.namespace, vmDetail.vmId);
    const wsUrl = btoa(data.wsUrl);
    window.open(`/vnc-view/?accessToken=${token}&accountId=${accountId}&workspaceId=${workspaceId}&userMetadata=${encodeURIComponent(userMetadata)}&wsUrl=${wsUrl}`, "", "width=1280, height=720");
  }

  return (
    <div className="cluster-container">
      <Header
        items={undefined}
        title={undefined}
        description=' '
      />
        {true ? <>
          <h2 style={{ color: '#006dc9' }}>{vmDetail ? vmDetail.name : ''}</h2>
          <Row justify='space-between' className='vm-stats-info'>
            <Spin spinning={isLoading}>
              <Row justify='space-between' className='stats-container'>
                <Col span={8} className='stats-info-block'>
                  <div className='stats-info-description'>
                    <div className='stats-value'>{vmDetail?.cpuUtilised || 0}%</div>
                    <div className='stats-sub-title'>CPU Usage</div>
                  </div>
                </Col>
                <Col span={8} className='stats-info-block'>
                  <div className='stats-info-description'>
                    <div className='stats-value'>{vmDetail?.memoryUsage ? vmDetail.memoryUsage.toFixed(1) : 0}Mi</div>
                    <div className='stats-sub-title'>Memory Usage</div>
                  </div>
                </Col>
                <Col span={8} className='stats-info-block'>
                  <div className='stats-info-description'>
                    <div className='stats-value'>{vmDetail?.status || 'Unknown'}</div>
                    <div className='stats-sub-title'>Status</div>
                  </div>
                </Col>
              </Row>
            </Spin>
            <Row justify='space-between' className='vm-actions-container'>
              <Col span={18} className='action-container'>
                <Button
                  icon={<PlayCircleOutlined />}
                  onClick={playVM}
                  disabled={vmDetail?.status === 'Running'}
                  className='action-btn'
                >Start</Button>
                <Button
                  icon={<StopOutlined />}
                  onClick={stopVM}
                  disabled={vmDetail?.status === 'Stopped'}
                  className='action-btn'
                >Stop</Button>
                <Button
                  icon={<RedoOutlined />}
                  onClick={restartVM}
                  disabled={vmDetail?.status !== 'Running'}
                  className='action-btn'
                >Restart</Button>
              </Col>
              <Col span={6} className='destroy-container'>
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={showDeleteModal}
                  className='destroy-btn'
                >Delete</Button>
              </Col>
            </Row>
          </Row>
          <Row justify='space-between' gutter={40}>
            <Col span={12}>
              <div className='vm-details-container'>
                <Spin spinning={isLoading}>
                  <Row justify='space-between'>
                    <Col span={11} className='stats-info-block'>
                    <div className='stats-info-description'>
                        <div className='stats-value'>{vmDetail?.cpu || ''}</div>
                        <div className='stats-sub-title'>vCPUs</div>
                      </div>
                    </Col>
                    <Col span={11} className='stats-info-block'>
                      <div className='stats-info-description'>
                        <div className='stats-value'>{vmDetail?.ram || 0}Mi</div>
                        <div className='stats-sub-title'>Available RAM</div>
                      </div>
                    </Col>
                    <Col span={11} className='stats-info-block'>
                      <div className='stats-info-description'>
                        <div className='stats-value'>{vmDetail?.ipAddresses || ''}</div>
                        <div className='stats-sub-title'>IP Address</div>
                      </div>
                    </Col>
                    <Col span={11} className='stats-info-block'>
                      <Spin spinning={isResizing}>
                        <div className='stats-info-description'>
                          <div className='stats-value'>{ miToGi(vmDetail?.disk?.size) + 'Gi' || ''}</div>                        
                          <div className='stats-sub-title'>HDD size</div>
                        </div>
                      </Spin>
                    </Col>
                    {vmDetail?.interfaces ? vmDetail.interfaces.map((interfc) =>
                      interfc.name === 'default' ?
                      <Col span={11} className='stats-info-block'>
                        <div className='stats-info-description'>
                          <div className='stats-value'>
                            <span>{interfc.name}</span>
                            <br/>
                            <span>{interfc.mac}</span>
                            <br/>
                            <span>{interfc.ipAddresses?.join(', ')}</span>
                          </div>
                          <div className='stats-sub-title'>Network ({interfc.name})</div>
                        </div>
                      </Col>
                      : ''
                    ) : ''}
                    <Col span={24} className='stats-info-block'>
                      <div className='stats-info-description'>
                        <div className='stats-value'>{vmDetail?.description || 'No description available'}</div>
                        <div className='stats-sub-title'>Description</div>
                      </div>
                    </Col>
                  </Row>
                </Spin>

                <div className='vm-actions-container'>
                  <Row className="details">
                    <Button
                      className='action-btn'
                      onClick={() => {
                        toggleVisibleFileUpload(!visibleFileUploadModal)
                      }}
                    >
                      ISO Upload
                    </Button>
                    <Button
                      className='action-btn'
                      onClick={openVNCViewer}
                      disabled={vmDetail?.status !== 'Running'}
                    >
                      VNC
                    </Button>
                    <Button
                      className='action-btn'
                      onClick={openUpdateModal}
                    >
                      Update
                    </Button>
                    <Tooltip title={vmDetail?.status === "Running" ? 'VM must be stopped before resizing the disk': undefined}>
                      <Button
                        className='action-btn'
                        onClick={openResizeModal}
                        disabled={vmDetail?.status === "Running"}
                      >
                        Resize
                      </Button>
                    </Tooltip>
                  </Row>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <Events data={vmDetail?.events} />
            </Col>
          </Row>
        </> :
        <div className='machine-not-available'>
          <div className='message-container'>
            <ExclamationCircleOutlined />
            The machine you are trying to access is not available. Please go back and try again.
          </div>
          <Button className='view-machines-btn' type='primary' onClick={goViewVMs}>View Virtual Machines</Button>
        </div>
      }
      <DeleteItemModal
        title={vmDetail?.name || 'VM'}
        visible={isDeleteModalVisible}
        loading={deleteLoading}
        onCancel={onCancelDelete}
        onDelete={deleteVM}
      />
      <AddVMModal
        data={vmDetail}
        visible={updateVisible}
        loading={isUpdating}
        handleComplete={handleUpdate}
        namespaces={namespaces}
        onCancel={closeUpdateModal}
        target={target}
        capacityLimits={{
          maxCores: capacity.maxCores,
          maxMem: capacity.maxMem
        }}
      />
      <ResizeVMDiskModal
        data={vmDetail}
        visible={resizeVisible}
        loading={isResizing}
        handleComplete={handleResize}
        namespaces={namespaces}
        onCancel={closeResizeModal}
        target={target}
        capacityLimits={{
          maxCores: capacity.maxCores,
          maxMem: capacity.maxMem
        }}
      />
      <ClusterFileUploadModal
        target={target}
        visible={visibleFileUploadModal}
        loading={isUploading}
        kubernetes={namespaces}
        handleIsoUpload={handleIsoUpload}
        handleHttpUpload={handleHttpUpload}
        onCancel={() => toggleVisibleFileUpload(false)}
      />
    </div>
  );
};

export default VMDetails;
