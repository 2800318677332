import React, { useState, useMemo } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Form from 'antd/lib/form';
import useForm from 'antd/lib/form/hooks/useForm';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import { useSelector } from 'react-redux';
import { addNat } from '../../../api';
import { useHistory, useParams } from 'react-router';
import notification from '../../../utils/notification';
import BaseWizard from '../baseWizard';
import { mergeObjects, transformPortRange } from '../../../utils';
import '../index.scss';
import './addNat.scss';
import portRangeValidator from '../../validators/portRangeValidator';
import portValidator from '../../validators/portValidator';
import WrappedSelect from '../../select';

const { Option } = Select;

const actionOptions = [
  {
    name: 'ACCEPT',
    value: 'ACCEPT_SNAT',
  },
  {
    name: 'DNAT',
    value: 'DNAT',
  },
  {
    name: 'SNAT',
    value: 'SNAT',
  }
]

const protocolOptions = [
  {
    name: 'ALL',
    value: 'all',
  },
  {
    name: 'TCP',
    value: 'tcp',
  },
  {
    name: 'UDP',
    value: 'udp',
  },
  {
    name: 'ICMP',
    value: 'icmp',
  }
]

const AddNATWizard = () => {
  const [selectedAction, setSelectedAction] = useState('ACCEPT_SNAT');
  const [selectedProtocol, setSelectedProtocol] = useState('all');
  const [currentData, setCurrentData] = useState({});
  const [loading, setLoading] = useState(false);
  const target = useSelector(state => state.clusters?.target);
  const { data } = useSelector(state => state.nats);

  const params = useParams();
  const history = useHistory();

  const [form] = useForm();

  const initialValues = params?.wizardId ?
    transformPortRange(data?.find(nat => String(nat.id) === String(params.wizardId)) || {}) : {
    protocol: 'all',
    action: 'ACCEPT_SNAT',
    src_port_range: '1-65535',
    dst_port_range: '1-65535',
    status: 'enabled'
  };

  const onChangeAction = value => {
    setSelectedAction(value);
  }

  const onChangeProtocol = value => {
    setSelectedProtocol(value);
  }

  const wizardSteps = useMemo(() => [
    {
      title: 'Fill in NAT protocol & action',
      name: 'Protocol & Actions',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
    {
      title: 'Fill in NAT details',
      name: 'Details',
      description: '',
      validation: () => {
        return form.validateFields().then(() => {
          return true;
        }).catch(() => false);
      },
    },
  ], [form]);

  const onCancelWizard = () => {
    history.push('/network/nat');
  }

  const onComplete = async () => {
    // get form data and send
    setLoading(true);
    const formData = form.getFieldsValue();
    let combinedData = mergeObjects(currentData, formData);

    let transformedData = {
      name: combinedData.name,
    }

    setLoading(false);

    addNat(target, transformedData).then(() => {
      notification.success({
        message: 'New NAT added.'
      });
      setLoading(false);
      setCurrentData({});
      history.push('/network/nat');
    }).catch(() => {
      setLoading(false);
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    });
  }

  const onStepChange = () => {
    // We do this so we can expand on the field values since form resets the field value for each step
    setCurrentData(mergeObjects(currentData, form.getFieldsValue()));
  }

  return <>
    <BaseWizard
      name={'Create NAT'}
      stepName={'NAT'}
      onComplete={onComplete}
      onCancelWizard={onCancelWizard}
      steps={wizardSteps}
      loading={loading}
      onStepChange={onStepChange}
    >
      {(currentStep, setCurrentStep) =>
        <Form form={form} layout='vertical' initialValues={initialValues} requiredMark={'optional'}>
          <Row gutter={24}>
            {currentStep === 0 &&
            <>
              <Col span={12} className={'wizard-col'}>
                <Form.Item
                  name='name'
                  className='modal-item-label'
                  label='Name'
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input className='modal-item-controller' placeholder='Name' />
                </Form.Item>
              </Col>
              <Col span={12} className={'wizard-col'}>
                <Form.Item
                  name='status'
                  className='modal-item-label'
                  label='Status'
                  rules={[{ required: true, message: 'Status is required' }]}
                >
                  <WrappedSelect className='modal-item-controller'>
                    <Option value='enabled'>Enabled</Option>
                    <Option value='disabled'>Disabled</Option>
                  </WrappedSelect>
                </Form.Item>
              </Col>
              <Col span={12} className={'wizard-col'}>
                <Form.Item
                  name='protocol'
                  className='modal-item-label'
                  label={'Protocol'}
                  rules={[{ required: true, message: 'Protocol is required' }]}
                >
                  <WrappedSelect className='modal-item-controller' onChange={onChangeProtocol}>
                    {protocolOptions.map((protocolOption) => (
                      <Option key={protocolOption.value} value={protocolOption.value}>
                        {protocolOption.name}
                      </Option>
                    ))}
                  </WrappedSelect>
                </Form.Item>
              </Col>
              <Col span={12} className={'wizard-col'}>
                <Form.Item
                  name='action'
                  className='modal-item-label'
                  label={'Action'}
                  rules={[{ required: true, message: 'Action is required' }]}
                >
                  <WrappedSelect className='modal-item-controller' onChange={onChangeAction}>
                    {actionOptions.map((actionOptions) => (
                      <Option key={actionOptions.value} value={actionOptions.value}>
                        {actionOptions.name}
                      </Option>
                    ))}
                  </WrappedSelect>
                </Form.Item>
              </Col>
            </>
            }
            {currentStep === 1 && (
              <>
                <Col span={12} style={{
                  marginBottom: 10,
                }}>
                  <span className='static-text'>SOURCE</span>
                </Col>
                <Col span={12} style={{
                  marginBottom: 10,
                }}>
                  <span className='static-text'>DESTINATION</span>
                </Col>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='source'
                    className='modal-item-label'
                    label='IP Address'
                    rules={[{ required: true, message: 'Source is required' }]}
                  >
                    <Input className='modal-item-controller' placeholder='0.0.0.0/0' />
                  </Form.Item>
                </Col>
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='destination'
                    className='modal-item-label'
                    label='IP Address'
                    rules={[{ required: true, message: 'Destination is required' }]}
                  >
                    <Input className='modal-item-controller' placeholder='0.0.0.0/0' />
                  </Form.Item>
                </Col>
                {(selectedProtocol === 'TCP' || selectedProtocol === 'UDP') &&
                <Col span={12} className={'modal-col'}>
                  <Form.Item
                    name='src_port_range'
                    className='modal-item-label'
                    label='Port Range'
                    rules={[{ required: true, message: 'Source Port Range is required' }, portRangeValidator]}
                  >
                    <Input className='modal-item-controller' placeholder='1' />
                  </Form.Item>
                </Col>
                }
                {(selectedProtocol === 'TCP' || selectedProtocol === 'UDP') ?
                  (selectedAction !== 'DNAT' ? (
                  <Col span={12} className={'modal-col'}>
                    <Form.Item
                      name='dst_port_range'
                      className='modal-item-label'
                      label='Port Range'
                      rules={[{ required: true, message: 'Destination Port Range is required' }, portRangeValidator]}
                    >
                      <Input className='modal-item-controller' placeholder='1' />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={12} className={'modal-col'}>
                    <Form.Item
                      name='dst_port'
                      className='modal-item-label'
                      label='Destination Port'
                      rules={[{ required: true, message: 'Port is required', portValidator }]}
                    >
                      <Input className='modal-item-controller' placeholder='Port' />
                    </Form.Item>
                  </Col>
                )) : <></>}
              </>
            )}
          </Row>
        </Form>
      }
    </BaseWizard>
  </>
}

export default AddNATWizard;