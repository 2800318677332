import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'antd/lib/card';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Spin from 'antd/lib/spin';
import Avatar from 'antd/lib/avatar';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import NotificationTwoTone from '@ant-design/icons/NotificationTwoTone';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { fetchNotification, setNotificationReadTimestamp } from '../../../reducers/notificationSlice';

import './messages.scss';

const Messages = () => {
  const { notificationClusters } = useSelector((state) => state.notification);
  const target = useSelector(state => state.clusters?.target);
  const dispatch = useDispatch();

  const { data, isLoading, error } = notificationClusters[target] || {};

  useEffect(() => {
    const getNotifications = () => {
      target && dispatch(fetchNotification(target));
    }
    getNotifications();
    const interval = setInterval(() => getNotifications(), 30 * 1000);
    return () => {
      clearInterval(interval);
    }
  }, [target, dispatch]);

  const handleMessageClick = () => {
    dispatch(setNotificationReadTimestamp({ target, timestamp: new Date().getTime() }));
  };

  const emptyMessage = !!error || data?.length === 0 || !data;

  return (
    <Card
      title='Messages'
      headStyle={{
        color: 'black',
        fontSize: '16px',
        fontWeight: '600',
      }}
      bodyStyle={{
        alignItems: isLoading || emptyMessage ? 'center': 'start',
      }}
      className='messages-card'
      extra={[<Button key={`messages-button-view-all`} onClick={handleMessageClick} className="card-header-view-all">Mark as Read</Button>]}
    >
      <Spin spinning={!!isLoading}>
        {emptyMessage ? (
          <div>No messages available</div>
        ) : (
          <div>
            {data &&
              data.length > 0 &&
              data.map((item) => (
                  <Row
                    key={item.id}
                    justify='space-between'
                    align='middle'
                    className={`message-row`}
                  >
                    <Col className='message-content'>
                      {item.img ? <Avatar src={item.img} /> : item.isRead ? <NotificationOutlined style={{ color: 'lightgrey' }}/> : <NotificationTwoTone />}
                      <div>
                        <span className='message-content__title'>{item.title}</span>
                        <span className='message-content__body'>{item.detail}</span>
                      </div>
                    </Col>
                    {item.timestamp && <Col className='message-date'>
                      <div>{item.timestamp && formatDistanceToNow(new Date(item.timestamp))}</div>
                    </Col>}
                  </Row>
                ))}
          </div>
        )}
      </Spin>
    </Card>
  );
};

export default Messages;
