import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router';
import RFB from '@novnc/novnc';

import notification from '../../utils/notification';
import './styles.scss';

const VNCViewer = () => {
  const [rfb, setRFB] = useState(null);
  const [status, setStatus] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (params && params.wsUrl && !status) {
      try {
        let obj = new RFB(document.getElementById('vnc-viewer'), atob(params.wsUrl));
        obj.addEventListener('connect', () => {
          notification.success({
            message: 'Connect to Virtual Machine',
            placement: 'bottomRight',
          });
        });

        obj.addEventListener('disconnect', () => {
          if (!rfb) return;
          rfb.disconnect();
          setRFB(null);
          setStatus(false);
        });

        obj.scaleViewport = obj.resizeSession = true;
        setRFB(obj);
        setStatus(true);
      } catch (err) {
        notification.error({
          message: 'Unable to create RFB client:',
          placement: 'bottomRight',
        })
      }
    }
  }, [status, params, rfb])

  const ctrlAltDel = () => {
    if (!rfb) return;
    rfb.sendCtrlAltDel();
  }

  return (
    <div className='vnc-container'>
      <div className='vnc-btn-bar'>
        <Button 
          onClick={ctrlAltDel}
          className='vnc-btn'
        >
          Ctrl-Alt-Del
        </Button>
      </div>
      <div id="vnc-viewer" style={{ width: '100vw', height: 'calc(100vh - 25px)' }}/>
    </div>
  );
};

export default VNCViewer;
