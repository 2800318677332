import React  from 'react';
import { Modal, Button, Form, Input, Row, Col, Select } from 'antd';
import './modal.scss';

const { Option } = Select;

const EditClusterModal = ({ visible, loading, onEdit, onCancel, existingData = {} }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title='Edit Environment'
      onOk={onEdit}
      onCancel={onCancel}
      width={500}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then(onEdit)
              .catch((errorInfo) => console.log(errorInfo));
          }}
        >
          Update
        </Button>,
        <Button
          key='cancel'
          type='secondary'
          onClick={onCancel}
        >
          Cancel
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' initialValues={existingData} requiredMark={'optional'}>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='clusterName'
              className='modal-item-label'
              label='Environment Name'
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input className='modal-item-controller' placeholder='Name' />
            </Form.Item>
          </Col>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='expectedCount'
              className='modal-item-label'
              label='Node Count'
              rules={[{ required: true, message: 'Node count is required' }]}
            >
              <Select>
                <Option value='<128'>{'<128'}</Option>
                <Option value='128'>128</Option>
                <Option value='256'>256</Option>
                <Option value='512'>512</Option>
                <Option value='1024'>1024</Option>
                <Option value='2048'>2048</Option>
                <Option value='4096'>4096</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditClusterModal;
