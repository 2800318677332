import { createSlice } from '@reduxjs/toolkit';

const breadcrumbsInitialState = {
  crumbs: []
};

const breadcrumbs = createSlice({
  name: 'breadcrumbs',
  initialState: breadcrumbsInitialState,
  reducers: {
    updateBreadcrumbs(state, { payload = [] }) {
      state.crumbs = payload;
    },
  }
});

export const { updateBreadcrumbs } = breadcrumbs.actions;

export default breadcrumbs.reducer;