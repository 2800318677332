import React from 'react';
import './index.scss';

const ExpandedBootKeys = (record, index, indent, expanded) => {
  const providers = [
    {
      name: 'Default',
      url: `http://boot.fluidhq.io/pxe/${record.uuid}/default/boot.ipxe`
    },
    {
      name: 'Equinix',
      url: `http://boot.fluidhq.io/pxe/${record.uuid}/equinix/boot.ipxe`
    }
  ]

  return (
    expanded && providers.map((provider) => {
      return <p>{provider.name} : {provider.url}</p>
    })
  );
};

export default ExpandedBootKeys;
