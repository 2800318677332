import { createSlice } from '@reduxjs/toolkit';
import { getClusterState } from '../api';

const clusterStateInitialState = {
  data: [],
  isLoading: false,
  error: null
};

const clusterState = createSlice({
  name: 'clusterState',
  initialState: clusterStateInitialState,
  reducers: {
    getClusterStateState(state) {
      state.isLoading = true;
    },
    getClusterStateSuccess(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.data = payload.data || [];
    },
    getClusterStateFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    }
  }
});

export const { getClusterStateState, getClusterStateSuccess, getClusterStateFailure } = clusterState.actions;

export default clusterState.reducer;

export const fetchClusterState = (target) => async (dispatch) => {
  try {
    dispatch(getClusterStateState());
    const clusterState = await getClusterState(target);
    dispatch(getClusterStateSuccess(clusterState));
  } catch (err) {
    dispatch(getClusterStateFailure(err.toString()));
  }
};
