import React, { useEffect, useState, useMemo } from 'react';
import Button from 'antd/lib/button/button';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import BaseVlansModal from '../../components/modals/baseVlansModal';
import DeleteItemModal from '../../components/modals/deleteItemModal';
import { useDispatch, useSelector } from 'react-redux';
import { createVlan, deleteVlan } from '../../api';
import notification from '../../utils/notification';
import { fetchVlans } from '../../reducers/vlansSlice';
import { stringSorter } from '../../utils';

import './index.scss';

const VLANs = () => {
  const [visible, toggleVisible] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [visibleDeleteModal, toggleVisibleDeleteModal] = useState(false);
  const [current, setCurrent] = useState(undefined);

  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(state => state.vlans);
  const target = useSelector(state => state.clusters?.target);

  useEffect(() => {
    target && dispatch(fetchVlans(target));
  }, [dispatch, target]);

  const vlanColumns = useMemo(
    () => [
      {
        title: 'NAME',
        dataIndex: 'name',
        width: 250,
        sorter: stringSorter('name')
      },
      {
        title: 'VLAN',
        dataIndex: 'vlanId',
        width: 250,
        sorter: stringSorter('name')
      },
      {
        title: '',
        dataIndex: '',
        key: 'action',
        width: 50,
        className: 'action-column',
        render: (_, record) => {
          return <Button onClick={openDeleteModal(record)}>Delete</Button>;
        }
      }
    ],
    []
  );

  const closeModal = () => {
    setCurrent(undefined);
    toggleVisible(false);
  };

  const openModal = () => {
    toggleVisible(true);
  };

  const handleCreateOrUpdate = (form) => {
    toggleLoading(true);
    const name = form.getFieldValue('name')
    const vlanId = form.getFieldValue('vlanId')

    return createVlan(target, name, vlanId).then(() => {
      toggleLoading(false);
      closeModal();
      handleUpdate();
      notification.success({
        message: 'New VLAN added.'
      })
    }).catch(e => {
      if (e?.response?.data?.message) {
        notification.error({
          message: e?.response?.data?.message
        });
      } else {
        notification.error({
          message: 'Sorry something went wrong. Please try again later.'
        });
      }
      toggleLoading(false);
    });
  };

  const handleUpdate = () => {
    target && dispatch(fetchVlans(target));
  };

  const openDeleteModal = (record) => () => {
    setCurrent(record);
    toggleVisibleDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setCurrent(undefined);
    toggleVisibleDeleteModal(false);
  };

  const handleDelete = () => {
    const name = current?.name
    const vlanId = current?.vlanId

    return current && deleteVlan(target, name, vlanId).then(() => {
      closeDeleteModal();
      handleUpdate();
      notification.success({
        message: 'Deleted VLAN.'
      });
    }).catch(() => {
      notification.error({
        message: 'Sorry something went wrong. Please try again later.'
      });
    })
  };

  return (
    <div>
      <Header
        type='button'
        buttonName='Add VLAN'
        items={data}
        title={{
          singular: 'VLAN',
          plural: 'VLANs'
        }}
        loading={isLoading}
        openModal={openModal}
        onRefresh={handleUpdate}
      />
      <BaseTable
        className={'vlan-table'}
        columns={vlanColumns}
        data={data}
        loading={isLoading}
      />
      <BaseVlansModal
        visible={visible}
        loading={loading}
        current={current}
        handleCreate={handleCreateOrUpdate}
        onCancel={closeModal}
        rowKey={'id'}
      />
      <DeleteItemModal
        visible={visibleDeleteModal}
        title='VLAN'
        onDelete={handleDelete}
        onCancel={closeDeleteModal}
      />
    </div>
  );
};

export default VLANs;
