import React from "react";
import './index.scss';

import { ReactComponent as ErrorCloud } from '../../assets/error_cloud.svg';
import { useHistory } from 'react-router';

const Error = () => {
  const history = useHistory();

  const { location = {} } = history;
  const { state = [] } = location;

  return (
    <div className='error-container'>
      <ErrorCloud />
      <h1>Oops! Something went wrong</h1>
      <p>Please contact support for more information about the error</p>
      {state.length ? <p>{state[0]}</p> : <>1001-0011</>}
    </div>
  );
};

export default Error;
