import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Layout from 'antd/lib/layout';
import { useDispatch, useSelector } from 'react-redux';
import Menubar from '../components/menubar';
import routes from '../routes.js';
import './admin.scss';
import Breadcrumbs from '../components/breadcrumb';
import VersionsModal from '../components/modals/versionsModal';
import { fetchVersions } from '../reducers/versionSlice';
import { fetchUser } from '../reducers/userSlice';

const { Header, Content } = Layout;

const Admin = () => {
  const Footer = () => <div className='fluid-footer'>
    <span className='version-text' onClick={showVersionsModal}>FLUID {process.env.REACT_APP_BUILD_VERSION}</span>
  </div>
  const dispatch = useDispatch();
  const [isVersionsModalVisible, setIsVersionsModalVisible] = useState(false);
  const [compiledRoutes, setCompiledRoutes] = useState([]);
  const history = useHistory();

  const showVersionsModal = () => {
    setIsVersionsModalVisible(true);
  }

  const onCloseVersions = () => {
    setIsVersionsModalVisible(false);
  }

  const target = useSelector(state => state.clusters?.target);
  const versions = useSelector(state => state.versions);

  useEffect(() => {
    dispatch(fetchVersions(target));
  }, [dispatch, target]);

  useEffect(() => {
    dispatch(fetchUser);
  }, [dispatch]);

  useEffect(() => {
    const getRoutes = (routes) => {
      const allRoutes = [];
      const parentRoutes = routes.map((prop) => {
        if (prop.layout === '/admin') {
          if (prop.children && prop.children.length > 0) {
            const childRoutes = prop.children.map((child) => {
              return (<Route
                  path={child.path}
                  render={(props) => <child.component {...props} />}
                  key={child.name}
                  exact={child.exact}
                />
              );
            });
            allRoutes.push(...childRoutes);
          }
          return (
            <Route
              path={prop.path}
              render={(props) => <prop.component {...props} />}
              key={prop.name}
              exact={prop.exact}
            />
          );
        } else {
          return null;
        }
      });
      allRoutes.push(...parentRoutes);
      return allRoutes;
    };
    setCompiledRoutes(getRoutes(routes));
  }, []);

  return (
    <Layout>
      <Header className={'fluid-header'}>
        <Menubar routes={routes} history={history} />
      </Header>
      {history && <Breadcrumbs history={history} />}
      <Content className='content-layout'>
        <Switch>{compiledRoutes}</Switch>
      </Content>
      <Footer></Footer>
      <VersionsModal
        visible={isVersionsModalVisible}
        onClose={onCloseVersions}
        cloudVersion={versions.data.fluidCloud}
        maestroVersion={versions.data.fluidMaestro}
      />
    </Layout>
  );
};

export default Admin;
