import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/header';
import BaseTable from '../../components/table';
import ShowStatus from '../../components/showstatus';
import ExpandedSwitches from './expandedSwitches';
import { fetchNetworks } from '../../reducers/networkSlice';
import { stringSorter } from '../../utils';

const switchesColumns = [
  {
    title: 'NAME',
    dataIndex: 'switch_name',
    width: 200,
    sorter: stringSorter('switch_name')
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    width: 100,
    sorter: stringSorter('type')
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    width: 100,
    sorter: stringSorter('status'),
    render: (status) => <ShowStatus status={status} />
  },
  {
    title: 'LOCATION',
    dataIndex: 'site_name',
    align: 'left',
    width: 100,
    sorter: stringSorter('site_name')
  }
];

const Switches = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.networks);
  const target = useSelector(state => state.clusters.target);
  const switchesData = data.filter(({ type }) => {
    const lowerType = String(type).toLowerCase();
    return lowerType === 'switch' || lowerType === 'spine';
  });

  useEffect(() => {
    function getNetworks() {
      target && dispatch(fetchNetworks(target));
    }
    getNetworks();
    // const interval = setInterval(() => getNetworks(), 10 * 1000);
    // return () => clearInterval(interval);
  }, [target, dispatch]);

  const handleUpdate = () => {
    target && dispatch(fetchNetworks(target));
  };

  return (
    <div>
      <Header
        title={{
          plural: 'Switches',
          singular: 'Switch'
        }}
        type='button'
        items={switchesData}
        loading={isLoading}
        onUpdate={handleUpdate}
        description='View switches that connect Your Data Fabric components'
        onRefresh={handleUpdate}
      />
      <BaseTable
        className={'switch-table'}
        columns={switchesColumns}
        data={switchesData}
        loading={isLoading}
        error={error}
        rowKey={'id'}
        expandedRowRender={ExpandedSwitches}
      />
    </div>
  );
};

export default Switches;
